<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>

    <h1>Estranged</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://www.instagram.com/leafy_lise/" target="_blank"
        >Lisa Saban</a
      >
      (She/Her).
    </p>
    <br />


    <div
      class="youtube"
    >
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/PYAHCRhrpxM?si=FRjzd6fcJoeRE3c0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          Estranged is about a middle aged Serbian-Canadian women, Maya, who
          lives with her daughter, Nina, and mother, Duska. She has successfully
          gone a year without speaking to her ex-husband—who moved back to
          Serbia—since they divorced because of his infidelity. She would prefer
          to never speak to him again, but when her daughter has a desire to
          reconnect with her father, Maya is forced to confront her deeply
          buried feelings of resentment.
        </p>
        <br>
        <h3>LISA SABAN</h3>
        <p>
          asilnabas{@}gmail.com
            <br />
          After making her first short film, Estranged, in 2022, Lisa continues
          to explore her voice as an emerging filmmaker in Toronto. With a
          background in theatre, Lisa brings a strong understanding of character
          relationships and layered dialogue. She enjoys telling stories that
          expose the nuances and messiness of what it means to be a confused
          human on this earth—where not much really makes sense. But alas, we
          persevere and we move forward in the best way we know how.

        </p>
      </div>

      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>
          Writer/Director/Producer: Lisa Saban<br />
          Maya: Biljana Karadžić<br />
          Nina: Jessica Antovski<br />
          Duska: Lada Skender-Mićić<br />
          Cinematographer: Kat Zoumboulakis<br />
          Production Manager: Clare Workentin <br />
          Production Designer: Juliana Bergen<br />
          Costumer Designer: Zoe Van't Hof <br />
          Hair & Makeup Artist: Kurt Brown <br />
          1st Camera Assistant: Kiana Tagabing <br />
          Gaffer: Khanh Tudo <br />
          Key Grip: Ellen Shaw<br />
          Sound Recordist: Xavier Allard<br />
          Production Assistant: Joydip Dey<br />
          Catering: Good Behaviour<br />

          Editor: Oliver Whitfield-Smith<br />
          Colourist: James Graham<br />
          Composer: Julian Trivers<br />
          Dialogue Mixer: Mike Jones<br />
          Sound Effects, Ambiences & Final Sound Mix: Quinn Hoodless<br />

          Song “Prvi Korak Je Najteži” by Jarboli
        </p>
      </div>

      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH LISA</span>
        </h2>
        <br />

        
          <h3>Why were you inspired to make this specific film?</h3>
          <br><p>
          When I started writing the story, I was going through a break up and
          was feeling a lot of shame, anger and resentment. I wasn't sure how to
          navigate these feelings. I finally realized that I needed to forgive
          my ex and all the pain that relationship caused me. This was the only
          way I could release myself, and move on to live more freely in the
          present. So it was this process of forgiveness that became the heart
          of the story. From there, I thought it would be interesting to set it
          within the context of a Serbian immigrant family, specifically three
          generations of women, all living under the same roof. Something I
          noticed growing up with immigrant parents, is the huge focus put on
          resilience, perseverance, and being strong. There is less room for
          feelings like sadness, shame, resentment and anger—being vulnerable.
          So I ended up telling a story about forgiveness, within a Serbian
          immigrant family, that struggles to communicate and be vulnerable.
          </p><br>
          <h3
            >Were there any challenges or doubts you faced in the
            process?</h3
          >
          <br><p>
          The biggest challenge I faced during the process was being in denial
          that I needed to produce the film. I convinced myself that I couldn't
          produce it, and I tried to bring a producer onboard, but after a year
          of waiting around, I realized that no-one was going to prioritize
          making my film—other than me. So finally, I taught myself how to
          produce (I watched YouTube videos on making a budget, organizing a
          shoot, etc) and I went full force into it—confronting my fears. And
          then—it's funny how the universe works—the moment I took the project
          into my own hands, everything started to fall into place. Within a
          month, I confirmed my cast, found a crew, and locked in the location
          and shoot dates. It honestly felt like magic. The rest of the process
          was so smooth and enjoyable. 
          </p><br>
          <h3
            >What is your writing process like?</h3
          >
          <br><p>
          My writing process continues to reveal itself to me everyday. It still
          feels like a mystery in a lot of ways. The challenge is embracing that
          mystery, and trusting that it will eventually bring out a new story
          that's exciting, fresh and feels like *me*. Lately, I have been going
          to the public pool on Monday afternoons. After swimming, I'll lay on
          my towel, put my headphones in, and listen to music—observing what's
          happening around me. And then suddenly, ideas and characters will come
          flowing through me, and I'll write them down. Before I know it, I'm
          lost in my notebook.
        </p>

      </div>
    </div>


    <div class="chat" style="border: 1px dotted #212121; padding: 30px; height: 400px">
      <script
        type="application/javascript"
        id="cid0020000363315514134"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "estrangedlisa",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>

    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>


<script>
export default {
  name: "saban",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
    line-height: 1.6;
    background: #664c71;
    font-family: hersheyOld;
    height: 100%;
    color: #f3f9e6;
    padding: 30px;
    overscroll-behavior: none;
}

h1,
p {
    padding: 0px;
    margin: 0px;
}

h1 {
    line-height: 1;
    font-size: 45pt;
}

h2 {
    padding: 0px;
    margin: 0px;
    line-height: 1;
}

ul {
    font-size: 15pt;
}

.logo {
    width: 50px;
    float: right;
    padding-left: 10px;
    padding-bottom: 10px;
}

a {
    color: #f3f9e6;
    transition: 1s;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
    background: #f3f9e6;
    color: #664c71;
}

.ts-contain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.ts-box {
    border: 1px dotted #212121;
    padding: 30px;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.youtube {
    border: 1px dotted #212121;
    padding: 30px;
    margin-bottom: 10px;
    height: 55vh;
    min-height: 350px;
}

.chat {
  margin-top: 10px;
    display: block;
}

@media only screen and (max-width: 600px) {
    .ts-contain {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    .ts-box {
        height: 300px;
    }
    .youtube {
        height: 200px;
        margin-bottom: 20px;
    }
    .chat {
        display: none;
    }
    .credits {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 3;
    }
}
</style>