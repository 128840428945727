<template>
  <body></body>

</template>


<script>
  
  export default {
    name: 'adaptingArtCC',
    components: {
   
    }
  };
  
</script>

<style scoped>

</style>