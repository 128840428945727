<template>
  <MainLayout>
    <head>
      <title>Volvox Vault - 2 Kalil Haddad Films</title>

      <link
        rel="shortcut icon"
        type="image/png"
        href="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil3.jpeg?v=1632262004036"
      />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="description" content="2 Films by Kalil Haddad" />
      <meta name="author" content="Kalil" />

      <meta
        property="og:title"
        content="Volvox Vault - 2 Films by Kalil Haddad"
      />
      <meta property="og:description" content="2 Films by Kalil Haddad" />
      <meta
        property="og:image"
        content="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil3.jpeg?v=1632262004036"
      />
      <meta property="og:url" content="https://volvoxvault.com/archive/kalil" />

      <meta
        property="twitter:title"
        content="Volvox Vault - 2 Films by Kalil Haddad"
      />
      <meta property="twitter:description" content="2 Films by Kalil Haddad" />
      <meta
        property="twitter:image"
        content="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil3.jpeg?v=1632262004036"
      />
      <meta
        property="twitter:url"
        content="https://volvoxvault.com/archive/kalil"
      />
    </head>

    <body>
      <main>
        <div class="main">
          <div>
            <div>
              <h1 class="head">THE BOYS OF SUMMER</h1>

              <p class="headDes">A double feature by Kalil Haddad</p>

              <p>
              <audio
                class="firstAudio"
                ref="audio"
                title="THE BOYS OF SUMMER - Kalil Haddad"
                controls
              >
                <source title="" src="./kalil_boys_volvox.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </p>

              <hr />
              <br />

              <p>
                FOR MATURE AUDIENCES | PLAY LOUD | <a href="#theboysofsummer"
                  >WATCH</a
                ><br />
                Shot and abandoned in 2017, <i>The Boys of Summer</i> and
                <i>Honeypot: Remastered</i> represent the resurrection of a work
                that, for years, has been trapped between existences. Too
                complete not to exist, too incomplete to exist. Both originating
                from the lost project files of <i>Glue</i>—an incomplete epic of
                interconnected character vignettes—this double feature serves as
                a remix of what was—recontextualizing, adapting, and
                teleporting the film’s previous dramatic structure into the
                hybrid, narrative abstractions of underground video art.
                <br /><br />
                Complimenting each other in both form and tone—and edited in
                tandem—these works serve as the first two volumes in an on-going
                series reexamining the material for <i>Glue</i>; exploring
                characters and themes in a way previously unavailable within the
                film’s original intended structure. These edits represent their
                characters expressively, impressionistically, and
                emotionally—purely. They are films with narrative, but above
                all, they are works that regard mood—sharing a set of characters
                and continuity—but linked together in emotional abstraction.
                <br /><br />
                <i>Honeypot</i>—appearing here in remastered form—was previously
                released as a demo in the
                <router-link to="/issues/aggregate">Aggregate Issue</router-link
                >, before evolving live online into the final version presented
                here. <i>The Boys of Summer</i>, debuting for the very first
                time—as the summer itself draws to an end—may similarly update
                and change with the passage of time, existing as a living film.
                Time <i>itself</i> is of essence to the thematic scope and
                overall development of <i>Glue</i>. Remixing footage the way a
                DJ works with sound, the material has been radically reshaped
                and restructured to express a new kind of reality—the unreality
                of the past. <br /><br />
                These works now exist as they only could today. In the wake of
                time, they have been reshaped as reflection,
                reminiscence—documents of a time that cannot be accessed. Their
                present selves unable to exist separate from that of their
                past—the lived in nature of each work spanning all the way to
                the form itself. After four years of development, these films
                have come to represent the life of time—their continued
                aesthetic evolution contingent upon evolving artistic
                perspective and individual growth. So as the summer draws to a
                close, let us reflect on not only our present, but where we have
                been—and the futures we have yet to meet.
                <br /><br />
                This is: <i>The Boys of Summer</i> and
                <i>Honeypot: Remastered</i>. <br /><br />
                <a href="https://kalilhaddad.com/" target="_blank"
                  ><i>Kalil Haddad</i></a
                >
              </p>

              <br />
              <hr />

              <h2 id="theboysofsummer">THE BOYS OF SUMMER</h2>

              <div style="padding: 82.47% 0 0 0; position: relative">
                <iframe
                  src="https://player.vimeo.com/video/605980572?h=7cf3f3d755&title=0&byline=0&portrait=0"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              

              <br />

              <h2>HONEYPOT: REMASTERED</h2>

              <div style="padding: 75% 0 0 0; position: relative">
                <iframe
                  src="https://player.vimeo.com/video/499434644?h=41e2c8bc79&title=0&byline=0&portrait=0"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <br />
              <hr />

              <h2>STILLS</h2>
              <img
                src="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil3.jpeg?v=1632262004036"
                alt="3 young men standing with shirts off in a forest near a small pond. To the left, a cement wall with crude graffitti."
              />
              <br />
              <img
                src="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil4.JPG?v=1632262003953"
                alt="A young man and woman sitting on a suburban roof. A couple other roofs, trees, and a power line in the background. Clear sky."
              />
              <br />
              <img
                src="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil5.JPG?v=1632262003915"
                alt="Young man looking up at the camera, sitting on a chair on a deck in the suburbs."
              />
              <br />
              <img
                src="https://cdn.glitch.com/31c7994a-ff45-4592-b835-14262460c504%2Fkalil2.JPG?v=1632262003720"
                alt="A young woman looking to camera, trees in the background, clear summer day."
              />

              <br />
              <hr />
              <br />

              <a href="https://kalilhaddad.com/" target="_blank">
                Kalil's website.
              </a>
              |
              <a href="https://www.paypal.com/paypalme/ti4na" target="_blank">
                Tip Jar ☕
              </a>
            </div>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "kalil",
  components: {
    MainLayout,
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background: navy')
  },
  beforeDestroy () {
    document.querySelector('body').setAttribute('style', '')
  }
};
</script>

<style scoped>
body {
  line-height: 2;
  color: white;
  background-color: navy;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}

.head {
  text-align: center;
  font-size: 20pt;
  line-height: 1.5;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}

p {
  font-size: 15pt;
}

a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  color: grey;
}

.main {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(111, 111, 111, 0.4);
  padding: 40px;
  border-radius: 45px;
  margin-bottom: 60px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;

  border-image-source: linear-gradient(to bottom, yellowgreen, grey);

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

hr {
  border: none;
  border-bottom: 1px dashed grey;
}

mark.mollyA {
  background-color: Moccasin;
  color: black;
}

mark.molly {
  background-color: OliveDrab;
  color: black;
}

h2 {
  line-height: 1.5;
}

@media (max-width: 800px) {
  .main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-device-width: 800px) {
  .main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}
</style>