<template>
  <main>
    <div class="main">
      <h1>kind words</h1>
      <br />
      <p>
        hi everyone, this is mad <br /><br />
        welcome to the <router-link to="/">volvox.observer</router-link> kind
        words column, where you can
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScaMRoojXD19ClmhFsylL6NPGA6jKA98DxdhSafjnZnNCURQA/viewform?usp=sf_link"
          >share a worry, ask for or share some kind words of your own...</a
        >
        rather than an advice column for pointed advice, this column will focus
        on sharing kind words and relating to one another. it will including
        gentle advice, should there be any...
        <br /><br />
        to me, kind words is a practice of showing care without having all the
        solutions, and being gentle to anonymous kins in a quiet community...
        <br /><br />
        "use your words to lift others and be lifted in return"
        <br /><br />
        ( ꒰ .·:*0¨◌ …︑ ༘︒⚬∙︓·⠄ :·. ꒱ … ◌.·:*0¨◌ ꒱ …。。◌ ）
        <br />
      </p>
      <br />
      <hr />
      <br />
      <h2>dear mad,</h2>
      <br />
      <p class="kindq">
        i'm pursuing a degree in a 'practical' field to be able to financially
        support my creative interests; the more i study it, the more worried i
        become that when i finally start working, i'd have wasted the potential
        of becoming successful in the arts (as a career). am i wrong in thinking
        that it's alright to develop my interest in the arts as something i love
        and not something i want to work for? i'm not sure how to phrase it. -
        selin
      </p>
      <br />
      <p class="kinda">
        dear selin, i struggled so much w this that i quit my job for 1.5 years
        just to figure it out. what i figured out is that i want to keep what i
        love precious, carefree, boundless, no other stakes except for fun and
        love. you are so valid in protecting your energy and your love and not
        allowing it to be tangled up into responsibilities, expectations, money.
        <br /><br />
        i’m always trying to feel like i’m 11 years old again, just doing things
        because i have time to be myself. i dont think i can do that if im
        consciously trying to make trade those things for money! my job in tech
        bought me the past 1.5 years of rest. i think its not necessary to have
        a “career path” as a human being… more like doing things that make sense
        to you one thing at a time. stay intuitive and inquisitive, one day
        something could come along and change everything.
        <br /><br />
        i learned how to see my job as just a few hours in my day. your most
        precious self will always, always be the self that you love the most, so
        please don’t worry about not succeeding in a commercial way with your
        artistic pursuits and just protect the energy that makes you you ♥
        <br /><br />
        <a
          href="https://www.newyorker.com/magazine/2017/06/05/the-work-you-do-the-person-you-are"
          >"the work you do, the person you are"</a
        >
        i read this new yorker article in 2017 right before starting my first
        job in tech. its stuck with me since but i wasn’t always able to
        practice it. now it’s 2022 and i feel like i am capable of keeping this
        mindset for a long time. i’m rooting for you!
      </p>
      <br /><br />
      <p class="kindq">
        I constantly have a nagging feeling that I'm running out of time... +
        then I get discouraged that I don't have the energy to make my dreams
        come tru - july28
      </p>
      <br />
      <p class="kinda">
        dear july28, you have time ♥ live at your own special pace, in your
        style. your dreams are always in the process of coming true, even as you
        mull over it in fear. one of my daily affirmations of the year has been
        “i have time”... say it to yourself, and time is there.
        <br /><br />
        these days i adore child-like desperation and unabashedly pronouncing
        your fears. “i am scared that if i try this, ill fail. i am scared that
        if i don’t try it, ill fail. i am scared that if i try it, i’ll realize
        i’m bad” or the ever pretentious “i am scared if i do this, i won’t like
        it anymore”... saying these sentences aloud in comparison to one another
        makes me realize “ah, the choice is obvious here. i have to start!” you
        can also divide your dreams into smaller dreams, create daily dreams
        (dreams achievable in a day!), or give up decisively. just be honest. if
        u want to read something with some “reset energy”, this might be nice –
        <a
          href="https://eyedrops.substack.com/p/on-0?r=5rdp&s=w&utm_campaign=post&utm_medium=web"
          >"on 0"</a
        >
      </p>
      <br /><br />
      <p class="kindq">
        hi mad!! i’m worried about wasting time on relationships that may not
        last forever. do you think “forever” is a realistic idea or just a naive
        misconception? - pepero
      </p>
      <br />
      <p class="kinda">
        dear pepero, forever can be any duration you want it to be... sometimes
        there’s forever in one moment, there’s forever in one perfect weekend
        trip, there’s forever in someone’s words. i do believe the literal
        forever exists, but its rare.
        <br /><br />
        rather than thinking about life and relationships in durations, i like
        to think about it in its energy and presence. sometimes longer isn’t
        better, sometimes shorter is just right – right for the person you are,
        right for the person you want to become.
        <br /><br />
        anything could end, for any reason, at any time. and… anything could
        last forever, for any reason, for forever. to answer your question
        simply, i think forever itself is both realistic and naive, i believe
        it, and i don’t! these days i lean towards beautiful words like “best”
        “favorite” “forever” “perfect”... there’s something so attractive about
        these words that makes my eyes glaze over. maybe the question is… am i
        happy with this person, right here, right now? i wish you many perfect
        moments with your special person ♥
      </p>
      <br /><br />
      <p class="kindq">
        i'm often worried that I am disappointing and arbitrary to those around
        me. - yeT
      </p>
      <br />
      <p class="kinda">
        dear yeT, even from this one sentence of concern, i can feel that u
        aren’t arbitrary. i love when people ask questions like… “what do u
        think of me?” it’s nerve wrecking, but ask that to somebody u trust and
        they’ll gift u the words u need to hear. sometimes one person’s
        acknowledgement dissolves all my worries about this “everyone around me”
        that lives in my head. who of those around u do u care about the most?
        don’t be afraid to seek their direct affirmation~ another way to seek
        affirmation is to first attack them with ur own true love and
        appreciation &#9829; i like to remember that the people i think are
        judging me can be counted one by one and dismissed one by one.
      </p>
      <br /><br />
      <p class="kindq">
        I've been quite insecure about my looks lately. How can I learn to love
        my little quirks? - dee
      </p>
      <br />
      <p class="kinda">
        dear dee, surprisingly, practicing grounding exercises throughout the
        years made me more lighthearted about my insecurities. to be specific,
        thinking “i have a body” makes the thought of “i don’t like my upper
        arm” suddenly feel lighter…? gratitude is a weapon that protects me
        against insecurities and shoves me towards self love. one way to ground
        is by confronting urself! look in the mirror, count your insecurities,
        make a list, take pictures… or… even post it online! :0 sometimes theres
        no significant difference between getting used to something and loving
        something. sometimes those two things are part of the same journey. all
        your little quirks belong to you and you only. keep them or let them go,
        in ur own style!
        <br /><br />
        getting a little rambly, but lately ive been thinking a lot about beauty
        and what cosmetic procedures id want. i got a lash lift and a facial
        recently. it can be a fun and healthy exercise... what would i change if
        i could change anything? and why? i’ve realized its easier than i think
        to change how i look, and the smallest changes (eg one mole removal)
        make me feel drastically different.
      </p>
      <br />
      <hr />
      <br />
      <h2>community kindness</h2>
      <br />
      <p class="kindc">
        helloOoo im VERY proud of you idc how big or small you think your
        achievements are but the fact that you're existing and reading my
        message right now is a pretty great achievement by itself already!!!
        lately ive been feeling like ive fallen behind and wasted too much time
        trying to "fix myself".. i couldn't help but to compare myself to my
        same age peers who have accomplished so much these past couple of years
        but then i was reminded by a good friend that you cant really compare
        your growth to others because we all live very different lives. we often
        think that life is a race where the finish line is success and wealth
        and we're all against each other but ive realized that it's not like
        that at all!! life is just a very nice walk around the park, there is no
        race, just enjoy everything in your own pace. as long as you keep on
        moving you will get to where you wanna be eventually!! this was very
        long and probably incoherent but i hope that you'll be a lil nicer to
        yourself today. very proud of u &#9829;
      </p>
      <p class="kindc">
        Men are born soft & supple; dead, they are stiff and hard. Plants are
        born tender and pliant; dead, they are brittle and dry. Thus whoever is
        stiff and inflexible is a disciple of death. Whoever is soft and
        yielding is a disciple of life. The hard and stiff will be broken. The
        soft and supple will prevail!!!!!!!!!!! - #76 Tao Te Ching
      </p>
      <p class="kindc">
        I think it's a good idea to take a walk at 5am, and then go back to bed
        for a little. Especially on foggy days... in quiet areas... or busy
        areas. It's cool to see who's up at that hour, along with the birds. The
        air is usually still and full of soft whispers.
      </p>
      <p class="kindc">
        remember how good you are at overcoming. A wise woman once said
        (roughly) 'if we lived in a world where we had hotdogs for fingers, we
        would get really good at doing stuff with our feet'
      </p>
      <p class="kindc">
        yesterday, I overheard a mother tell her young son - "listen, everything
        that you care about will matter someday"
      </p>
      <p class="kindc">
      Your presence is felt in ways you're not even aware. The past doesn't inform your future; you're going to keep getting closer to your truth.
      </p>
      <p class="kindc">
      things take time and in the end, you will find your way even if you feel lost right now.
      </p>
    </div>
    <br><br>
  </main>
</template>


<script>
export default {
  name: "KindWords",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Kind Words",
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background: #cbbad2");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
};
</script>


<style scoped>
main {
  margin: 40px;
}
.main {
  margin: 0 auto;
  padding: 50px;
  background: #fbfdf7;
  border-radius: 100px;
  color: #212121;
  max-width: 900px;
}
p {
  padding: 0px;
  margin: 0px;
}
a {
  text-decoration: underline;
}
a:hover {
  background-color: #cbbad2;
  border-radius: 100px;
  text-decoration: none;
}
.kindc {
  padding: 10px;
  border-radius: 15px;
  border: 0.5px dotted #212121;
  margin-bottom: 10px;
}
.kindq {
  border-bottom: 0.5px dotted #212121;
  font-weight: bold;
}
</style>