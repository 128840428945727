<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>

    <h1>Vlog #1460</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://instagram.com/conliuart" target="_blank">Connie</a> <a href="http://connie.surf">Liu</a> (She/Her).
    </p>
    <br />

    <div class="youtube">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/bFRaJNkV9gw?si=X5DV_QfsWI3fp_bV"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          A sequence about how time passes in my suburb and how it marches on.
        </p>
        <br />
        <h3>CONNIE LIU</h3>
        <p>
          cliu55567{@}gmail.com<br />
          Growing up in my majorly white suburb as the daughter of Asian
          immigrants, I always had felt out of place when I was growing up. It
          wasn't until the latter end of high school, I had found people who had
          understood me, and had felt my identity accepted. The amount of time
          it had taken to get to that point of stability had made me fear that
          this was it, this town, ironically where I had initially felt like I
          didn't belong, would be my only safe harbor where I could truly be
          myself.
          <br /><br />
          Initially I vlogged senior year as a way to hold onto my memories of
          finally belonging, but then when the pandemic hit and I went through a
          difficult college experience, I vlogged to preserve my memory of
          stability as I reconnected with hometown friends and then finally when
          I graduated and inbetween moving out of my hometown indefinitely, I
          felt like I had to vlog to create a piece to address this tension
          between time telling me to move forward and my inability to let go. I
          try to answer that in this, that although time marches on, and things
          change, that feeling of safety and stability resides in me.
        </p>
      </div>

      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>
          Director: Connie Liu<br />
          Music: Erik Satie - Gymnopédie No.1
        </p>
      </div>

      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH CONNIE</span>
        </h2>
        <br />

        
          <h3>Why were you inspired to make this specific film? </h3>
          <br /><p>
          The film is a smattering of three different times in my life where I
          was taking video to film something. The first time I made my vlog, it
          was because I was extremely happy at the end of high school and just
          wanted to capture random moments. The second time was during the
          pandemic where I was scared for time to continue moving forward. The
          last time was when I wanted to combine my previous to vlogs for this
          film fest. I was thinking, it's interesting how nothing really has
          changed but so much has changed in my suburb. If I interspersed the
          footage, could you even tell things have changed? It's like my version
          of boyhood. No one is acting from the time it was taken, it was all
          from different times but feels like the same time. I wanted to answer
          where do I stand with these memories? How can I go forward?
          </p><br />
          <h3
            >What is one of your fondest memories from the production/process of
            making this film?
          </h3>
          <br /><p>
          I think it was the act of sharing it with my close friends and getting
          their feedback, as well as jokingly filming it along with my friend
          who also wanted to film something to remember the summer days in my
          town in the past three months. It was lovely getting to hear how they
          related to the film, and how they resonated with it, some people said
          they even teared up. It made me so happy because how I grew up and my
          memories there is something that's very dear to my. heart and I was
          happy to share it with others. I think also while I was filming it
          with my friend, it made me think about the circularity of time. At one
          point, people didn't understand why I had vlogged back then, it's
          interesting how things change but also converge.
          </p><br />
          <h3
            >What emotion or human experience were you most drawn to in the
            making of this film and why?
          </h3>
          <br /><p>
          I think I was always enamored with the idea of memory and nostalgia. I
          joked with a friend the other day that I would definitely be one of
          the first to become fevered in Severance (in the book it's those who
          are very nostalgic who become fevered). I still am - it's evident
          through a couple things - I love going home, I hate change, I love
          talking to the friends who have known me for years (we talk almost
          every day), I think about the people I've met that I treasure very
          much, and moments of joy that I keep stored in my heart. I wanted to
          think of nostalgia in a way that recognized the beauty of it, but also
          the fact that change is inevitable and what has happened will never
          happen again.
        </p>
      </div>
    </div>

    <div
      class="chat"
      style="border: 1px dotted #212121; padding: 30px; height: 400px"
    >
      <script
        type="application/javascript"
        id="cid0020000363494290672"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "vlogliu",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>

    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>

<script>
export default {
  name: "stenberg",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
  line-height: 1.6;
  background: #664c71;
  font-family: hersheyOld;
  height: 100%;
  color: #f3f9e6;
  padding: 30px;
  overscroll-behavior: none;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  line-height: 1;
  font-size: 45pt;
}

h2 {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

ul {
  font-size: 15pt;
}

.logo {
  width: 50px;
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;
}

a {
  color: #f3f9e6;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  background: #f3f9e6;
  color: #664c71;
}

.ts-contain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.ts-box {
  border: 1px dotted #212121;
  padding: 30px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.youtube {
  border: 1px dotted #212121;
  padding: 30px;
  margin-bottom: 10px;
  height: 55vh;
  min-height: 350px;
}

.chat {
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .ts-contain {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .ts-box {
    height: 300px;
  }

  .youtube {
    height: 200px;
    margin-bottom: 20px;
  }

  .chat {
    display: none;
  }

  .credits {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
</style>
