<template>
  <MainLayout>
    <head>
      <title>
        Volvox Vault - 20-Something Home Cooking Insta: The Sweetest Place Left
        on the ‘Gram
      </title>

      <link rel="shortcut icon" type="image/jpg" href="@views/archive/2021/3/hp_0.jpg" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta
        name="description"
        content="A look into the sweet side of instagram with Hannah Polinski."
      />
      <meta name="author" content="Hannah Polinski" />

      <meta
        property="og:title"
        content="Volvox Vault - 20-Something Home Cooking Insta: The Sweetest Place Left on the ‘Gram"
      />
      <meta
        property="og:description"
        content="A look into the sweet side of instagram with Hannah Polinski."
      />
      <meta
        property="og:image"
        content="http://volvoxvault.com/articles/2021/homeCooking/hp_0.jpg"
      />
      <meta
        property="og:url"
        content="http://volvoxvault.com/articles/2021/homeCooking/"
      />

      <meta
        property="twitter:title"
        content="Volvox Vault - 20-Something Home Cooking Insta: The Sweetest Place Left on the ‘Gram"
      />
      <meta
        property="twitter:description"
        content="A look into the sweet side of instagram with Hannah Polinski."
      />
      <meta
        property="twitter:image"
        content="http://volvoxvault.com/articles/2021/homeCooking/hp_0.jpg"
      />
      <meta
        property="twitter:url"
        content="http://volvoxvault.com/articles/2021/homeCooking/"
      />
    </head>

    <body>
      <main>
        <div>
          <div>
            <h1 class="head">
              20-Something Home Cooking Insta: The Sweetest Place Left on the
              ‘Gram
            </h1>

            <p class="headDes">
              A look into the sweet side of Instagram with Hannah Polinski.
            </p>

            <p>
              <audio
                class="firstAudio"
                ref="audio"
                title="20-Something Home Cooking Insta - Hannah Polinski"
                controls
              >
                <source title="" src="./hannah_volvox.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </p>

            <div class="imgBox"><img src="./hp_0.jpg" /></div>

            <p style="text-indent: 15px">
              <strong>W</strong>hen the pandemic first began in March and people
              were forced indoors, two things became even hotter commodities:
              one was Instagram documentation on a very micro level, and the
              other was cooking/baking (or both). While this may sound like a
              recipe for culinary success, I ended up annoying all my friends
              with constant enthusiastic vegetable posts and worsening my eating
              anxiety due to all the time I spent thinking about food.
              Eventually, I decided to expel my food thoughts into a separate
              account, which brought me to the world of 20-something home
              cooking Instagram. Home cooking insta is a place where mostly
              young womxn share their kitchen creations, holding space between a
              visual food journal and a Finsta. However, this is not your
              average foodie account; the community is distinguished by
              filter-free photos that grace your feed without the forced
              “authenticity” of food influencer blogs. I can post any low
              quality shot of my fried rice, update the status of my fridge
              fermented pickles, or talk about my food therapist on any
              particular day and be met with support and love from other cooking
              accounts. A lot of kombucha and sourdough starter knowledge is
              passed around, and recipes are shared between accounts with the
              intention of helping one another to eat better.
            </p>
            <p style="text-indent: 15px">
              I had a lil chat with two active users of at home cooking insta,
              who both happened to be named Molly (<a
                style="
                  background-color: Moccasin;
                "
                href="https://www.instagram.com/veganish.molly/"
                target="_blank"
                >@veganish.molly</a
              >, and
              <a
                style="
                  background-color: OliveDrab;
                "
                href="https://www.instagram.com/umamimolly/"
                target="_blank"
                >@umamimolly</a
              >
              respectively). Other first names are permitted on food insta, but
              these lovely food lovers shared a little about their IG cuisine
              journey with me.
            </p>

            <div class="imgBox"><img src="./hp_3.jpg" /></div>

            <p>
              <strong
                >Hannah: When and why did you start your food account? What do
                you post?</strong
              >
              <br /><br />
              <mark class="mollyA">Molly</mark>: I started my food account back
              in January just to share my fave recipes with friends. I&#39;d
              always wanted to create a food account but was timid at first
              cause there weren&#39;t many people doing it. Now my account is
              helping me heal from an eating disorder. <br /><br />
              <mark class="molly">Molly</mark>: I began my food account the
              summer of 2017 right after my 1st year of university. I had
              recently become vegetarian and I wanted a place to specifically
              post about my vegetarian cooking experiments. Throughout
              university, the account became a much needed creative outlet to
              contrast seemingly endless and sometimes mind-numbing assignments.
              I’m no longer vegetarian or in university, but this account has
              been especially fun for me to upkeep throughout the pandemic.
              <br /><br />
              <strong
                >Hannah: How would you describe our Home Cooking
                community?</strong
              >
              <br /><br />
              <mark class="mollyA">Molly</mark>: I&#39;d describe the community
              as inspiring, creative, and authentic. <br /><br />
              <mark class="molly">Molly</mark>: Open, friendly, creative, and
              constructive are the first words that come to Mind.
            </p>

            <div class="imgBox"><img src="./hp_2.jpg" /></div>

            <p>
              <strong
                >Hannah: What have you gotten out of home cooking insta?</strong
              >
              <br /><br />
              <mark class="mollyA">Molly</mark>: Home cooking insta has helped
              me rediscover the joy of cooking and eating to nourish my body.
              I’m constantly being introduced to new cuisines, ingredients, and
              food histories that have greatly enriched my home cooking.
              <br /><br />
              <mark class="molly">Molly</mark>: My food ig served me well as a
              creative outlet when I was in uni, but during the pandemic it’s
              been such a source of joy to find other like-minded 20-something
              home bakers and cooks. Prior to the pandemic, active but chill
              food accounts that weren’t high-profile ~food influencers~ seemed
              few and far between. <br /><br />
              <strong
                >Hannah: Do you think our home cooking insta community is
                different than other food communities online?</strong
              >
              <br /><br />
              <mark class="mollyA">Molly</mark>: Despite the mix of diets
              (vegan, veg, gluten free, etc) we all come together over a shared
              love of food and cooking. At the end of the day, counting
              calories, diet culture, and food shame are gone, creating space
              for genuine connection through food. While posts may look perfect,
              chefs open up about their struggles whether that be in or outside
              the kitchen. Ultimately,promoting an authenticity uncommon in
              other communities. There&#39;s no sense of competition for
              followers or shares; we are all in this together, just doing what
              we love. <br /><br />
              <mark class="molly">Molly</mark>: I’d definitely say we are
              distinct from other online food communities in that most
              20-something home cooking accounts are extremely open and
              welcoming, and that there really isn&#39;t a precursor other than
              enjoying baking and/or cooking. Performance is and will always be
              a large part of social media, but it feels like it’s slightly less
              central to a lot of 20-something cooking accounts and that has
              been really refreshing. I don&#39;t think many of us are actively
              trying to be famous or food influencers. We’re just having fun!
            </p>

            <div class="imgBox"><img src="./hp_4.jpg" /></div>

            <p>
              <strong>Hannah: What are your favourite things to make?</strong>
              <br /><br />
              <mark class="mollyA">Molly</mark>: Right now I love making soft
              scrambled eggs with miso butter! It&#39;s simple but paired with
              some leek &amp; potato hash or crusty bread; it&#39;s divine. I
              went into my account being strictly vegan and terrified of eggs
              and meat. But seeing so many people develop recipes with love and
              seeing delicious photos has made so many &quot;fear foods&quot;
              more approachable and enjoyable again. <br /><br />
              <mark class="molly">Molly</mark>: I’ve been baking bread as a
              hobby for 8 years now and specifically sourdough for 1.5 years, so
              that’s obviously a fav. I love to create interesting cookie
              recipes (a friend and I actually have a side project for this
              called Kuketista!), and I’ve been really into learning more about
              Palestinian cooking and baking lately. <br /><br />
              I began baking bread in my early teens as a way to cope with
              anxiety. It still serves as an effective way to deal with my
              anxiety, and I think a huge reason why bread baking became
              massively popular at the beginning of quarantine is because other
              people realized how therapeutic it is. I like how maintaining a
              sourdough starter feels similar to having a low maintenance pet—
              it&#39;s the reasonable level of commitment (for me at least) and
              it&#39;s fun to see it change as it matures. It’s also incredibly
              rewarding to see how just a handful of inexpensive ingredients
              combined with the right environment and technique transforms into
              something so beautiful, nourishing, and delicious.
              <br /><br />
              <strong
                >Hannah: Any general thoughts on food insta you want to
                add?</strong
              >
              <br /><br />
              <mark class="mollyA">Molly</mark>: Being a part of this food
              community has drastically reshaped my food + body relationship.
              Ever since we shared photos and mini bios about ourselves,
              there&#39;s been a huge shift in openness. I&#39;m seeing more
              accounts open up about their disordered eating habits and lives
              and it&#39;s helped normalize the conversation around eating
              disorders. I started out just complimenting people on their
              recipes, very surface level stuff. And I still do that, don&#39;t
              get me wrong, but now there&#39;s more words of support and
              upliftment as we&#39;re all worthy of cooking and eating foods
              that nourish our bodies. <br /><br />
              <mark class="molly">Molly</mark>: I feel like I’ve already said so
              much with these other questions! I guess I’m just grateful to have
              stumbled upon this little community of like-minded home cooks
              &amp; bakers during the pandemic. It&#39;s been a rare cute moment
              in an ugly year.
            </p>

            <div class="imgBox"><img src="./hp_1.jpg" /></div>

            <a href="" target="_blank">
              Hannah Polinski's Tip Jar ☕ - coming soon
            </a>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "cookingInsta",
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
body {
  line-height: 2;
}

.head {
  text-align: center;
  font-size: 20pt;
  line-height: 1.5;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}


a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  color: grey;
}

main {
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(255, 255, 255, 0.6);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 40px;
  border-radius: 45px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;

  border-image-source: linear-gradient(to bottom, yellowgreen, grey);

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

mark.mollyA {
  background-color: Moccasin;
  color: black;
}

mark.molly {
  background-color: OliveDrab;
  color: black;
}

@media (max-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-device-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}
</style>