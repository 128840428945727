<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>

    <h1>Okay, See You Then</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://instagram.com/maipai0" target="_blank">Maia</a> <a href="http://maiaworld.me/" target="_blank">Liebeskind</a> (They/Them).
    </p>
    <br />

    <div class="youtube">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/orv67VaTEGo?si=wctWqt4rFs2KYO3p"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          Maia and their mother try to find a time to connect.
        </p>
        <br />
        <h3>MAIA LIEBESKIND</h3>
        <p>
          maiab13{@}gmail.com
          <br />
          My artwork, which is primarily video based, exists in the mundanity of
          daily life. I am a person who is constantly stuck asking myself
          questions like how does anyone make a good decision? How does anyone
          feel connected? Enjoy their job? Know what to make for dinner? Wake up
          in the morning? Etc. I have found that my best way to combat these
          questions, and maybe attempt to answer them, is through my video
          camera.
          <br /><br />
          By filming and magnifying our small actions, I am able to notice the
          large emotions they emit, and a narrative about the subject’s approach
          to living begins to form. Sometimes I focus on myself, sometimes
          strangers, family, or friends. Whether it’s someone making a meal, a
          day at work, or a phone call, process is always present, equally as
          much as results. I believe when looked at closely, daily life has a
          narrative as big as any action movie or thriller (which are very fun
          and have their own place in media). I interact with textures,
          movements, sounds, glances, smiles, frowns, and stuck out tongues, and
          then collage them together in a way that demonstrates a narrative.
          Sometimes I start with an idea and try to film things to show it,
          other times I find the idea after filming.
          <br /><br />
          Lately, I have become interested in our increasing humanization of
          technology. Laptops, desktops, phones, websites, programs, and mice
          all seem to be characters in their own right, albeit usually
          controlled by a person. I have started implementing screen recordings
          and coded scenes in my newer videos, attempting to examine the digital
          world in a similar way as I do the physical world.
          <br /><br />
          I am currently working with a creative partner on a feature fictional
          film, using my years of observational filmmaking and his years of
          narrative filmmaking to create the perfect storm of fly on the wall
          fiction!
        </p>
      </div>

      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>
          Director: Maia Liebeskind Featuring Maia and Jean
        </p>
      </div>

      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH MAIA</span>
        </h2>
        <br />

        
          <h3>Why were you inspired to make this specific film?</h3>
          <br /><p>
          I was inspired to make this film because I was living away from home,
          in a new city and in my own apartment, for the first time in my life.
          I was having a hard time maintaining long distance relationships with
          my friends and family. This film isn't 100% real text messages, but it
          pretty much represented what my communication with my mother had been
          like for months - both wanting to talk, but never finding the time. I
          wanted to capture the feelings of longing, boredom, and distraction,
          as that was a lot of what I was experiencing in that time period of my
          life and needed an outlet for it. I made it really just as a dairy
          video, and was pleasantly surprised to see people relate to it.
          </p><br />
          <h3
            >What thoughts has making this film sparked that you may bring into
            your next project or into your daily life?</h3
          >
          <br /><p>
          This film is one of my first pieces using text messages. Making it, I
          started to realize the potential they hold emotionally. To me they
          have this sort of hollow connection, which is sometimes hard to
          capture in person. For example, if the film was inter-cut with my mom
          and I having very short phone calls, I don't think it would hold the
          same longing feelings. I also think the screen recordings make the
          “real life” scenes feel more weighted - especially the part when I
          call my mom and get her voicemail, a tiny moment of hearing her real
          voice feels more weighted after only seeing her in texts. I think
          making this reminded me just how important juxtaposition is in
          filmmaking, and how cool of a tool it can be to play around with. A
          cut from the digital world to the real world always hits with this
          kind of loneliness that I'm always chasing after in my videos. I
          definitely am a sucker for a hard cut - and making this reminded me
          how powerful of a tool it is. </p><br />
          <h3
            >Who are some of your inspirations as a filmmaker and how might
            their impact show up in your film?</h3
          >
          <br /><p>
          Some of my biggest film inspirations are Hito Steyerl, Andrew Haigh,
          Maya Deren, and (maybe obviously) Frederick Wisemen. My favorite thing
          about their work, and what I am most inspired by, is their ability to
          shape reality with such a h3 point of view, both visually and
          emotionally. Even Andrew Haigh, who is a fiction filmmaker, makes
          films that are painfully real feeling. I also love the shooting style
          of all of these filmmakers, who all often play with shadow,
          reflection, and movement in ways not common in big mainstream
          pictures. Maya Deren specifically inspires me with her essay “Amateur
          Versus Professional”, an essay I have read over and over again, when I
          feel inferior for not being profitable enough as an artist. I think
          that these filmmakers have taught me to embrace the beauty and
          mysticism of reality, and to look at it in playful ways with my
          camera. I am inspired by lots of other filmmakers, of all types of
          genres, but these four artists I find the most in common with, and
          hope I can follow their legacies and wisdom as I continue to make more
          work.
        </p>
      </div>
    </div>

    <div
      class="chat"
      style="border: 1px dotted #212121; padding: 30px; height: 400px"
    >
      <script
        type="application/javascript"
        id="cid0020000363488563508"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "okaymaia",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>

    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>

<script>
export default {
  name: "liebeskind",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
  line-height: 1.6;
  background: #664c71;
  font-family: hersheyOld;
  height: 100%;
  color: #f3f9e6;
  padding: 30px;
  overscroll-behavior: none;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  line-height: 1;
  font-size: 45pt;
}

h2 {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

ul {
  font-size: 15pt;
}

.logo {
  width: 50px;
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;
}

a {
  color: #f3f9e6;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  background: #f3f9e6;
  color: #664c71;
}

.ts-contain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.ts-box {
  border: 1px dotted #212121;
  padding: 30px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.youtube {
  border: 1px dotted #212121;
  padding: 30px;
  margin-bottom: 10px;
  height: 55vh;
  min-height: 350px;
}

.chat {
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .ts-contain {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .ts-box {
    height: 300px;
  }

  .youtube {
    height: 200px;
    margin-bottom: 20px;
  }

  .chat {
    display: none;
  }

  .credits {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
</style>
