<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>

    <h1>Goddess (She/Her/Hers)</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://www.instagram.com/cookierill/" target="_blank"
        >Charli Christine Marker</a
      > (She/Her).
    </p>
    <br />

    <div class="youtube">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/_gV1RgIM9oo?si=mn78psaq1yKWdcE7"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          A fat autistic trans woman examines both the femininity and toxicity
          of her family tree to understand her own body and mind.
        </p>
        <br />
        <h3>CHARLI CHRISTINE MARKER</h3>
        <p>
          itschristinemarker{@}gmail.com
          <br />
          I'm an autistic trans filmmaker who is passionate about finding new
          ways to show how I and others see the world. This short film, "Goddess
          (She/Her/Hers)", was made right after I broke my MacBook in the middle
          of the pandemic, an worrying that I wouldn't be able to use film as an
          outlet for surviving conversion therapy anymore (I ended up spending
          another year and a half in it, continually making movies even as not
          being able to show myself in my own home was causing me trauma).
          <br /><br />
          But then I realized I could use iMovie for iPhone to both shoot AND
          EDIT my movies, and this was my first experiment with it. It ended up
          being a wonderful experience for me, because I could know longer be so
          maximializist with the iPhone app, and had to think very clearly about
          eerie shot I wanted to include. And this is sorta like a dance through
          the home I lived in in late 2020.
        </p>
      </div>

      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>Director: Charli Christine Marker</p>
      </div>

      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH CHARLI</span>
        </h2>
        <br />

        
          <h3>Why were you inspired to make this specific film?</h3>
          <br /><p>
          My dad looks JUST like former ex-gay movement spokesman John Paulk did
          in the cover of Newsweek in the eighties, so I thought examining his
          gender and sexuality would be both helpful and fun, especially since
          both my parents are such bigots. </p><br />
          <h3
            >Did anyone specific become critical to the creation of your film?
            Why do they stand out as significant to your film?</h3
          >
          <br /><p>
          Well, on the media database site RateYourMusic, the only ratings
          currently there for Goddess other than my own gave it 0.5 stars. Part
          of me is not too surprised by that, since not only are they not THAT
          wild about most films by women in general, the community seems
          ESPECIALLY hostile to short films by women, if only because the poetic
          subjectivity we often implement often feels "frivolous" or "indulgent"
          (see: Lili Loofbourow's theory of the "Male Glance"). What really made
          me uncomfortable, though, was that the only rating listed on the site
          was just a one-word statement of "Bro...", which was REALLY hard to
          not take as blatantly transphobic.</p><br />
          <h3
            >What thoughts has making this film sparked that you may bring into
            your next project or into your daily life?</h3
          >
          <br /><p>
          It will be three years since I made this short in November, and while
          I tried to follow it up with a spiritual sequel in my April 2021 short
          The Milf Cinema (included in the Google Drive), but even back then it
          felt like I was reaching diminishing returns. I often think about how
          David Lynch was never able to make a feature so minimal like
          Eraserhead again, and I'm still trying to figure out my equivalent to
          Twin Peaks The Return Episode 8 to show what I would do with Goddess
          today.
          </p><br />
          <h3
            >How did you perceive films as a kid, in comparison to how you look
            at them now?</h3
          >
          <br /><p>
          It's funny, because when talking about the aesthetic differences
          between me and my high school best friend Dan Lotz, I would always use
          our shared childhood experience with the Lego Spielberg MovieMaker set
          to illustrate. While he was dedicated to mastering the stop-motion
          process of, say, Michel Gondry's "Fell in Love With a Girl" video, I
          would just twist the included webcam around my desk as I played with
          my toys on camera, almost RELISHING the way accidents would happen in
          those long takes (looking back, I see a lot of that strangely in my
          favorite moments of my hero Bela Tarr). You can probably see that in
          my work to this day.
          <br /><br />
          I do have to say, though, that I didn't become anywhere near a
          cinephile until my late teens, partially because so much of the
          "serious" film culture surrounding me in the Chicago suburbs had a
          suffocating bro energy I felt so uncomfortable with before I came out
          in 2018. At the risk of sounding like a freak, so much of my influence
          came from being immersed in the BBW paysite scene since middle school,
          and loving the ways being an auteur of your own body and working with
          early digital technology created its own aesthetic that sometimes
          doesn't feel that much different from what made me fall in love with
          Akerman's Je Tu Il Elle at the end of high school.
          </p><br />
          <h3
            >What other mediums (apart from film) do you explore as an artist?
            How do they communicate with your film practice?</h3
          ><br /><p>
          I was a competitive dancer for two years. It was in ballroom, which is
          often cringe and patriarchal and racist, but one thing I don't know if
          people realize about it compared to, say, ballet, is how it's all
          about tactile connection with another human being. Your creative
          intuition isn't based on the audience, but in sensing the pressure in
          your partner's hands to figure out where they might want to go next. I
          genuinely think this was an extraordinary training for me as a
          filmmaker, and you can see that dancerly energy in so much of my work.
          </p><br />
          <h3
            >How do you imagine the audience might perceive your film? Or how
            have you seen people react to it in a way that stood out to
            you?</h3
          >
          <br /><p>
          I've noticed since screening my growing catalog of short films at
          Chicago's Sweet Void microcinema that lots of people are very stressed
          out by how raw my movies are. Part of it is the frenetic way I've
          often edited them (for better or worse, you can compare the way I
          based the editing of my first two features on the rhythms of Chicago
          footwork to how Aronofsky did so with NYC hip hop with Pi and
          Requiem). I've tried to make more "comfy" movies (even with The Squish
          Trilogy I genuinely DID think I was making something happy), but I
          can't help that I LOVE miserablism in movies, probably because it
          makes my C-PTSD feel SEEN.
          </p><br />
          <h3>How do you approach making a personal film?</h3>
          <br /><p>
          I honestly don't think I know HOW to make any other kind of film.
          Maybe part of that is that I spent so much of my life hiding my
          womanhood, and with that so many other essential parts of myself, so I
          feel like it's important to show that in every frame of what I make. I
          also come from the tradition of outsider art, first deciding to make
          music in 2009 when I discovered the way fellow Chicagoan Wesley
          Willis's art was inseparable from his bodymind.
        </p>
      </div>
    </div>

    <div
      class="chat"
      style="border: 1px dotted #212121; padding: 30px; height: 400px"
    >
      <script
        type="application/javascript"
        id="cid0020000363316340705"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "goddesschristine",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>

    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>

<script>
export default {
  name: "marker",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
  line-height: 1.6;
  background: #664c71;
  font-family: hersheyOld;
  height: 100%;
  color: #f3f9e6;
  padding: 30px;
  overscroll-behavior: none;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  line-height: 1;
  font-size: 45pt;
}

h2 {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

ul {
  font-size: 15pt;
}

.logo {
  width: 50px;
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;
}

a {
  color: #f3f9e6;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  background: #f3f9e6;
  color: #664c71;
}

.ts-contain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.ts-box {
  border: 1px dotted #212121;
  padding: 30px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.youtube {
  border: 1px dotted #212121;
  padding: 30px;
  margin-bottom: 10px;
  height: 55vh;
  min-height: 350px;
}

.chat {
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .ts-contain {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  .ts-box {
    height: 300px;
  }
  .youtube {
    height: 200px;
    margin-bottom: 20px;
  }
  .chat {
    display: none;
  }
  .credits {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
</style>
