<template>
  <MainLayout>
    <body>
      <main>
        <div>
          <div>
            <h1 class="head">Figma Dinner Party With Leah Maldonado</h1>

            <p class="headDes">
              <a href="https://www.instagram.com/fun.weirdo/">Leah Maldonado</a>
              and <a href="http://tiana.land">Tiana Dueck</a> met for a Figma
              Dinner Party, to discuss Leah’s art and work practices in relation
              to her heart.
              <br />
              Featured in <router-link to="/issues/heart">Heart</router-link>.<br />
              Listen to (<a href="https://open.spotify.com/show/2fo1GJaSCXUBF5iMCZgBQJ?si=d9f210436b884734">Spotify</a>) or watch to hear more of our conversation.
            </p>

            <hr />

            <div class="container">
              <iframe
                src="https://www.youtube.com/embed/Pu2zB_3DkXs"
                frameborder="0" allowfullscreen class="video"
              ></iframe>
            </div>

            <hr />

            <br />

            <iframe
              style="border: 1px solid rgba(0, 0, 0, 0.1)"
              width="100%"
              height="450"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FoEblZW1b13OYlru0zY6aQP%2FUntitled%3Fnode-id%3D12%253A12"
              allowfullscreen
            ></iframe>

            <p>
              Leah Maldonado and I met on a calm winter day for a glamorous
              Figma Dinner Party, and conversed about our hearts.
              <br /><br />
              <span class="leahM"
                >“I’ve lived in Portland for a really long time, which maybe
                isn’t the norm of the people who are here now. I feel really
                connected with Oregon in general, and the natural world here a
                lot. I love where I’m from, I think that’s a huge part of my
                identity. I work as a graphic designer and make artwork as a
                type-designer. I made Glyphworld, which I think is my one hit
                wonder. It’s actually not one hit, it’s a full album. There’s
                lots of fonts within that typeface. 
                <br><br>
                So that’s kind of like my
                claim to fame. I’ve made other typefaces, but they haven’t
                really received the same type of attention as my star child —
                which is Glyphworld, who I’m beginning to kind of hate a little
                too. Glyphworld is like, growing up, they’re not a cute baby
                anymore. Besides type, I also enjoy doing performance art, or
                like, workshop/interactive art that requires a group of people
                in a classroom or some collaborative setting where we learn
                something together. And the output of that feels kind of like an
                art piece that we all share. So yeah, that’s who I am,”
              </span>
              she said.
              <br /><br />
              Leah and I both have a strong affection for collaborative art, so
              I was excited to collaborate with her over our Figma Dinner Party.
              She has a really vibrant and playful collection of work and
              personality, so I had a lot of questions prepared to cook up and
              savour with her.
              <br /><br />
              “What is the relationship between love and design for you?” I
              asked.
              <br /><br />
              She began drawing a circle, as if setting out all the ingredients
              of our dinner. She chopped up a venn-diagram to compare Love to
              Design.
              <br /><br />
              <span class="leahM"
                >“I wish I was way faster in Figma… So this is the Love circle
                and this is like Design. I’m sorry, but I need time to make it
                perfect.”</span
              >
              <br /><br />
              “It’s OK, cooking takes time!”
              <br /><br />
              <span class="leahM"
                >“Cooking does take time… These are a little bit, you know, not
                centred in the middle, but that’s okay. That’s a stylistic
                choice I’m making here. I think your question is, ‘What lies in
                the overlapping area for me?’—and currently, I have been trying
                to think as if there is no such thing as that intersection,
                because when I started out in my career, like in school, this
                was my venn-diagram...”</span
              >
              <br /><br />
              She moves the circles to overlap.
            </p>
            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2010.png?v=1663889715030"
              />
              <br />
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2013.png?v=1663889715317"
              />
              <br />
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2011.png?v=1663889715133"
              />
              <br />
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2014.png?v=1663889715359"
              />
              <br />
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2012.png?v=1663889715193"
              />
              <br />
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%2015.png?v=1663889715266"
              />
            </div>
            <p>
              <span class="leahM"
                >“When I was more in control of what I would use design for, and
                my professors would give me interesting briefs and stuff, and
                the briefs were all about just growing my knowledge and making
                me better. Then, when I started my career, I kind of entered
                with a perspective like, ‘well, everything can’t be perfect, but
                it’s probably gonna be like this (Love and Design overlapping).’
                It was stupid of me to think that a workplace could offer that
                venn diagram. It actually just made it so the center of this
                diagram was: abuse of my time. I was so fresh, new, and willing
                to do everything to the best of my freaking possible ability. I
                was treating every-thing like a thesis… It may have made me a
                great worker, that would work overtime all the time and stuff,
                but I put everything into things that were meaningless. 
                <br><br>
                So I’ve
                had a huge reaction to that, and I’ve been feeling like there is
                no intersection. But maybe there is an intersection between Love
                and Art or Creative Output. For me now, I’m feeling like Design
                = Work—I used to think Design = Artwork. I think it’s a bad use
                of your innate urge to create artwork, to funnel it into a brand
                solely for validation. Instead, you should probably funnel it as
                a medium to express something to the world. Like in art, I’m
                always really thankful for the way canons have preserved certain
                things. I’m also really critical of the canon. I think that the
                act of putting a canon on art and asking, ‘What is
                high/acceptable art?’ closes off the spectrum to a lot of good
                stuff that’s probably out there. But all of that preservation
                towards artwork that we consider artwork (whereas an ad campaign
                is not considered art) has been so helpful for us to communicate
                with the past, in order to maybe understand some type of future.
                <br><br>
                At the time that an artist was making something, I’m sure that
                it was a way for them to deal with their present. So art becomes
                this emotional timeline of how we’ve always felt, or how that
                canon has always felt. Whereas, if you were to overlay that
                timeline with a timeline of only ad campaigns, it would probably
                show us a whole separate set of emotions. Like, I actually don’t
                know if German Expressionism found its way into advertising. I
                have no idea. Maybe there is some correlation there because I’m
                sure that there’s plenty of correlation now, like artwork
                constantly finds its way into ad campaigns, since basically the
                only lucrative job for an artist is to go work in advertising.
                So, the big difference between Design and Artwork is Money. But
                some people will find a way to make plenty of money with
                artwork. So I’m not necessarily saying facts, just my opinion.
                In the start of my career I was working incompatible jobs, it
                was really difficult for me to give into another person’s
                imagination and become a human cursor for that. I wish that they
                would have just learned the tools and done it themselves.”</span
              >
              <br /><br />
              She garnished her venn-diagams as I nodded in agreement.
            </p>

            <div class="imgBox">
              <img
                style="width: 90%"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Design%20%3D%20Work.png?v=1663889077486"
              />
              <br />
              <img
                style="width: 90%"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Design%20%3D%20Money.png?v=1663889077486"
              />
              <br />
              <img
                style="width: 90%"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Art%20Work%20%3D%20Expression.png?v=1663889077486"
              />
            </div>

            <p>
              “What things in your life overlap with Love right now?”
              <br /><br />
              <span class="leahM"
                >“Honestly, super corny stuff. I love my Family. I guess in the
                center of that is quality social time. While I don’t love all my
                family, the family that I do love gives me the best social time
                ever. Also, basic stuff like TV, quality entertainment time, and
                Dinner. Yeah, I love dinner.”</span
              >
              <br /><br />
              “Oh yeah.”
              <br /><br />
              <span class="leahM"
                >“I’m definitely obsessed with stuff, like fixated on things,
                but I’m trying hard not to tell myself that that’s love. It’s
                too much, to hyper-focus on something for too long, because I’ll
                ignore other stuff. I’ll forget to drink water or I’ll forget to
                text somebody back. It’s really important that I do that. I
                think love is really powerful. I’m a little scared of it. So I
                only want to give it to things that are like—maybe I should take
                off TV. That’s bad.”</span
              >
              <br /><br />
              With a change of heart, she tended to the TV venn-diagram as if it
              was burning to the point of setting off the Figma fire alarms. I
              waved my digital towel at them.
              <br /><br />
              <span class="leahM"
                >“I mean, in moderation it can be refreshing for sure. Even
                though Love and Work or Design don’t overlap, it still plays a
                part in the balance of feeling joy and enjoying life. What does
                that boundary feel like right now, with balancing everything
                else that is important?”</span
              >
              <br /><br />
              “So, over time.”
              <br /><br />
              Leah turned her attention to another portion of our Figma
              counter-top, and started making digital noodles that gestalted
              into a graph.
            </p>
            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%206.png?v=1663889864975"
              />
            </div>
            <p>
              <span class="leahM"
                >“Are we saying like, this is Time and this is Love?”</span
              >
              <br /><br />
              She labeled the axes of the graph.
              <br /><br />
              <span class="leahM"
                >“Then we’re putting it in the world of Free Time—or maybe this
                isn’t Love, maybe this is Interest… Now I’m just making up the
                question.”</span
              >
              <br /><br />
              “Please cook! Make the recipe your own,” I insisted.
              <br /><br />
              <span class="leahM"
                >“I found the spice cabinet and I’m just throwing stuff
                in.”</span
              >
              <br /><br />
              “Please make yourself at home.”
              <br /><br />
              <span class="leahM"
                >“Okay. If this (left-most of the x axis) is when I was born and
                this (right-most of the y axis) is when I’m going to die... I’m
                going to start from the end. I hope that right before I die, I
                give my maximum energy to my interests. Since I won’t have any
                more time after that to do it. But, before I die, I hope that I
                spend time with things that aren’t my interests. Like, I’ll
                still pay attention to some stuff, like my family, but I won’t
                give everything to them. In my senior years, I hope that I will
                have a seasonal fluctuation with interests. Maybe this (highest
                points) is summer times spent alone doing what I like. Or with a
                partner that it feels like being alone with, you know. Then,
                this (low points) is the holidays or something where I just have
                to see everybody. Then, I hope that right before I go in to my
                old years, I let go of all interests. So maybe I can make new
                ones. When I was a child, anything that interested me I gave my
                full energy to, and it was just downhill from there.”
              </span>
              <br /><br />
              We both laughed at the truth she was depicting in her pixel
              noodles. Leah asked me,
              <br /><br />
              <span class="leahM"
                >“Would you give anyone advice on how to avoid a steep, downward
                path? Like look, we’re not going to promise that you won’t go
                downhill, but maybe we can give some people advice on how to do
                a more stepped approach?”</span
              >
              <br /><br />
              “I feel that community helps a lot. If you’re in life alone,
              that’s especially difficult. But if you find connections where you
              are pretty consistently inspired by the people around you, and it
              reignites motivation, it cushions the fall.”
              <br /><br />
              <span class="leahM"
                >“What about advice for that vertical downfall, any advice for
                dark times?”</span
              >
              <br /><br />
              “I think people always say that their most creative ideas come out
              of their worst experiences. So maybe take notes while you’re in
              that dark time. Like, why is this so terrible right now? How could
              this become my next big thing? For me, at one of my first real
              jobs that I was really excited for, the boss ended up being a
              really strange person. I felt very stuck in life. But a couple
              years later, I ended up writing a script that I really liked.
              Basically, the main character of it was this terrible out of touch
              boss who was really fun to fictionally write and read about. So I
              was like, ‘I’m glad that getting to know that terrible thing
              helped something.’ Remembering that it’s all a part of the cycle
              helps.”
              <br /><br />
              <span class="leahM"
                >“Yeah. I really resonate with writing a script based on your
                life. I think that’s so funny. I would love to read that script.
                Like a table read is the perfect COVID hangout. But yeah, I
                would suggest writing things down. Or using that time to start
                planning your art project, maybe that can even help you regress
                a little bit, like, I think planning is really helpful. You
                don’t have to do anything, you just have to plan it and it feels
                kind of good to plan it. Even if you don’t do it. So it’s like a
                way to trick yourself, is to just start planning. And besides
                journaling, I think you could do other things that aren’t word
                related, you know, it could be recording in any way. But also
                maybe just feeling it. Like, I’ve stopped just hitting the
                pillow and closing my eyes. Now I have to force my eyes closed,
                like I’ll read or look at my phone until I close my eyes. But I
                think I should probably just go to bed and close my eyes, let
                myself think for a second uninterrupted, and have that force me
                to fall asleep. In those moments, I think we process a lot of
                that scary stuff, but it’s good to think about it. Maybe it’s a
                little too scary for some people, so be careful.”</span
              >
              <br /><br />
              “Yeah. Building on that, spending time in nature helps. Where I
              live now, it’s really easy to go to a waterfall. Just being in a
              forested area, you get into that moment before bed headspace.
              Where it’s just your thoughts and everything turns off. It’s a
              little less scary when you’re in a forest. Things feel a little
              more zoomed out, so maybe that’s a good warmup. What do you tend
              to follow in life, your heart or your mind?”
              <br /><br />
              <span class="leahM"
                >“I really think I follow my heart and that leads me into
                situations where I need to control how much of my heart I follow
                in certain things and remove it from the situation. I think it’s
                really difficult to not be emotional about stuff. As logical as
                I think I am, I really am not. I’m so emotionally-minded. I
                don’t know what the fact is, I just go on my gut feeling. Do you
                think that you’re more mind over heart?”</span
              >
              <br /><br />
              “I’m probably more heart over mind. And for you, even though you
              said Love doesn’t overlap with Work, maybe we can imagine a map
              where your Heart’s in the middle, and then the force field around
              it with protection. How far do you keep work and other things away
              from it?”
              <br /><br />
              <span class="leahM">“Yeah. Okay, I’ll make a diagram.”</span>
              <br /><br />
              She brought out a digital rolling pin and streched out three
              doughy digital layers. Then she started dressing it.
              <br /><br />
              <span class="leahM"
                >“So you have your Heart in the middle. Then things that you
                love, and things that you keep your heart at a little bit of a
                distance from. Okay. I’m trying to make this, but it’s kind of
                like failing a little bit, so let’s see... Okay, this is good
                enough. The closest thing I keep to my heart is probably my
                emotions. Farther from that would be judgements. Well, I try my
                best to keep what I perceive other people think about me—or my
                negative perceptions of myself—away from my heart. I think that
                my heart is like my life force. And actually, LOVE is really
                close to my heart, but separate from the machine. I don’t know
                what would be in the middle layer. Maybe my friends, I think
                having boundaries with friends is probably good practice. But I
                am the type of person that believes a friend could become
                family, definitely at a certain point.”</span
              >
              <br /><br />
              “Yeah, I think similarly.”
              <br /><br />
              I stretched out my heart dough and decorated it too, with strong
              inspiration from Leah’s, as I resonated.
            </p>
            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%203.png?v=1663889936990"
              />
            </div>
            <p>
              <span class="leahM"
                >“I have another category that I’m curious about. You know how
                some people say, ‘from the bottom of my heart.’ If that is a
                different section, it’s kind of independent of the layers. What
                do you think is at the bottom of your heart?”</span
              >
              <br /><br />
              “Oh man. Probably the Tao, if I get into the spiritual things that
              keep me going when it feels like there’s nothing of me left—it
              feels like there’s just the universe, and just that luckiness of
              being a part of this speck of the universe that can experience
              itself, you know? So I would say: being.”
              <br /><br />
              <span class="leahM"
                >“Yeah, I’m going to kind of copy you. Instead of being, I will
                say luck. I think right now I’m so lucky to be here, and I’m
                glad that I’m in this time. I’m glad that I’m of this time, even
                though it’s a crazy time. If I was born maybe just 40 years
                before I would have never seen—or I guess we would
                have—nevermind. I think there’s no time that we could have been
                born that would protect us from scary sickness. I don’t know. It
                seems a little scarier than other times in my life, but maybe
                it’s just because of my age. Like, do you think little kids are
                really, really, really afraid of COVID, or they don’t
                understand?”</span
              >
              <br /><br />
              “I don’t know that they understand it. Sometimes I’ll remember my
              brain as a kid, and there was definitely a lot of anxiety. I think
              the social pressure when you’re a kid is like your whole world,
              and that feels similar to virus anxiety sometimes.”
              <br /><br />
              <span class="leahM">“Yeah. I can resonate with that.”</span>
              <br /><br />
              “What about you, what’s the contrast between your kid brain and
              your now brain?”
              <br /><br />
              <span class="leahM"
                >“Sometimes I feel like I’ve held onto a lot of the neuroses
                that I developed as a child. I haven’t let them go, and instead
                polished them and made them grow old with me. I think I’m a
                hypochondriac now and I was a hypochondriac when I was a child.
                A child could believe in really fantastical stuff, while both
                totally believe in things that could never happen to me. But now
                I believe I could have a rare cancer that only 1% of the whole
                world gets, which is as fantastical as believing that the
                watermelon seed I swallowed will turn into a tree in my stomach.
                They’re like the same thing, but one is the adult version. I’m
                like a little girl underneath it all. So actually, at the bottom
                of my heart it’s a child.”</span
              >
              <br /><br />
              Leah finished decorating her heart dough and we put it in our
              Figma oven.
              <br /><br />
              “Yeah, that’s good. Speaking of friends on this other tier, how do
              you show your friends or family that you love them?”
              <br /><br />
              <span class="leahM"
                >“I really love to cook for my friends. Like honestly, we had a
                big dinner party and I had so much fun planning out the oven
                times. I was basically doing my own version of Thanksgiving
                planning for my 12 person dinner party, preparing and laying out
                the food, and then presenting the food to my friends. Having
                this very-formal-giving during my birthday, that they would have
                to be so kind and thankful to receive, because it’s my birthday.
                (laughs) So, it was a little bit of a selfish thing. I also
                think that playing is a way that I really like to show love to
                my friends. I have only ever thrown themed parties. A party
                without rules is a little too scary for me to try to throw. I’ll
                go to one, but I don’t think I could just throw a party with the
                theme of: just have fun naturally. 
                <br><br>
                What I’m trying to say is
                that I kind of struggle with natural socialization, and I love
                it when my friends can indulge me in my attempts to indulge
                them. With my family, I think that all of those boundaries go
                away, and my family doesn’t fucking care if I’m having a good
                social time or not. It’s more like, maybe family is about
                arguing without there being a consequence. Not about anything so
                negative, but just about the world. Just being able to be 1000%
                honest with my family, and tease and bully people a little. We
                sound like we’re awful, but it’s nice to be able to share that
                honesty and humor with people, without it leading to someone
                hating you or something. So that’s how I show my love to my
                family and friends. There’s more ways too, like hugs and
                kisses.”</span
              >
              <br /><br />
              “Yeah, classic. It’s interesting how the closer it gets to the
              heart, the more tension that builds as well.”
              <br /><br />
              <span class="leahM"
                >“Yeah. Well, I think that I’m a little bit of a cold person, so
                I guess the closer you get to my heart, the colder it might
                feel. But hopefully you like the cold. There’s definitely people
                who hate too much warmth, and I don’t know how you feel about
                that, but I certainly do not get along with people that are a
                little bit not analytical or critical enough.”</span
              >
              <br /><br />
              “Are you a Virgo at all too? Because I’m a Virgo rising, and I
              resonate with that area of things.”
              <br /><br />
              <span class="leahM">“Yeah, that’s exactly what I am.”</span>
              <br /><br />
              “Great, so that’s just basically what this all narrows down to
              then.”
              <br /><br />
              <span class="leahM"
                >“Yeah, and then I’m a Leo sun on top of it. So I feel like it’s
                a special type of psychopath. Like, not only am I cold, but I
                will say it really loud.”
              </span>
              <br /><br />
              “Right, that’s powerful though.”
              <br /><br />
              <span class="leahM"
                >“Powerful is a nice word, I think other people have said
                mean.”</span
              >
              <br /><br />
              “No! Like, I’m a Libra sun on top of my Virgo rising, so we’re
              pretty compatible there. Because Libras are known to be a bit
              indecisive, so whenever I meet a decisive person I’m like, ‘Wow,
              it’s amazing that you can decide that, and say it out loud.’
              Anyways, Is there anyone whose warm-heartedness has impacted how
              you live and work?”
              <br /><br />
              <span class="leahM"
                >“My teacher, Kate Bingaman-Burt, at Portland State University
                is probably that person. She kind of teaches from the Sister
                Corita Kent rules, which are really important to me now too.
                Maybe at the root of it all is Sister Corita Kent, or whoever
                taught her, which I fear is a bit too religiousy for my taste
                now, but filtered through Kate. I’m so glad to have had that
                person in my life because she showed me the benefit of fostering
                community. Leaning into chaotic stuff and trying not to worry so
                much. Some people, when they to tell you not to worry, they
                might actually mean: stop caring and relax by avoiding or not
                doing so much. But Kate Bingaman-Burt was like, don’t worry
                about all of this stuff that you have to do, make a to-do list.
                And I really liked that. 
                <br><br>
                It was really helpful to have an
                example of somebody who was like, there’s no task that you can’t
                not try to do. She affected me and my cohort of peers in similar
                ways. So, I’m really thankful to be a piece of that little cast
                of people that was the community she was fostering. She’s very
                expressive, a yell talker, which I really respect. Then, Sister
                Corita Kent. I’ll actually find the rules right now… So, she had
                an art class in a church around the ‘70s. She was kind of kicked
                out of the church, because I think her art class started to
                surpass the (Catholic) churchy stuff. So then it just became an
                art class, but… I should say Sister Corita Kent is super into
                God. Like, she really believes in God. Which is fine. It’s
                something I’ve totally seen in a lot of artists, because I had
                to do the typical art history classes and basically learn about
                Catholicism through an art history lens. But I think anything
                spiritual that compels people to do more than their human
                capacity is cool and worth looking at. Whether that is people
                who believe that they are possessed by something or people who
                are very religious and are doing it out of piety or faith, or
                even people who become faithful to things that aren’t higher
                powers… I don’t know, I guess at the beginning I was saying the
                opposite, but maybe people who become super faithful to making
                money start to create spiritual work too… You know, when an
                artist is at the height of their career and they’re getting the
                most money that they’ve ever had, and then they make new work
                and are building this crazy, crazy thing? I think that’s
                probably them connecting with something. 
                <br><br>
                I want to share Kate
                Bingaman-Burt’s work. I’ll share her drawings. She has this
                practice where she compulsively draws everything that she buys.
                When I met her, that was her claim to fame. It was really
                interesting, because while she does this illustration, and this
                is her illustration style, I started to realize that being a
                designer could be really different. Like, who you were as a
                designer could be very different from who you were as a visual
                outputer, artist, creative, like whatever that title could be. I
                don’t know if this picture or anything that she’s drawn
                represents the type of artists that she is to me. I think you’d
                have to go to her class to experience that version of her. Maybe
                that’s why I really want to find her face. Okay, here’s her
                face. I think that this kind of helps sum it up.”</span
              >
              <br /><br />
              Leah brought out these images from our Figma oven, and uncovered
              them carefully. Their comforting aromas spread throughout the
              whole room.
            </p>

            <div class="imgBox">
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/image%206.png?v=1663890063659"
              />
              <br />
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/image%208.png?v=1663890066009"
              />
              <br />
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/image%207.png?v=1663890063002"
              />
            </div>

            <p>
              “That’s incredible. How do you get your heart in the right place
              before getting creative when you’re doing something for fun or
              art?”
              <br /><br />
              <span class="leahM"
                >“I think I need to become obsessed with something before I can
                really make work about it. I think my public output is what I
                was obsessed with, which was getting to know Photoshop and
                making something in the program that was so shiny, shining back
                at me. It was so satisfying to have a computer screen playback,
                in full brightness, the colours that I was putting down on the
                canvas. To be getting better and better with my tool, then
                grabbing new tools and using those and getting even better. Then
                I started to get a little bit disgusted by all of the
                maximalism. I started to struggle with letterforms, and finding
                time outside of work and yada yada. So my obsession with that
                tool kind of faded away, and overlapped with my new obsession
                with learning, or the pursuit of teaching. I was trying to teach
                a class, and I thought that it was cool to write lectures, and
                now I’m kind of obsessed with writing, but I don’t really
                publish a lot of that on my channels. I have my journal that I
                keep, I’m on are.na, but a lot of the writing I do is misspelt
                gibberish in my notebook. I love that stuff. I don’t know if
                that will ever be public work, but it’s definitely my practice
                right now. To get my heart in the right place to do that just
                means not setting some ritual like, ‘every day for 15 minutes, I
                have to turn on my journal!’ I kind of have to procrastinate for
                a really long time, and then hyper fixate on it for six hours.
                That’s a perfect place for me to be.”</span
              >
              <br /><br />
              “I am glad you said that, it’s re-assuring. That’s what I did
              today too.”
              <br /><br />
              <span class="leahM">“What did you fixate on?”</span>
              <br /><br />
              “I spent like six hours on Tik-Tok or something, you know, which I
              re-downloaded, which has been hell. Then I transcribed some
              interviews.”
              <br /><br />
              <span class="leahM"
                >“How many times have you deleted Tik-Tok?”</span
              >
              <br /><br />
              “A couple times… I’m letting myself have it right now. Something
              in me is like, ‘you’re allowed to have this right now.’ and that’s
              perfectly fine with me. Like you said, any task can get done. I
              have my to-do list, I’m knocking things off of it, so that’s good.
              But I think a lot of people strive for the perfect schedule, but
              maybe the perfect schedule is a chaotic one. Is that a process
              you’ve come to peace with, or?”
              <br /><br />
              <span class="leahM"
                >“Oh, hell no. I wish I was like a robot. I’m also trying to
                treat Tik-Tok like it’s something I need to be sober from,
                because I had a really bad issue with Tik-Tok and have deleted
                it countless times, only to download it again. I was clocking a
                lot of hours on Tik-Tok. I don’t even want to say.”</span
              >
              <br /><br />
              “I think a lot of people can relate.”
              <br /><br />
              <span class="leahM"
                >“It was really bad and took away whole days of mine. I had to
                use it for research at work, so I’ve tried to stop.”
              </span>
              <br /><br />
              “That’s the worst.”
              <br /><br />
              <span class="leahM"
                >“Yeah, It’s super the worst, but it’s really crazy to have been
                in control of some things on Tik-Tok. Like, to make Bretman Rock
                do something. At one point I was the puppet master of Bretman
                Rock.”</span
              >
              <br /><br />
              “What! Can you explain?”
              <br /><br />
              <span class="leahM"
                >“Well, at Nike, or any other large brand, often they aren’t
                doing anything with their own brand’s Tik-Tok page. Those are
                kind of like placeholders just to have something. What they
                really do is work with influencers and creators to support
                campaigns, and use hashtags and stuff like that. So we’ll reach
                out to some type of influencer, whoever they might be, and use
                them as ads. Which I think is consensual with some people who
                seek to be influencers, but I don’t know if it will ever be
                consensual with the generations who follow those people. Like
                children that are online, and such. I think the pursuit of fame
                is a completely non-consensual urge that we all have now because
                of our platforms, and it feels really bad. I’m very afraid for
                the generation after the one that’s in college right now, or the
                youth right now! I hope that the internet explodes.”
              </span>
              <br /><br />
              “Boom! ...Fame is a whole thing.”
              <br /><br />
              <span class="leahM"
                >“Definitely. I think I got really, really far away from your
                original question.”</span
              >
              <br /><br />
              “That’s OK! It’s interesting too. What was my original question…
              getting your heart in the right place to be creative. I think
              we’ve stemmed from that somehow, I guess it relates to those wild
              passions that our hearts get stuck on, such as fame. I feel like
              in the generation before me, Pop was about celebrities, like Mary
              Kate and Ashley, and it was like, they’re the celebrity and I’m
              the person. Like, yes I have that urge to be famous, but it’s such
              a separate world. But now with all these channels, like YouTube
              and Tik-Tok, it feels more and more accessible, but it’s not
              necessarily, and it’s like… what’s happening? Anyways, you said
              you’ve deleted Tik-Tok a bunch. What has your ego journey been
              with social media? Especially since you work in it, you can’t
              delete the app from your phone because you’re working with it.”
              <br /><br />
              <span class="leahM"
                >“Now I try to look at social media as my job, and keep my
                person-hood really separate from it. I even lean into it if I
                have to, because I totally accept that the only way to become a
                successful artist or do things that require crowd participation
                is to maintain some type of crowd. I feel as though,
                specifically in the type world, I hold some type of crowd that’s
                small enough that I feel like I can owe them something and
                deliver on that thing. It’s small enough where I can withhold
                anything, and maybe be silent forever, and no one would be mad
                at me or be like, ‘did you die?’ I also enjoy one-on-one email
                correspondence with people that I respect. 
                <br><br>
                I think that’s better
                than any type of following that you could amass, these
                heart-to-heart relationships that you have with people that you
                respect in the world. Which leads to a whole different digital
                social network, but de-platformed. Maybe it’s like the social
                network of email. I’ve asked myself a few times, ‘do I need to
                get a Tik-Tok to stay relevant?’ and my answer is, ‘well, I have
                one, and I definitely make really stupid content, but I don’t
                think I’ll get to a point where I’m going to make content about
                type in a serious way.’ I don’t think I’ll participate in that
                platform publicly. I’ll probably let myself die with Instagram
                and not ever pursue more followers than what I have now. When I
                was graduating school, I realized that I would have a higher
                chance of getting a good paying job if I had more followers. And
                I was also trying to make Glyphworld into this thing that would
                become a golden egg that people would hire me for having. So I
                was hashtagging my work and that felt like, I dunno—I’m gonna
                use this word and I mean it in a really neutral way, for its
                definition—but I felt like I was being a bit of a whore, and I’m
                just really aware of that. I think it was okay at that time. 
                <br><br>
                And
                it’s always okay to be a bit of an attention whore, if you need
                it. Because attention can become rent, your salary, or health
                insurance. As an artist community, we should continue to support
                each other needing attention on these platforms, and understand
                that it’s totally okay to need to be an attention whore. But I
                do think that it’s good to be critical of it. Only in a way that
                would protect people. I think it’s too much to turn your whole
                self into an ad space, but you can make a lot of money doing
                that. So if you need money that’s okay. But you should be
                careful about doing that. It can be really dangerous. At some
                point I think it’s important to set some limits. 
                <br><br>
                I think
                sometimes brands will require that you have like this set of
                unspoken requirements. I think it’s a lot easier to get a job in
                general where they don’t look at your resume for that long. If
                you have an Instagram with over 30K followers, you’ll get a job.
                Even if you’re not proficient in the skills needed to do the
                job. I also think though, if you have like 250 followers, people
                might actually pay you more than people with more followers, to
                do a job that requires skill. But to do any job that requires
                communication or becoming an ad space for a bigger audience or
                something like that—just buy followers, you’ll make
                money.”</span
              >
              <br /><br />
              The water on our Figma stove was boiling over, so I turned the
              heat to medium and added our pixel noodles that Leah made earlier.
              <br /><br />
              “So, since you went through the Glyph-world phase, whoring
              yourself, and now on the other side of it­­—where would you say
              you are now?”
              <br /><br />
              <span class="leahM"
                >“I think I accomplished my goal with Glyphworld to canonize
                myself in the modern typographic design zeitgeist. Maybe not
                history, I don’t know what will be preserved or if I will remain
                part of the conversation, but I did what I wanted. I wanted a
                seat at the table of the type-world, and I wanted to have my own
                seat, a seat that I designed for myself. One of expertise that I
                could talk about, and I didn’t have to pretend to be the people
                that were already sitting at the table. So I feel as though I
                was able to infiltrate and achieve my goal and now I’m so lucky.
                I could kill my digital self and I would still have a salary and
                professional life. I know enough people now behind closed doors
                to not have to have that public persona, and to be respected
                while not having an Instagram. I think it’s kind of really
                similar to being a whore—I think that money is the difference
                between being seen as a business person or a whore; but, both
                are the same thing in a way. Again, let me say that I’m trying
                to use the word whore as not a bad word. I don’t want to be
                offensive to anybody or anything, but I really just believe that
                the definition of that is fine.”</span
              >
              <br /><br />
              The noodles were a perfect al dente, Leah strained them in our
              Figma sink.
              <br /><br />
              “I think a lot of people coming out of university or school have
              the energy of like, ‘okay, now I gotta get into the career,’ and
              feel that pressure behind not having that network yet. So you
              wonder how you’re going to make that network? You don’t know how
              that’s going to work out. So everyone’s like, begging on their
              hands and knees for something—putting their all into something a
              little naive. There’s some naiveness in it, but it’s an essential
              part of the process.”
              <br /><br />
              <span class="leahM"
                >“I think that begging is a very valid technique, starting out
                on your hands and knees will work.”</span
              >
              <br /><br />
              “At least a little bit.”
              <br /><br />
              <span class="leahM">“Yeah. It’ll get you there.”</span>
              <br /><br />
              We started on the broth for our noodles, adding a bunch of what
              Leah had chopped up earlier to a big pot.
              <br /><br />
              “Thinking about the designs and art that you share to your social
              media, are they as fun to make as they are to look at?”
              <br /><br />
              Leah stirred the contents of the pot around and around
              energetically.
              <br /><br />
              <span class="leahM"
                >“There’s so many things that the computer can do with me that
                make it so fun to do. The way the computer can mix colors is
                very different from the way analogue mediums do. Like, I can set
                a brush’s transparency setting to screen or luminance. Which
                blends colors in a whole new way that only the computer could do
                with me. And I think that’s part of why I enjoy it so much.
                There were outcomes that continuously surprised me, because I
                was learning the tool as I was making the work. A lot of that
                process is about hitting this type of realism or a polished
                feeling that it evolved into. It starts off kind of blobby, and
                over time and layers, it starts to look more like I rendered it
                in a 3d program, but the whole time I’m just using Photoshop.
                The lettering part too – It’s one thing to make letter forms in
                black and white, and those can look so good in black and white,
                but it’s another thing to put the third dimension onto them and
                wonder, are they made of like a soft texture? ‘What material are
                they made out of, and how can I depict that?’ was a fun thing to
                think about with letters. Lately I think less about
                illustration, and I’m trying to focus on writing workshops, but
                I don’t know how I will share that on Instagram or how I will
                share that. I’ve thought about writing a few essays, but I think
                that on Instagram, the only thing that those essays could become
                would be an infographic. So I’m starting to feel as though
                Instagram is crumbling, and I don’t really know where to put my
                work now because that’s kind of the only space.”</span
              >
              <br /><br />
              “Yeah. Well, I’m a big advocate for just making your own website
              and presenting it in a super fun way there. But yeah. Instagram is
              definitely limited with that kind of expression. I’m excited to
              ask you this, what letter do you think would make a great friend
              and why?”
              <br /><br />
              <span class="leahM"
                >“Oh this one is really easy. I’m glad that you asked. I think
                I’ve felt this way for a really long time, and I don’t know if
                it’s ever going to change: a double story g. I think this is
                maybe the friendliest letter because it’s really complicated and
                bizarre and weird. It makes a lot of sense when it’s a serif. It
                works better when there’s some contrast going on. The double
                story g does get made into things that aren’t serif, but I think
                when that happens, it’s even funnier. It really reminds me of a
                human body. It has some things that humans have. It has an ear,
                and I think that this is maybe called a neck or an arm. This is
                like the tail. It has funny little body parts. So that’s kind of
                my favorite letter. What’s yours?”</span
              >
            </p>

            <div class="imgBox">
              <img
                style="width: 90%"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/g.png?v=1663890177534"
              />
            </div>

            <p>
              “That’s really nice. The first thing I saw, I kind of put it on a
              3D plane where the circle at the bottom is a pond and the top is a
              sun, so it’s like a sunset.”
              <br /><br />
              <span class="leahM">“Wow, that’s really beautiful.”</span>
              <br /><br />
              “I liked seeing that for a second. I’d have to think about mine
              more. I do think about the lowercase letter a a lot. It’s a
              dependable letter.”
              <br /><br />
              <span class="leahM"
                >“Yeah, definitely. I depend on that letter a lot too. Like a
                double story g, I think a double story a or even a single story
                a can hold the entire personality of a typeface. It’s something
                I depend on to sketch with. It’s also the first letter of the
                alphabet, so I think that’s why I go there first. Did you have a
                point in your life where you wanted to try making the letter a a
                little different in your handwriting?”</span
              >
              <br /><br />
              “Yeah, I would experiment. The constant question of, ‘do you want
              to be a round ‘a’ or swirly ‘a’ person?’”
              <br /><br />
              <span class="leahM">“I don’t know.”</span>
              <br /><br />
              “It’s okay to change all the time. It depends on the day.”
              <br /><br />
              <span class="leahM"
                >“Yeah. I wish I had more of a round a than a mean looking a, I
                think.”</span
              >
              <br /><br />
              I put the noodles into a big bowl, and Leah poured in the broth.
              We mixed them together with big Figma spoons.
              <br /><br />
              “True. If you were a shape, what would you be, what colour, and
              why?”
              <br /><br />
              <span class="leahM"
                >“I think it would be a triangle. I would want to be a right
                angle triangle. I think a right angle triangle is really
                important to life, building, and society. I’d want to be helpful
                to society. For colour, I’ve always really loved red. I think
                it’s abrasive, aggressive, and powerful. I love that colour
                because it’s protective to me, to be perceived at first as
                aggressive or something. But then internally, when I was little
                I liked the colour yellow a lot. So ketchup and mustard. What
                would you be?”</span
              >
            </p>

            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%204.png?v=1663890215333"
              />
            </div>

            <p>
              “Perfect. Hmm... I have been really obsessed with Kiki and Bouba
              lately, and I’ve been feeling very Bouba.”
              <br /><br />
              <span class="leahM">“I totally feel that I’m Kiki.”</span>
              <br />
              “I feel that, good contrast. For colour, I’ll just put a dot of
              colour inside of it, because I forget how to do this.”
              <br /><br />
              <span class="leahM">“Oh, now it’s a flower!”</span>
              <br /><br />
              “Yeah! I think I’m chartreuse.”
              <br /><br />
              <span class="leahM"
                >“That’s like the colour of a really beautiful Moss.”</span
              >
            </p>

            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%205.png?v=1663890322243"
              />
            </div>

            <p>
              We decided to use our kiki and bouba as bowls. She held them as I
              served our hearty Figma noodle soup.
              <br /><br />
              “Yeah, and like algae. It’s like looking in a mirror... What do
              you think you might reincarnate into?”
              <br /><br />
              <span class="leahM"
                >“I hope and I think two different things. I hope I can
                reincarnate into something a little bit easier next life, like
                not a human. But maybe I’m so narrow-minded to even think that
                things beyond humans have it easier. Like, who knows that an ant
                doesn’t have it hard? They probably actually have a terrible
                life. But, something with less brain-power. Maybe something in
                the ocean that floats. Or that’s part of a larger mass of stuff
                where the group kind of does the work and you sort of just go
                with the movements. That sounds like a nice break between
                lifetimes. Like I said, I feel like we’re living through some
                really extreme times. Who knows what the future holds? 
                <br><br>
                It seems
                like the future is going to be way harder, so I hope that I get
                a break. I hope we all get a break. So that’s what I hope for.
                What I think I’ll be reincarnated as is so corny. I had read a
                post on Tumblr a long time ago as a teenager and it stuck with
                me. It was this very reblogged thing, some person’s write-up of
                talking to God, or some fable that I saw drifting in the wind on
                my Tumblr feed about how your soul is everybody. That in all of
                your past lives and future lives, you just cycle through every
                single person throughout all of time. That not only you are your
                neighbour or your best friend from first grade, but you’re also
                Hitler and Stalin. But you’re also… I’m trying to think of
                someone like, I’m really trying to think of a historical figure
                that was like, for sure super good.”</span
              >
              <br /><br />
              “Yeah, because you never know what they might’ve done.”
              <br /><br />
              <span class="leahM"
                >“Well, so that’s the whole thing though. As good as you think
                you personally are, part of you is also the worst person that
                has ever existed, and every lifetime you get you have a new
                chance to be a lifeforce. So if I’m reincarnated, I think I’ll
                probably just be this infinite set of possibilities that is
                everybody else.”</span
              >
              <br /><br />
              “That’s a nice thing to look forward to. What are some things you
              do to maintain your lust for life and creativity?”
              <br /><br />
              We navigated to our Figma Fridge to find something good to drink.
              <br /><br />
              <span class="leahM"
                >“When I was talking to the design club at UC Davis they asked,
                ‘How do you stay creative? How do you stay passionate? Blah
                blah.’And I told them that I try to do things that require my
                imagination to work, like reading fiction. As you read you
                envision who the characters are. Reading is something I haven’t
                done for awhile, because I’ve given my attention to Tik-Tok,
                Instagram, and Twitter. Even scrolling through Are.na, which is
                more like health food to me. I’ve given away a lot of my
                attention during COVID to digital stuff, and have stopped
                reading. But I think if I were to read again, things would be
                less hard. 
                <br><br>
                I also think that staying interested and learning can
                keep me creative, because if I have new interests, then there’s
                new reasons to have things to work through with whatever medium
                I’m into at the moment. Also, staying in awe of other people,
                which I think is part of learning, just being in awe of
                something or someone. As for my lust for life, I mean, I try to
                keep that going by having three meals a day. Getting excited
                about the little things in life, you know. 
                <br><br>
                Recently I discovered
                with my partner – we had bought candy for Halloween, and it was
                the grossest bag of candy because everything was sold out. It
                was just Tootsie Rolls and Tootsie Pops, and I was really pissed
                off about that bag of candy. I thought, ‘oh, there’s no Snickers
                in here. It’s not going to be good,’ but my partner and I
                discovered that the Tootsie Pops are incredibly delicious. When
                you do get to the Tootsie Roll in the middle, eating that with
                the remnants of the lollipop left… there’s nothing better than
                that. So that’s how I stay hopeful for tomorrow, is that there’s
                a lollipop that I’m going to allow myself to have in the evening
                tomorrow too. How do you keep your lust for life and for you to
                be flowing?”</span
              >
              <br /><br />
              We found some lust for life in our Figma fridge to drink, and used
              Leah’s right angle triangles as cups.
              <br /><br />
              “Well, I think the little rewards at the end of your task list of
              the day is really good. And you know, calling my grandparents.
              That, and just being in nature and pretending I’m not me
              sometimes. Just being like, we’re all just another breathing
              thing... just staring into the void and being like, ‘It’s good.
              We’re just here.’”
              <br /><br />
              <span class="leahM"
                >“Yeah. I need to do that. I’m actually going to do that. Before
                I fall asleep tonight, I’m going to not look at my book or phone
                and just like stare into the void of my eyelids and be like, ‘my
                bed is so comfortable!’”</span
              >
              <br /><br />
              “Nice. It might be a little scary, but it’ll be good.”
              <br /><br />
              We each took a taste and found that lust for life is quite
              refreshing.
              <br /><br />
              <span class="leahM"
                >“I have a question for you actually, are you the type of person
                that will go take a walk if it’s raining out?”</span
              >
              <br /><br />
              “Depends where I am. Because here at my parents’ place, like I do
              have to drive somewhere to get somewhere interesting, you know,
              cause I just live in the suburbs, so it’s like, I’ll stay in my
              room. But when I was on vacation, it was a rain or shine kind of
              vibe, especially when you’re on vacation, you’re like, I’ll do
              anything, you know? What about you?”
              <br /><br />
              <span class="leahM"
                >“I feel like I have to say yes, because it’s always raining in
                Portland. And we deal with that. But I also have to say no,
                because I don’t like to not be warm. Yet being outside, in
                nature, is much more beautiful when it’s raining lightly or even
                raining really hard. So it’s worth it to go see, it’s just
                uncomfortable to be a human and see that. Maybe I need a better
                rain jacket.”</span
              >
              <br /><br />
              “Yeah, dressing for the weather is underrated. I think I’ll
              probably go out for a walk next time it rains.”
              <br /><br />
              <span class="leahM">“Yeah. Try it out.”</span>
              <br /><br />
              “We were talking about social media earlier. What do you think of
              love in relation to communication over social media? If you had to
              put it on our diagram, where would it be?”
              <br /><br />
              <span class="leahM"
                >“I think I have my outer world. It does have to intersect with
                my heart sometimes, because I do choose to have social media.
                Ok, I have judgement over here, and then social media is like
                perpendicular to my Heart, and over there in the corner is work.
                I will say though, my Instagram close friend’s story and my
                Twitter are closer to my heart. I actually use it to talk to
                people that I would talk to if we were together in a room, but…
                I’m not in the room with them. So those are on the outer-line,
                they’re almost the same as my friends, but it’s not the
                same.”</span
              >
              <br /><br />
              “Yeah, there’s a buffer. I would say the same thing. Sometimes
              there are digital connections that can impact your real heart in a
              great way. If social media was an essence or a being, what would
              it be?”
              <br /><br />
              <span class="leahM"
                >“If it was its own icon or something? Here, let me find
                something that only Google images could give me. I feel like it
                would literally be this shape, and exact colouring. That’s what
                social media looks like in my brain. If it were personified,
                it’d be something that makes you look.”</span
              >
              <br /><br />
              “It’s a really fun little shape, but it’s also grabbing your
              attention in a very intrusive way.”
              <br /><br />
              <span class="leahM"
                >“And if we didn’t know it was an arrow, maybe we would think it
                was a knife, or a machete. Something really dangerous.”</span
              >
              <br /><br />
              “It’s looking like the Pisces sign.”
              <br /><br />
              <span class="leahM"
                >“It’s a little scary.. no offence to any Pisces.”</span
              >
              <br /><br />
              “I love Pisces honestly. But yeah, I think that’s very accurate.”
            </p>

            <div class="imgBox">
              <img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Group%202.png?v=1663890435694"
              />
            </div>

            <p>
              We scrolled back over to our table.
              <br /><br />
              “So, I’ve been thinking about being glamorous, and one of the
              adjectives I would use for the art you share is glamorous. So I
              wonder, what is your personal relationship with being glamorous?”
              <br /><br />
              We decorated the table glamorously with a double story g and a
              theme. Our chairs and table were the letters: b n d.
              <br /><br />
              <span class="leahM"
                >“I love to be glamorous. I love glamour. I love to dress up. I
                hope that my interest will just grow stronger over time as my
                body is more and more incapable of holding any type of
                mainstream glamour. I think it’s maybe more glamorous to be an
                older person and wear a lot of makeup than it is to be a younger
                person and do the same, but both are beautiful and very
                glamorous. 
                <br><br>
                But I do have to say, I think it’s cooler looking
                when it’s someone who’s a bit older looking. I think in my work,
                even in my workshops, there’s still some type of glamour going
                on, because I think of it as performance art. I’m still kind of
                centering myself a bit for the first part, and then I do try to
                take myself away.”</span
              >
              <br /><br />
              We cut our noodles up with cursor forks and arrow knives.
              <br /><br />
              <span class="leahM"
                >“But I mean, I am an only child, an artist, and a creative
                person. In my head, my own reflection has been at the forefront
                of a lot of my personal experiences. I’ve spent a lot of time
                alone, especially when I was little. So that egotism is
                interesting to me, and repulsive too. And I think like glamour
                is a little repulsive, because it’s about looking, but it’s not
                about looking for a long time usually. 
                <br><br>
                The longer you look at
                something glamorous, maybe the more weird it starts to look. And
                I love that. I think the grotesque part of glamour is really
                cool. I am trying to interest myself in minimalism right now,
                even though I’ve been really maximal in the past. I think
                maximalism performs better on social media. So I’ve leaned into
                that out of necessity in the beginning, but maybe now I can
                start to allow myself to do things that probably won’t perform
                very well on social media and be interested in that performance
                too—or lack of. I wonder how glamour will find its way into my
                middle of my interest in minimalism, or if my interest in
                glamour will go away for a little bit. In general I love it. 
                <br><br>
                I like it when people and things are over the top. I like
                decadence. When there’s decadence, some-one’s usually deprived
                or something. And I think that that dynamic is really
                interesting. It’s absolutely disgusting that there’s rich people
                and very impoverished people. But I think that that dynamic is
                pretty fascinating, even though it’s people, I think that evil
                stuff can be fascinating too. But I think some people can get
                really fixated on evil things, like World War II buffs. I’m
                kinda like, maybe get another hobby. If that’s your
                hobby.”</span
              >
              <br /><br />
              “Yeah. Focus more on glamour.”
              <br /><br />
              <span class="leahM"
                >“Like at least read tabloids too if you’re going to become that
                involved. Like, have some humour.”</span
              >
              <br /><br />
              We took our first bites of the meal and found it was missing
              something... but couldn’t place it. Nevertheless, it was
              delicious.
              <br /><br />
              <span class="leahM"
                >“I met someone on vacation, I was really inebriated while
                meeting them. I was at a resort with my mom—which is very
                glamorous—and everybody there was people who go to resorts. So
                they were very weird, interesting people to me. I’d never been
                to a resort before, it was my first time. So, I met this person
                and they asked me what I was reading. I said I wasn’t reading
                anything... and then they told me what they were reading. I
                think that’s what they wanted when they asked me the question.
                But they said that they always love to read stuff about World
                War II, or like historical fiction about that. I was like, ‘so
                like Nazis?’ and they’re like, ‘yeah, I really love to read
                stories about the Holocaust.’ and the fact that he was saying
                this to me as a stranger, like really disturbed me. Like, whoa,
                you kind of asked me a question to tell me that. Which is a
                little crazy. 
                <br><br>
                So, I really mean it when I say that if you are a
                World War II buff, get it together and focus on some other
                things too. Not to say that it’s bad to know about WWII—it’s
                good to know about it—but I have my opinions about leaning too
                far into that.”</span
              >
              <br /><br />
              “Oh yeah, that is deeply concerning. Thinking back, about
              minimalism, I feel like the more minimal glamour gets, the more
              it’s interpreted as elegance. So maybe it’ll come off more like
              that! I’m excited to find out.”
              <br /><br />
              <span class="leahM">“I hope!”</span>
              <br /><br />
              “What are some little things that warm your heart?”
              <br /><br />
              <span class="leahM"
                >“I’m really just exposing who I am, as not any type of high
                artist or anything, but just as a person… I think it’s so
                incredible, and it brings me to tears sometimes, when I’m
                watching a talent show, like America’s Got Talent, and some
                small child is able to do something only an adult usually does.
                Like, singing an opera or doing a difficult choreography
                perfectly. Or if they have this talent that seems so outside of
                their little tiny body—it moves me to fucking tears and I love
                it. Especially the opera stuff. I’ve gone down plenty of YouTube
                spirals where I’m no longer watching America’s Got Talent, and
                I’m watching like Italy’s Got Talent, because I can’t get enough
                of the opera singing children. It really warms my heart!”</span
              >
              <br /><br />
              I turned on some child opera music.
              <br /><br />
              <span class="leahM"
                >“I have also been really warmed by Winter/Fall stews. A great
                soup or stew is so delicious and satisfying. I love that, it’s
                my favourite type of meal.”</span
              >
              <br /><br />
              “Yeah, I couldn’t agree more with that too. I feel like everyone,
              at some point, needs to find their signature stew or soup. I hope
              one day I can have a signature soup.”
              <br /><br />
              <span class="leahM"
                >“I hope that for you too, and I think it’s coming.”</span
              >
              <br /><br />
              “Good, thank you. What is your favourite soup?”
              <br />
              <span class="leahM"
                >“Chilli. Some people think chilli is its own thing. I could see
                that, but to me it seems like a stew.”</span
              >
              <br /><br />
              “I think so too. Finally, if the things you love were a shape,
              what would they be, what colour, and why? So that conglomeration
              of the opera kids and chilli, what is that being?”
              <br />
              <span class="leahM"
                >“Oh yeah, let me look something up. There’s a lot on here to
                choose from. I had to take this one, but maybe it’s not just
                this one—it’s all of them. For one of them, I looked up
                clickbait arrow. And for the other, I looked up clickbait
                circle. I think it works better when you see it in use. Here’s
                something totally unrelated, where the clickbait circle is in
                use and is working with the arrow. I just love it when clickbait
                is circling something, and they make it really big and it
                creates this mystery for you in a little tile at the bottom of
                some news article. So I think that’s the shape of everything I
                love, it’s just something that I can’t believe I want to look
                at, but I love, love, love it, and can’t wait to click on
                it.”</span
              >
              <br /><br />
              “Cool, that’s very accurate. I love it. Well, I’ve truly enjoyed
              cooking with you today.”
            </p>

            <div class="imgBox">
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/image%2011.png?v=1663890502573"
              />
              <br />
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/image%2012%20edit.png?v=1663890504660"
              />
            </div>

            <p>
              Suddenly, we realized that what our noodles were missing was
              clickbait circle and arrow flakes! I got them from the Figma
              cabinet and we each mixed some in. Perfect!
              <br /><br />
              <span class="leahM"
                >“Yeah, it was really nice meeting and talking with you too. I
                enjoyed that. If there’s any loose ties, I just want to
                reiterate that I did use the word whore and I once again, am
                trying to use it outside of any negative cultural connotations
                it may hold, but I acknowledge those too. I believe it’s not a
                bad word. I think it’s a great, concise word for something. A
                second thing is I think I admitted that I have a cold heart, and
                I do want to say that there are moments in my life, that are
                like my shining memories that I have, where my little cold heart
                becomes so warm and overjoyed. So I promise that I’m not super
                evil or anything. And I mean, no offence to any of my friends
                for putting you on the third tier of my heart. I’m sorry, but
                that’s where you belong to me. It’s no big deal, it’s not an
                insult. It’s just me.”</span
              >
              <br /><br />
              “It’s probably where you belong to them as well, you know?”
              <br /><br />
              <span class="leahM"
                >“Yeah probably, and I’m fine with that. I like the third
                tier.”</span
              >
              <br /><br />
              “It’s a comfortable tier.”
              <br /><br />
              <span class="leahM">“Yeah, it’s really nice.”</span>
              <br /><br />
              We savoured our delicious Figma noodle soup, then cleaned up our
              mess. Afterwards, with warm hearts, we wished each other a good
              night and went back to our homepages.
            </p>

            <hr />

            <br />

            <a href="https://paypal.me/ti4na" target="_blank"> Tip Jar ☕ </a>
          </div>
        </div>
      </main>
      <br />
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "figmadinnerparty",
  components: {
    MainLayout,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Figma Dinner Party With Leah",
  },
};
</script>

<style scoped>
body {
  line-height: 2;
}

.HotelQ {
  color: yellowgreen;
  font-weight: bold;
}

.quote {
  font-size: 20pt;
  text-transform: uppercase;
  font-weight: bold;
  color: yellowgreen;
  border-top: 1px solid yellowgreen;
  border-bottom: 1px solid yellowgreen;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  line-height: 1.6;
  text-align: right;
}

.head {
  text-align: center;
  font-size: 20pt;
  line-height: 1.5;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}

.trevor {
  color: #593879;
}

a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  color: grey;
  text-decoration: none;
}

main {
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(255, 255, 255, 0.6);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 40px;
  border-radius: 45px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid yellowgreen;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 30px;

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

br {
  line-height: 2;
}

.tianaD {
  color: olivedrab;
}

.leahM {
  color: darkred;
}

mark.mollyA {
  background-color: Moccasin;
  color: black;
}

mark.molly {
  background-color: OliveDrab;
  color: black;
}

@media (max-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;

    height: 100%;
  }

  .quote {
    font-size: 18pt;
  }
}

@media only screen and (max-device-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
    height: 100%;
  }

  .quote {
    font-size: 18pt;
  }
}
</style>