<template>
  <body>
    <div style="background: linear-gradient(#fffaf0, #FFD580)">
      <h1>autumn 2021 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        Hello Moontides,<br />
        I hope these horoscopes find you at the right time. I
        invite you to listen with an open heart, allowing the messages that are
        meant for YOU specifically to bring nourishment and reflection.<br />
        For each of the twelve zodiac signs, I have pulled a card that offers goddess
        guidance for the season of fall we find ourselves in in the western
        hemisphere. Since I recorded these horoscopes on the New Moon in Libra,
        I have also pulled a tarot card that gives insight on what may be
        surfacing for your sign during this upcoming lunar cycle.<br />
        I encourage you to listen to your sun, moon, and rising sign. <a href="https://www.astrology.com.tr/birth-chart.asp" target="_blank"
          >Find your signs.</a
        ><br />
        For more of my poetic meditations &amp; musings, connect with me on instagram @moontidepoet. 
        <br />
        Much love,<br />
        <a
          href="https://www.instagram.com/moontidepoet/"
          targer="_blank"
          >MoontidePoet</a
        >
        <br /><br />
      </p>

      <p>
        Aries 4:55 | Taurus 11:15 | Gemini 18:30 | Cancer 24:35 | Leo 30:20 | Virgo 35:10 | Libra 40:10 | Scorpio 47:15 | Sagittarius 51:30 | Capricorn 56:25 | Aquarius 1:00:45 | Pisces 1:06:40 | Outro 1:11:00 
        <br /><br />
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/CXSg_0RM2CE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       
      </p>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        <router-link to="/">back to volvoxvault.com</router-link>
      </p>

    </div>

    <br /><br />

    <!-- SUMMER -->

    <div style="background: linear-gradient(#ebf5fb, #aed6f1)">
      <h1>summer 2021 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        <i>We have a new horoscopes writer! <br />Meet Julie...</i> <br />
        <br />
        Hello Moontides,<br />
        <br />
        Welcome to my first set of Oracle based Horoscopes for Volvox Vault! I
        am so excited to share these with you. These horoscopes are written
        based on the 12 astrological signs, and the cards that I pulled in a
        general reading for each. What have you experienced externally and
        internally this Summer? How can you work with those energies? For this
        season, each element of the zodiac (Fire, Water, Earth, and Air)
        receives a powerful Goddess that offers guidance and wisdom for major
        energies and topics of focus. Each zodiac sign within each of the four
        elements receives its own interpretation of that wisdom, and how to
        apply it to your own journey of growth. These horoscopes are a helpful
        tool for reflection on the past few months, beginning in June until the
        end of August. Feel free to read up on your Sun, Moon, and Rising sign.
        <br /><br />
        For inquiries and personal oracle readings please message @moontidepoet
        on Instagram. To listen to my podcast, subscribe to Poetic Meditations
        by MoontidePoet on Apple Podcasts. I hope to hear from you soon!
        <br /><br />
        Much love,<br />
        <a
          href="https://www.moontidepoet.com/?ltclid=0ee64697-8f5c-4bd9-9155-aaf3cc6e77ff"
          targer="_blank"
          >MoontidePoet</a
        >
      </p>
      <br />

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        find your signs
        <a href="https://www.astrology.com.tr/birth-chart.asp" target="_blank"
          >here</a
        ><br />
        <router-link to="/">back to volvoxvault.com</router-link>
      </p>

      <hr style="border: none; border-bottom: 1px dashed" />
      <br />
      <h2>Fire Signs: Goddess Guidance</h2>
      <p>
        <i>Baba Yaga // Wild Woman</i><br /><br />
        The shrill call of Baba Yaga pierces our souls with a wild longing.
        Known best for her mode of transportation in a mortar and pestle, this
        Russian witch archetype flies free through the night, daring us to join
        her...This summer you may feel called to run wilder and with more
        freedom than ever before. Perhaps you have been laying under your soft
        security blanket for most of the winter and spring seasons...but Baba
        Yaga is here to let you know with relentless passion that the time for
        safety and comfort has passed. Embrace your inner wild woman, and allow
        yourself to answer her call with a howl, a yodel, a dance, even a cry.
        We owe it to ourselves to feel our feelings, to be responsible for our
        work and the things that matter to us...but we cannot forget our wild
        nature. Think of yourself as an animal, because after all, that’s what
        you are...isn’t it?
      </p>
      <br />

      <p>
        <strong>LEO</strong> ~ Little lion cub, this is the season for you to
        embrace your bliss. What makes you shine brighter than the rest is your
        ability to channel the energy of ecstasy and pure enjoyment. Make time
        for healthy and holistic “fun”. Participate in opportunities that
        activate your flow state. Everything under the sun is a playground for
        you to run, dance, and sing in. This quality of following your bliss is
        attractive beyond measure. Love yourself, then love the world.
      </p>
      <br />

      <p>
        <strong>SAGITTARIUS</strong> ~ Hello fire power! Dearest Sagittarius, it
        is time to be inspired by everything. The good, the bad, and yes, even
        the ugly can offer up inspiration to our deepest levels of
        consciousness. If you are going through a dry spell in terms of
        creativity, take the pressure OFF. Remind yourself that inspiration does
        not insist upon action. The potency of an idea is all it takes. If it
        isn’t jiving the way you’d like to do now, give it a rest and return
        back to the root of your muse. Recognize how the simple things inspire
        you, and how you are an inspiration to the world around you on a daily
        basis.
      </p>
      <br />

      <p>
        <strong>ARIES</strong> ~ Aries, both precious and precocious
        shapeshifters...take heart in being everything all at once. Do not box
        yourself in with labels and meaningless identifications. This season,
        leave your nametag at the door and allow your vibration to change and
        shift with your own unique cycle. At times it may feel overwhelming and
        confusing when you are constantly in flux. Remember that no matter what
        you are rooted in the present moment, and all else will fall into place.
      </p>
      <br />

      <hr style="border: none; border-bottom: 1px dashed" />
      <br />

      <h2>Water Signs: Goddess Guidance</h2>

      <p>
        <i>Corn Woman: Nourishment</i><br /><br />
        The Goddess of the harvest reminds us to nourish ourselves as we would
        nourish our crops so that both may flourish, growing strong enough to
        offer back that nourishment to the greater good. This is a continuous
        cycle that depends upon the principle of self care. Ah yes, that all too
        often capitalistic idea of “self care” makes us overspend and
        underestimate what it really means to take care of our body, mind, and
        spirit. Be smart enough to stay far away from this shallow
        interpretation of nourishment. This season, the focus of nourishment
        calls forward a holistic approach. What food do you fuel your body with?
        What words do you water your soul with? What kind of energies do you
        allow into your home? Nourishing ourselves involves tender care AND
        discipline. Finding the happy medium between the two is of utmost
        importance.
      </p>
      <br />

      <p>
        <strong>CANCER</strong> ~ You are outgrowing your shell, little lunar
        one. In the Western hemisphere, Summer begins with your season. Have you
        ever noticed how things seem to change as your birthday draws near? This
        is because you are a cyclical crab, entering another round of death and
        rebirth. Release what no longer serves you. Release what you’ve
        outgrown. If your shell is getting too heavy, it is time to squiggle out
        and be vulnerable for a time before you slowly begin again— finding your
        new comforts. Listen to your intuition. That gut feeling that you
        describe as “claustrophobia” is here to announce this wisdom: change is
        good. It is part of who you are. Find the beauty in the beginning again.
      </p>
      <br />

      <p>
        <strong>SCORPIO</strong> ~ This season is all about compassion and
        vulnerability. When you feel that something isn’t quite right you have
        the tendency to tense up and draw those pincers up— ready to defend and
        protect your heart. There is nothing wrong with that Scorpio, but it
        will only get you so far. Somethings in life are meant to permeate our
        slick scorpion armour. Compassion is one of those things. Have you been
        too hard on yourself or those around you? Have you been feeling like no
        one is considering your feelings? Have you been hiding your true
        feelings in order to avoid being seen fully? The gaze of compassion
        seeks all, but settles only on those who are humble enough to be looked
        at.
      </p>
      <br />

      <p>
        <strong>PISCES</strong> ~ Anger is not you primary emotion, little fish,
        but as you swim through more turbulent waters it is important that you
        don’t cut yourself short of the experience of frustration. Often, as
        sensitive individuals, we are taught that anger is only destructive and
        creates conflict. This may be true for some, but with your watery wisdom
        and aloof dreamer mind, you can coach yourself through your “not so
        nice” emotions so that you experience them fully and express them for
        the highest good of all. Don’t swim away from a bad feeling. Approach it
        with an open mind and a loving heart, and watch how the healing waters
        transform it into a new treasure.
      </p>
      <br />

      <hr style="border: none; border-bottom: 1px dashed" />
      <br />

      <h2>Earth Signs: Goddess Guidance</h2>
      <p>
        <i>Sulis: Illness / Wellness </i><br /><br />
        The Goddess Sulis offers deep knowledge of the mystery of health. So
        often we are caught up in our afflictions, both physical and spiritual,
        that we miss an important truth: the illness is the medicine. We are not
        meant to “overcome” our afflictions in the sense that we win, but work
        with them to learn the potency of their deeper meaning in our lives.
        What knowledge do we gain if we never take the time to hear the villains
        backstory? Sulis also calls us to recognize what state of living we are
        in. When we are in illness we are being called to direct our energy and
        efforts internally, to ourselves. When we are in wellness we are being
        called to direct that energy outwards, to others and to the world around
        us.
      </p>
      <br />

      <p>
        <strong>TAURUS</strong> ~ Healing is not linear. It is elemental. Look
        for the reflection of your healing journey in the elements around you.
        Mother nature experiences trauma, just as you have. When it comes to
        reconciling with the Earth, we must treat it as we would our own holy
        bodies. Look for opportunities that allow you to dig up past painful
        experiences and use them as fertilizer for new seeds to be sown. Think
        of your healing process as a compost process. After all, the Taurean
        timeline knows that patience is of the essence. What once was the jagged
        edge of an egg shell can slowly but surely turn into a new leaf.
      </p>
      <br />

      <p>
        <strong>CAPRICORN</strong> ~ Empowerment is what comes after we realize
        our challenges are merely opportunities for growth. How has your
        personal power been developing over this season? Have you been feeling
        sturdy in your boots, or shaky at the knees? The most empowering
        experiences are born when we allow ourselves to be vulnerable. This
        season is a time to remain open to the concept of being naked without
        being afraid. Know that when you feel weak and unstable, it is only an
        invitation to realize that you are up for the challenge. On the other
        side of your fear is empowerment. If you are still in the midst of
        feeling at a loss, allow yourself to wobble.
      </p>
      <br />

      <p>
        <strong>VIRGO</strong> ~ Dearest earthly yet ethereal Virgo, despite
        your desire to be the keeper of order, you cannot know all. Make room
        for mystery and life will surprise you with more joy, more excitement,
        and more appreciation for the unknown. When it comes to control, you’d
        rather be IN it, but what does it really mean to be IN control? Control
        is not a real place. Accepting that we don’t know and cannot know
        everything provides us with the chance to let loose and not take things
        so seriously. The allure of mother mystery is such that we cannot know
        her. We cannot see her beauty, but we sense it. Allow this feeling to
        tug at your heart strings and open your mind to the endless
        possibilities that await.
      </p>
      <br />

      <hr style="border: none; border-bottom: 1px dashed" />
      <br />

      <h2>Air Signs: Goddess Guidance</h2>
      <p>
        <i>Kali: Fear</i><br /><br />
        When we look at our fears in the face, we see them for what they truly
        are. Kali offers us a new way of coping with what keeps us up at night.
        Much like a horror movie that only shows glimpses of the sinister
        monster lurking in the shadows, we fear what we refuse to look at. Trust
        that whatever your deepest terror may be is no match for the
        ever-triumphant force of love. During this season ask yourself, “what am
        I afraid of?” Rather than letting these ambiguous thoughts toil and
        bubble in your mind, write them down! Get them out on paper and look at
        them in the face. If there has been a situation or feeling you have been
        avoiding for some time, it is more important than ever to deal with it.
        The universe will continually throw situations of “fear” at you until
        you step up to face them with love. Treat each fear like a wanting
        child. Give it a face and a name. Ask it what its true motivations are.
        More often than not, all it’s doing is waiting to speak once it’s been
        spoken to. Only then will you be able to move forward.
      </p>
      <br />

      <p>
        <strong>AQUARIUS</strong> ~ You know the weight of certain energy and
        the lightness of others. Known as the “water bearer,” wise Aquarius, you
        are also a carrier of energetic ripples. Notice how these may affect you
        on a day to day basis. What downloads have you been receiving from
        beyond the veil? What has been swirling in the cosmos that is now ready
        to be bestowed upon the Earth? As an air sign it may feel like most
        things happen in your head, but don’t forget that you have a body too!
        Take note this season on how energy moves through you and your body.
        Despite what pop-astrology says, you DO have feelings, and you
        experience things deeply, no matter how objective you can be. Remember
        the importance of protecting your own energy field and set boundaries as
        needed.
      </p>
      <br />

      <p>
        <strong>LIBRA</strong> ~ Oh soft, soulful Libra. What has been weighing
        you down so much that your scale is tipping? This season, it is
        important to honour your grief. Whatever pain and sorrow you have
        experienced over the previous season, this summer is a time where you
        may be feeling it all. Take up journalling or another mode of
        recollection so that you can map out your experience of grief. What
        stage are you in? Do not rush to the finish line. What lies in between
        denial and acceptance is a whole lot of learning.
      </p>
      <br />

      <p>
        <strong>GEMINI</strong> ~ Love is in abundance for you this season.
        Whether or not you are in a romantic partnership, look for ways in which
        you experience union and unity. A oneness consciousness allows us to be
        in love regardless of our relationship status. Once you see the world
        around you as a reflection of your inner world, you will discover
        synchronicities that eternally hint at the interconnectedness of all
        things. Love is all. Love is all there is. If you have been avoiding the
        need for tenderness from friends, family, or a partner, it may be time
        to go out on a limb and express how you’re feeling... most likely they
        are feeling the same way.
      </p>
      <br />

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        We strive to make our horoscopes accessible to all visitors!<br />
        Please email hello@volvoxvault.com with any feedback.
      </p>
    </div>

    <br /><br />

    <div>
      <h1>spring 2021 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        horoscopes by em biggs -
        <a href="https://www.paypal.com/paypalme/emandthemoon" target="_blank"
          >tip jar</a
        ><br />
        contact em for birth chart and tarot readings<br />themoonthestarsandem@gmail.com
      </p>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        please apply to your sun / moon / rising <br />
        written in affirmation style and meant to serve you in whatever way you
        need.<br />
        find your signs
        <a href="https://www.astrology.com.tr/birth-chart.asp" target="_blank"
          >here</a
        ><br />
        <router-link to="/">back to volvoxvault.com</router-link>
      </p>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        ♈︎<br /><strong>A r i e s</strong> ~ Spring, that feeling in the air
        everyone talks about now, is what runs through my veins every day as an
        Aries. This is my season because for a moment, the world is on my page,
        eager to read and hear what I have to say. Rather than hoarding my
        magic, I share it. The world needs more examples and inspiration for
        possibilities of ways to live. My sovereignty is playful yet reliable
        and my youthful spirit is not taught but embodied. I do not leave my
        pleasure to be named by others. I find deep safety when I acknowledge
        which newness I would like to experience. Grounded in love and filled
        with vitality, I give myself permission to get in touch with my
        sensational nature. This spring I set my <b><i>flaunting</i></b> in
        motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/aries_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♉︎<br /><strong>T a u r u s</strong> ~ Any insecurity I have
        experienced in the past or maybe am sifting through in the present day,
        I thank it for protecting me at those times and let go of it from
        obstructing my future. I welcome in a lucid understanding of my inner
        framework, not to bog me down but to actually activate a working
        relationship with my subtle energy. I am imperfect and it’s wonderful,
        it’s a pleasure to be different. Over the past moons, I know not to
        waste my time or dissipate my energy. I’ve gotten the pleasure to be
        with myself every night, through SO MUCH. Through the accumulated wisdom
        of these experiences and acceptance of mine own self. I know how to wish
        for things without doubt. This spring I set my
        <b><i>inner mystic</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/taurus_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♊︎<br /><strong>G e m i n i</strong> ~ I trust that the friction I have
        been feeling will soon forge a new wave of thought and outlook on life
        for me. Perhaps I am allowing more and more space for my ability to
        change. Without this very natural chain of events (change, that is), we
        would all be mere shells of our 12-year-old selves. Which was exactly
        perfect for me at that time, but now I accept that things are different
        so that my younger self can thank me. So that my younger self can really
        root for me. Change does not come from standing still, change comes from
        my innate need to try new things and seeing where that takes me. I do
        not give up on myself because I am worth seeing this through. This
        spring I set my <b><i>persistence</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/gemini_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♋︎<br /><strong>C a n c e r</strong> ~ “To give and receive with love,
        I set my feelings free. I am open” I repeat this to myself every morning
        and every night. I’ve been through it the last few months and can now
        clearly see the narratives I have been contributing to. This season I
        jump into self-doubt and uncertainty and blow it up from the inside out.
        I release my grip and meet each tender ache with love and understanding.
        If I want to know where I am going and how to get there, I have to stop
        looking to others to tell me how to feel about a situation. In other
        words, I have to start getting to know myself. I have to stop expecting
        myself to be perfect for everyone else. So, “to give and receive with
        love, I set my feelings free. I am open…” This spring I set my
        <b><i>liberty</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/cancer_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♌︎<br /><strong>L e o</strong> ~ What makes me question my ability to
        receive what is being offered? When I choose to be introspective in
        contrast to perpetuating fantasy I am able to surrender into impartial
        love. I resist over-intellectualizing my experience and allow my body to
        naturally purge. If I do have a mental urge, I will simply ask myself
        “what is at the root of any thoughts or obstruction?” That is all, my
        body will do the rest, in this I trust. There is an importance now to
        have a non-interpretive practice about how I feel. In other words, just
        let myself be, knowing that I am oh so held. I am awakening and in awe
        of the way my body moves, breathes, naturally cleanses, loves and
        creates. This spring I set my <b><i>acute awareness</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/leo_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♍︎<br /><strong>V i r g o</strong> ~ I can take up space without
        feeling as though I am taking away from others. What masks do I put on
        for the world around me to make them feel comfortable? Have I considered
        the possibility that the right people, places, and opportunities will be
        magnetized to me regardless of what part of me is exposed? Maybe my love
        language isn’t so hard to speak. Maybe all I’ve had to do is embody this
        language so there is less translating to achieve. Perhaps I have just
        been concentrating too hard on what isn’t for me rather than what is.
        Today I open up a new commitment of choosing to participate according to
        my gifts. This spring I set my <b><i>radical candor</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/virgo_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♎︎<br /><strong>L i b r a</strong> ~ Honest love works on every system
        of the body. If I want to look for inspiration, I don’t have to look
        further than the strength within me – I have gotten this far already.
        Someone once said that pain is a moment between two pleasure points and
        pleasure is a moment between two points of pain. There is no
        super-secret antidote that will protect against feeling pain ever again.
        Though, I can remind myself that life is working for me, not to
        me/against me. I have a say in this, I always have and maybe it has just
        taken a second for me to remember. I see my emotions as messengers and I
        am willing to see any mistakes as lessons. As I tend to my sensitive
        heart, forgiveness frees me. This spring I set my
        <b><i>uplifting affirmations</i></b> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/libra_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♏︎<br /><strong>S c o r p i o</strong> ~ What I choose to focus on
        influences the framework of the world around me. I have reached the
        finality of a certain way of thinking. It’s made me unravel to a point
        where I really need some rest. I need to turn inward, in whatever
        capacity I can to gather new ideas and be receptive to what my body and
        journey is telling me. During this time, if I find myself replaying
        memories, it is the love instead of the hurt of these moments that I
        will attune to. I don’t need to rush through this process, the Universe
        has a funny way of bending around me when I need it the most. I give my
        attention to one direction and will thank myself in the future for doing
        so. This spring I set my <i><b>reliable self-care routines</b></i> in
        motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/scorpio_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♐︎<br /><strong>S a g i t t a r i u s︎</strong> ~ There’s a difference
        between what is settled and what lays dormant. Settled meaning a road
        that I have been down before many, many times, certain as to where it
        goes. Dormant meaning something is yet to be activated, sitting in the
        wings, awaiting a catalyst. If I could fuel one new approach, venture,
        dream… What would it be? I was born with expansive Jupiter energy in my
        cosmic blood for goodness sake. I have to fully commit though. It should
        be a goal of mine to make my work sustainable. I’m learning to encourage
        myself to keep going even when it’s easier to go back to how I’ve always
        done things. I am enough and my energy is limitless. This spring I set
        my <i><b>timely activation</b></i> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/sagittarius_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♑︎<br /><strong>C a p r i c o r n</strong> ~ I’ve felt like I’ve been
        grabbing at loose straws lately. I remind myself that I am worthy, and
        that I do not have to prove myself constantly to the world around me. I
        can take breaks from picking sides, having it together for others all
        the time; and honestly, I’ve just had enough. I will learn more of my
        values by allowing myself to just be. The more I open my heart to that
        feeling the more I am free. It seems simple in theory but I challenge
        myself to put it into practice. I will have more to share in due time
        but for now I trust that I am being called to take some space to
        reassess, reframe, and even grieve if I need to. This spring I set my
        <i><b>own nourishment</b></i> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/capricorn_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♒︎<br /><strong>A q u a r i u s</strong> ~ I consider practices that I
        know I can let loose in, like how good humour and good friends have
        always made me feel at ease. Events in my recent past showed me about
        life’s woes, and while teary-eyed/intense, it is important for me to
        remember that I am not alone. If anything, I can bet on the parts of
        myself that are opening up through this because I am no longer
        sheltered. I am safe of course, but I am no longer avoiding unpleasant
        realities for the sake of simply not wanting to be too vulnerable
        emotionally. My first steps after these shifts have taken place may feel
        like I am on a teeter totter… But! I remind myself that I can get off to
        take a break, or go slower as I eventually come to a balance. This
        spring I set my <i><b>unravelment</b></i> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/aquarius_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♓︎<br /><strong>P i s c e s</strong> ~ My mind is beautiful; my body is
        beautiful and I love to have FUN!!! In the past, I may have been taken
        advantage of because for my love of others and while grueling, I have
        learnt from each heartache. I have gained a perspective of my
        non-negotiables in life and relationships. The thing is, people love
        boundaries. They tell us how we want to be seen and treated by others so
        that the relationship can not only exist, but thrive. I realize the
        containers I would like to create for all kinds of relationships to take
        place in. May I be surprised by the transformation of my connections and
        confidence as I dismantle existing structures that are no longer
        nourishing. I am supported, loved, and respected. This spring I set my
        <i><b>new-found philosophy</b></i> in motion.
      </p>
      <audio
        src="@/assets/horoscopes/spring2021/pisces_spring.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        We strive to make our horoscopes accessible to all readers/listeners!
        Please email hello@volvoxvault.com with any feedback.
      </p>
    </div>

    <br /><br />

    <div>
      <h1>winter 2020 / 2021 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        <em
          >NEW - written in affirmation style and meant to serve you in whatever
          way you need.</em
        >
      </p>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        ♈︎<br /><strong>A r i e s</strong> ~ I will give a voice to what has
        been waiting in the wings to be seen and heard. There are parts of me
        that I may not understand yet and this excites me. Like any good story
        there is character development. May I trust that parts of my life can be
        curated specially for my own growth. The roots of any conflict dissipate
        as I allow for other perspectives and contemplations to enhance my
        story. Certain emotions may come up that I don’t know what to do with.
        This is okay, because I am curious to explore new territories. Here’s to
        me, taking the key and unlocking a door never before opened.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/aries_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♉︎<br /><strong>T a u r u s</strong> ~ I let go of the connections that
        make me feel strained and squished. Relationships that have some growing
        room are what I need to see myself clearly. They help me to witness
        where I have put up blockades around love in an effort to try and
        control it. I’ve cultivated something deep with myself and when I join
        forces with what and who I choose, we are in a good position to spread
        joy beyond the collaboration. The world needs this right now and maybe I
        do too. I don’t wish to be perfect at this time, nor do I wish for
        anyone else to be. I’m learning and I ask for the exchanges that
        encourage compassion from the heart and putting my own creative spin on
        things.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/taurus_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♊︎<br /><strong>G e m i n i</strong> ~ This Sagittarius Season I will
        reconnect with the ‘why’ in what I do. The last while has been fast
        paced, disorienting at times and I can empathize with why I want to skip
        to the next part already. The part where my eyes don’t feel glazed
        anymore as my body adjusts to change. But there is so much I learn
        through self-effort and the experience of seeing myself through this.
        There has been an exceptional amount of things I’ve had to juggle, but I
        owe it to myself to meet myself where I am at. This season may I realize
        that my thoughts are meant to be bonds, not binds. I see the clouds
        parting for me and the Sun on my skin re-instills a sense of hope.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/gemini_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♋︎<br /><strong>C a n c e r</strong> ~ I have everything I need to
        finish what I started. In fact, I witness and pay respect to how I
        naturally find a way to build with the materials I have. I ground down
        into being process oriented right now rather than results oriented. This
        allows for my creativity to come out and play while releasing the
        tension in my body that I need to let go of. There isn’t anything wrong
        with wanting to be seen. The world wants to get to know me too. What I
        am doing is enough, I understand that confidence in my output helps me
        see the closure available in each moment. May I be present in my actions
        and efforts and reassess my boundaries about how much I take on.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/cancer_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♌︎<br /><strong>L e o</strong> ~ This season I will recognize the
        challenges of my past that have enriched and strengthened me. For there
        are certain experiences that have added fuel to my fire and resources to
        my toolkit. I will have markers for myself so that I can understand when
        I’m feeling burnt out. When I need a break. It’s okay to wish for
        emotional health and stability. I work steadily, thoughtfully and
        compassionately. Especially towards myself. I will ask for what I need
        and deserve. There are so many subtleties experienced at once. This
        momentum is all the permission I need to explore the expression of who I
        am in all of my value, lovability and resourcefulness.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/leo_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♍︎<br /><strong>V i r g o</strong> ~ May I settle into an understanding
        that whatever move I make will inevitably bring me closer to myself.
        Sometimes things turn upside down so that I can explore my current
        limitations and perspectives. May I remind myself that I will move out
        of treacherous waves and into more serene waters eventually. I trust
        that there is time to work out any ‘kinks’ and I honour that nothing is
        ever perfect. What I perhaps underestimate is this process will be an
        inspiration for myself and others in the future. What matters right now
        is me doing my inner world justice, withdrawing if I need. This time
        hatches the egg for me to experience a different depth than what I am
        used to.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/virgo_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♎︎<br /><strong>L i b r a</strong> ~ May I give more awareness to what
        double takes my attention. The trial and error periods of the last
        months have helped me invite in ways to work smarter and direct my
        energy in healthy currents. I will nurture this momentum by consistently
        checking in. Posing the question “what is it that I can do right now”
        eases any emotional wounds around what it means to try. I will honour
        the moments to take a step back to see if I can fill all of my cups from
        one overflowing well. Reassessment doesn’t mean failure. Reassessment
        brings movement to how I see things and allows for the opportunity to
        see the next step before I make my next move.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/libra_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♏︎<br /><strong>S c o r p i o</strong> ~ I invite in reality checks
        around where my energy/thoughts have been going. My body will tell me
        when too much focus has me feeling dry, parched and unsure. May I not be
        frightened by this, instead welcome in an opportunity to adapt. It’s a
        vulnerable position to think for myself and still desire relating to
        others. But I want to. I’m beginning to see the certain magic I bring to
        my relationships. What makes them strong and enduring. Connection is
        meant to uplift my spirits. May I invite in the people and places that
        feel like home. When I feel comfortable, I can mindfully and intricately
        create the world around me.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/scorpio_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♐︎<br /><strong>S a g i t t a r i u s︎</strong> ~ May I be motivated to
        do and go forth, affirming the ideas I believe in. This isn’t the
        beginning for me, more so a reflection of where I have been and a
        recognition of all the change I have gone through. I realize that the
        things I hope for at this time require a certain diligence from me. May
        I soften into what feels like it is worth my time and energy. With so
        much shifting around me I commit to actions that remind me of the
        strongest parts of myself. It’s my season and may I adorn myself in any
        way I see fit. Whether that be through outfits, scents, giggles, etc. I
        am enchanting, mystic, and metamorphosing.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/sag_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♑︎<br /><strong>C a p r i c o r n</strong> ~ I take full responsibility
        for my choices and ask for help in fulfilling my commitments when I need
        to. I notice when things feel heavy but this doesn’t mean they have to
        weigh me down. Perhaps the pressure crystalizes me into the diamond that
        I am becoming. May I laugh a little lighter and focus on what I can
        control. Every so often we get to enjoy moments of feeling complete and
        that is so, so, sweet. It may not be fully in my awareness of what has
        shifted, but something has and it is altering my reality for the better.
        This season I will communicate how I see the world. My visions are
        valued.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/cap_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♒︎<br /><strong>A q u a r i u s</strong> ~ It’s my time to commit to
        the people, places, and things that feel like a breath of fresh air. I
        trust my boundaries and those expressed to me as the container for
        expression between myself and everything else. Reconnection helps to
        warm up any icy feelings, so let’s get that fire burning. I settle into
        life’s pleasures in whatever way feels good to me now. When I leave
        doubt at the door, I can live from an honest place. No strings attached,
        I arrive with presence and this allows me to give freely and from the
        heart. In turn, this act heals me too. This season is meant to serve as
        a burst of new life, putting that spark back.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/aquarius_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♓︎<br /><strong>P i s c e s</strong> ~ It’s up to me to see that the
        compassion I give myself is closely related to the compassion I give to
        others. It is in relationships that we heal the most, so may my
        connection with those I care for revive the parts of me that I may have
        forgotten. This might bring up a lot of what I have repressed, but I
        trust that I will allow for the space to accept where I am at. Life may
        be strange and bizarre as the world shifts, and I let go from this
        obscure tug of war. It is in this that I understand that I am not meant
        to suffer. I am Pisces and I will do my best to openly enjoy life’s
        pleasure’s even if I have experienced restrictions in the past/present.
      </p>
      <audio
        src="@/assets/horoscopes/winter2020/pisces_sag2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        We strive to make our horoscopes accessible to all readers/listeners!
        Please email volvoxvault@gmail.com with any feedback.
      </p>
    </div>

    <br /><br />

    <div>
      <h1>fall 2020 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        ♈︎<br /><strong>A r i e s</strong> ~ Sometimes after an issue we are
        left with imagining what else is possible. All is not lost though. An
        option whenever you feel good about it is to give yourself space for
        creative visualization. What scene do you find yourself in, do you
        look/feel the same? Is there a possibility of integrating these details
        into your life? You may feel as though you are actively and constantly
        juggling two things this month. Check yourself out of any drama or
        gossiping in October. However, and only you can answer this, if
        something is truly getting to you, you don’t have to pretend that it
        isn’t. What I think is so special about your energy is how you always
        offer to teach and counsel others with the tools you have. You are the
        authentic fire Aries, not the person dancing around it.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/ariesOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♉︎<br /><strong>T a u r u s</strong> ~ This month try to notice when
        you feel busy without purpose. By purpose, I don’t mean a seismic
        definition of what your life means. I simply mean that with every task,
        do it with the understanding that it matters. Ask yourself… At the core
        of it, what does matter to you? Be aware that what is profitable is not
        always ethical so watch out for when your benefit overrides those simple
        values too. Honor wherever you are in your cycle. This phase of your
        life is coming directly out of past experience. Take time to be with
        unprocessed feelings and unconscious beliefs. It’s okay to take moments
        to clear your head and hold off on conclusions. Illusions are
        disappearing and what is needed from you, for you, is gentle generosity
        as you shift.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/taurusOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♊︎<br /><strong>G e m i n i</strong> ~ When we tune into Gemini energy,
        a lot of astrologers will talk about the duality of this sign. Yes,
        there’s no doubt that you can juggle a ton, harness the ability to see
        both sides, and embrace oppositions. That is not in question. But, how
        can we aim that energy inwards? How can we get from the point of seeing
        this contrast of events outside of us, to another where we can see and
        remedy what has been going on under the surface? Try to start a dialogue
        with yourself in October if you haven’t already. With this leverage, you
        may tap into your 3 realities of the past, present, and future. If your
        friend was in the same position, how would you speak to them? Do you
        trust them? When you take off a mask it’s easier to see where you are
        stepping.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/geminiOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♋︎<br /><strong>C a n c e r</strong> ~ I see you. I see how you have
        been disciplining your energy and this has allowed you to help yourself
        and others. At this point, you can’t stand to see your energy stagnant.
        What you want, you want right NOW! I hope that you take this month to
        get excited about these sensations, emotions, and basic drives.
        Sometimes excitement builds up enough that we forget we still have a
        choice, commitments, and our very own will to conduct this energy. SO,
        if and when you are feeling the heat of a moment, know that you are
        allowed to take a step back and take a deep breath. The opportunities
        won’t slip away. What this means is YOU, giving yourself the space to
        envision the best possible expression of a scenario… Then when you’re
        ready, proceed with caution and a listening ear to make your dreams come
        true.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/cancerOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♌︎<br /><strong>L e o</strong> ~ Sparkles. Your inner light shines
        outwards. A recent harsh truth or heartache is being remedied. The
        weaving of events and interactions you take part in this month shows you
        how you would like to be cared for. Respect the receptivity it takes to
        draw others into a quiet tenderness. Notice if certain love or pleasure
        feels addicting or oppresses the Spirit. It isn’t love if you lose sight
        of yourself in the process of fulfilling someone else’s expectations of
        you. So, communicate, recognize deep feelings, and sense your gifts of
        self to another. As well, there was a powerful visualization of water
        that came through in your reading. Bathe babe. Water doesn’t have to be
        the medium of your immersion either. Forest bathe, music bathe, bathe in
        flowers… or all of the above. I trust your creativity in cleansing and
        revitalizing your energy when you feel the need to.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/leoOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♍︎<br /><strong>V i r g o</strong> ~ Listen and analyze from all angles
        and leave room for YOUR freedom before you take on more responsibility.
        How might we slow down observance so that we can collect the details and
        messages that we need? Connect with like-minded Spirits if you can. This
        will be great for your nervous system. You are appreciating your own
        patterns and bridging the gap between this day and the next. If
        something this month doesn’t go as you hoped, don’t believe for a second
        that this means you have to settle. No, no, no! Remedy any hurt if it
        comes up and try another approach when you feel ready. Have your pen
        stay hovered over that signature line as long as you need to. An honest
        commitment is the best kind of bond we can make.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/virgoOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♎︎<br /><strong>L i b r a</strong> ~ Your horoscope this month felt
        like writing a sweet affirmation aw! The pieces and lessons that came in
        for you this September were significant. Last month you faced a ton of
        tasks to allow further weaving of your web. Please do not doubt where
        you were, where you are, and where you are going. This month you may
        feel your mind cut to distant snapshots to all of the future projects or
        successions with what you have been working on. Allow your mind to
        wander here if you can to gather and fuel up on inspiration. This is
        brilliant and supportive energy whenever you would like to tap into it!
        Collecting the twigs and mud so to speak to create your nest. This will
        be hard work… But may you never grow tired or impatient of the process,
        and may abundance naturally follow!
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/libraOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♏︎<br /><strong>S c o r p i o</strong> ~ A sudden directedness and the
        inspiration you feel after watching a really good movie. I get this
        sense that you are moving on. Respect your body as a vehicle and keep it
        well. I get it, saying yes to one thing means saying no to another.
        Change of focus is healthy for you right now. You may feel the desire
        for different experiences, not for anyone else but for you to develop a
        different confidence about yourself. Magnetism comes from this sureness.
        Don’t doubt this development and let things flow through you without
        greed or grabbing. If it feels right, set some small goals for yourself,
        and find peace in quiet fulfillment. In your treasured relationships,
        let them know you care. They would really like to know.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/scorpioOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♐︎<br /><strong>S a g i t t a r i u s︎</strong> ~ What you find out
        about yourself this month will be teachings you will use for yourself
        and others later in life. You’re not always one for self-reflection but
        when it counts, you always show up. I feel as though more and more
        attention is being brought to your emotions, triggers, and instinct.
        This will bring knowledge on how to work with your energy in really
        beneficial ways! You may not see immediate progress but I promise, there
        are so many things working, healing, and mending for you right now. I
        appreciate your sense of always wanting to learn and get to the truth.
        Contemplate on your unique strengths. Coming in contact with them no
        matter how subtle has the power to change your whole day.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/sagittariusOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♑︎<br /><strong>C a p r i c o r n</strong> ~ After trial and error, are
        you leaving room to experience mystery? Here is some food for thought.
        At what cost are your feelings negotiated when figuring things out on an
        intellectual basis alone? To form healthy understandings of your
        reality, we must free ourselves from limiting mentalities. You are a
        resourceful genius in both mind and body. A pilgrimage is a journey
        usually to unknown and foreign places. See where the urge takes you
        despite the knowledge of the destination. Whatever new perspectives that
        are realized in October, please trust that they have the ability to
        sustain themselves. This month my hope for you is that you find pleasure
        in realizing that you have more than enough, just by Being.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/capricornOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♒︎<br /><strong>A q u a r i u s</strong> ~ A separation or ending of
        some kind has occurred. The energy this month is to assist in helping
        those who are no longer where they were and not yet where they are
        hoping to go. Tap into this if you feel called to. You know that you
        cannot take shortcuts and when you are ready, you will find the best
        course of action to take. Take some time out for a mini-vacation, even
        if that simply means stepping outside for only a moment. Do what you
        need to gather your energy. You will become aware that whatever you are
        leaving behind, wasn’t going to be fulfilling anyway. What you can count
        on during this month, and what will last, is your ability to add
        creative details to your overall vision.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/aquariusOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♓︎<br /><strong>P i s c e s</strong> ~ Things are never set in stone.
        The timing may feel off which can feel discouraging at times, but again
        nothing. Is. Ever. Set. In. Stone. Things are always in flux. While
        doing your reading, I kept getting the same feeling I would have after
        writing a test. My answers at the moment seem confident, then when
        receiving feedback afterward I would wish I could write that test again.
        You can only know what you know at a given time. I hope this eases your
        heart a bit and helps return some strength to you. We do our best with
        the information we have. You are changing in relation to something you
        don’t have control over. Be around uplifting friends or things now. If
        you need closure from somewhere, ask for it if you can.
      </p>
      <audio
        src="@/assets/horoscopes/fall2020/piscesOct2020.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        We strive to make our horoscopes accessible to all readers/listeners!
        Please email volvoxvault@gmail.com with any feedback.
      </p>
    </div>

    <br /><br />

    <div>
      <h1>summer 2020 horoscopes ☽</h1>

      <hr style="border: none; border-bottom: 1px dashed" />

      <p>
        ♈︎<br /><strong>A r i e s</strong> ~ I have three ‘S’ words for you
        this month Aries! Sunken in, Surreal, and Sobering. There’s been much to
        juggle over this last bit and you may find yourself wanting to shut the
        world out. Creating four walls however and wherever you can to hold you
        at this time, will keep you from blending into your surroundings. Pull
        back to return to yourself. Your attention is being casted to a deeper
        place. You cannot name joy unless you have experienced it yourself. So,
        your resolutions to what you are facing are something you have already
        experienced. Confidence in the newness of an environment vs. confidence
        in your experience are two different perspectives. Both offer a fresh
        look. Naivety dressed to look wise.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/aries.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♉︎<br /><strong>T a u r u s</strong> ~ It’s a great time to ask for
        help if you need, even if it’s just a short phone call or text to a
        friend. Valuable lessons are being learnt about your drive to create and
        how you manage the longevity of your relationship with creation. Nothing
        is permanent and any feelings of being scattered will settle in time.
        The work you do is not only for you, but also to help others. I think
        that is so admirable. Don’t rush it though. You need to absorb all the
        nourishment of this process. Imagine that wherever you are in life, we
        can still be learning the basic nutrients of that version of ourselves.
        Drink in the potent calm of the cocoon you are in. A caterpillar and a
        butterfly have two different guidebooks. We are wise for a moment and
        then know nothing at all.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/taurus.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♊︎<br /><strong>G e m i n i</strong> ~ You’re not going to be at home
        much, let me just say that as this horoscopes ice breaker. The
        motivations you’ve been feeling under the surface finally have a time to
        play out. This month there will be opportunity to acknowledge any
        regrets, redemption, and forgiveness. As you integrate broken pieces,
        listen to the voice that keeps begging for your attention. Crossroads
        only come up to show you that you have a choice. That you embody choice.
        If you are considering making big changes in your life, I certainly
        encourage it! Regardless, daily journaling may help you to see where you
        are coming from at a given time. Reading/Listening to those passages
        back in the future will feel like an old friend who knows your inner
        soul.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/gemini.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♋︎<br /><strong>C a n c e r</strong> ~ Think of this month as sugary
        and sweet thoughts being all the sustenance you need to keep going. Like
        gentle breadcrumbs, with something tangible just around the corner.
        Having the whole pie right now would be hard to digest. So, breaking
        things into pieces, generating energy, and not getting too hung up on
        timing will hopefully put your heart at ease. You are in the process of
        drawing your arrow backwards. Keeping an open mind to wherever that
        arrow lands will allow for what you can control. Trust in positive
        developments and romance. It may be difficult to see where these crumbs
        lead… But know that you have the skills to dream up the world you would
        like to live in.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/cancer.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♌︎<br /><strong>L e o</strong> ~ In certain complex situations you
        often know how to act. However, our minds can easily become overworked.
        It is rare for us to consider how stimulated we really are. Take some
        time this month to get to know your core values, again, and then again
        after that. Notice any reliance on sarcasm to negate any resentments or
        hidden truths. Being supportive, kind and consistent to yourself will
        help you to respect your process in the future. Then when you feel
        ready, define your thoughts and create a plan. It’s okay to take a break
        too. Deciding not to do anything is still a valid decision. Sometimes it
        feels like we are just getting by, but please know that our visions and
        voices are connected beyond what we see. This is the heat that heals.
      </p>
      <audio src="horoscopes/leo.mp3" type="audio/mpeg" controls>
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♍︎<br /><strong>V i r g o</strong> ~ Hi Virgo babies ~ a loving season
        to you, mercurial musings. I’m sensing the end of something that has
        caused stress or pain for you in the past. Please know that peace and
        strength is available to all, no matter where you are at. That healing
        lasts for however long you need so don’t feel like you have to be (x)
        better in an (x) amount of time. Speaking of needs, it’s best that you
        take care of yours. Like unbuttoning that top button after a big meal.
        Instinctual care. This month, going back to the basics of where you are
        directing your time and energy will signify a new beginning. You are
        your own living mascot of transformation. Further down the road, use
        these moments as a marker of how far you have come. Welcome in
        friendships that allow you to feel present and still.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/virgo.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♎︎<br /><strong>L i b r a</strong> ~ You recognize that your own needs
        are not cut off from those of others. At the same time, you aren’t
        looking for others to congratulate you before moving forward. I love
        this for you, as it seems you are trusting and seeing your own magnitude
        and that feels incredibly secure. You have the power to get what you
        want through the strength of your mind and force of your will. Don’t
        hesitate to ask for help along the way. This is a good month to explore
        magical helpers such as…But not limited to… Tarot, intentionally
        anointing yourself with your fav scent, caring for plants, or even the
        way you stir your coffee. (Clockwise to bring something into your life,
        counter-clockwise to let go.) Count on some vivid dreams, your desire
        being strong. Right now, preparing for the future is being in the
        present moment.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/libra.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♏︎<br /><strong>S c o r p i o</strong> ~ There are areas of your life
        that seem to be competing for your time. If you can, try not to focus on
        events unresolved. You can’t feel balanced all of the time and that’s
        okay. The best thing you can do at this time is to come clean about how
        you feel so that everyone can heal. You’ve always known about your
        presence being the catalyst for change and regeneration. Honesty and
        forgiveness will help you to stop looking outside of yourself for the
        answers. Wanting to flip to other pages in your life’s book I think is
        natural. Maybe the adventure or suspense you are craving begins when you
        fall asleep from the waking world. Pay attention to those dreams right
        now. Take whatever time you need to think, reflect, to belong. Soon the
        question of “what are you trying to tell me???” Will turn into “Wow, I
        knew this all along.”
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/scorpio.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♐︎<br /><strong>S a g i t t a r i u s︎</strong> ~ Decisions between
        what is familiar and the unknown. Take a step back, specifically on a
        mental plane if you can, to remind yourself why you do the things that
        you do. Within you is a well of endless energy. This doesn’t mean you
        always have to funnel that energy into something linear. Obviously
        easier said than done. Respect your own personal process. I am here to
        gently remind you that every moment is worth savouring, sometimes twice.
        Go back and revise wherever you feel necessary. This month is meant to
        show you which outside pressures make it difficult to remember your
        original purpose. Check in. Keep it simple. You got this!
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/sagittarius.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♑︎<br /><strong>C a p r i c o r n</strong> ~ I feel as though you want
        to be seen by someone or something, yet at the same time deny the
        pleasure of it. Why is that? Maybe you have felt you were being pulled
        in two different directions or that small disagreements felt like strong
        insults. Relationships are meant to break down the walls of what we
        think we can control. Over time, you have gathered the information to
        decide on something with your full heart and trust. You’ve been letting
        this opportunity ripen and baby, it’s about to be harvest time. Whatever
        or whoever you are considering, you are sprouting from mutual love. It
        will soon feel like every part of your world is working in accordance
        with your movement. How you interact with these situations will show you
        more of who you are.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/capricorn.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♒︎<br /><strong>A q u a r i u s</strong> ~ I think we all dislike being
        put into a box, especially you Aquarius. Exciting life transitions are
        wanting to make themselves available to you. Take to the idea that some
        moments preserve themselves. An enclosed space of juicy and worthwhile
        immersion is not a box. You are supported from all sides. There is no
        intention of tying you in, in fact the opposite. You are letting go of
        any binds this month, becoming aware of your limiting thought patterns
        and loosening the grip of self-judgement. This may be from the aftermath
        of a conflict. There is a current of meaningful and enduring
        relationships present or coming for you. Feelings bottle up when we
        continue whining at doors already closed. Look to new love and ideas
        that come from the heart. Facing yourself has been the antidote to your
        own liberation.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/aquarius.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        ♓︎<br /><strong>P i s c e s</strong> ~ You’ve grown tired of the things
        that you know. Something has changed and it is altering your reality for
        the better. Maybe you have gotten more in touch with the root of
        yourself. The place where compassion begins and extends outwards. You
        are in the process of moving on from what you were unsatisfied with. Let
        what you see and feel be said, the force of your ideas demand to be
        expressed. This doesn’t have to be verbal either. Whatever your
        comfortability, or modality. It’s not about who hears them, more so it’s
        about You giving yourself a chance. The last few months have been a
        gathering of tools to help you navigate stress to, with time, come out
        the other side. You know how people harness superhuman strength in
        situations where their adrenaline kicks in? This month, in your own way,
        that is YOU.
      </p>
      <audio
        src="@/assets/horoscopes/summer2020/pisces.mp3"
        type="audio/mpeg"
        controls
      >
        Your browser does not support the audio element.</audio
      ><br />

      <p>
        We strive to make our horoscopes accessible to all readers/listeners!
        Please email volvoxvault@gmail.com with any feedback.
      </p>
    </div>
  </body>
</template>

<script>
export default {
  name: "Horoscopes",
  components: {},
};
</script>

<style scoped>
body {
  background: linen;
  color: #383838;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

audio {
  width: 100%;
  height: 25px;
  padding-top: 10px;
  padding-bottom: 15px;
  opacity: 0.5;
}

a {
  color: #383838;
  text-decoration: underline;
}

a:hover {
  text-decoration: line-through;
}

div {
  margin: auto;
  width: 50%;
  border: dashed 1px #383838;
  min-width: 350px;
  padding: 30px;
  font-family: Times New Roman, serif;
  line-height: 2;
}

hr {
  border-color: #383838;
}

p {
  font-size: 12pt;
  line-height: 2;
}

h1 {
  font-size: 20pt;
  line-height: 2;
}

h2 {
  font-size: 15pt;
  line-height: 2;
}

@media (max-width: 800px) {
  div {
    width: 75%;
    max-width: 400px;
    min-width: 100px;
    margin: auto;
    margin-top: 5px;
    padding: 30px;
  }

  p {
    font-size: 13pt;
    line-height: 2;
  }

  h1 {
    font-size: 15pt;
    line-height: 2;
  }

  h2 {
    font-size: 15pt;
    line-height: 2;
  }
}

@media only screen and (max-device-width: 566px) {
  div {
    width: 75%;
    max-width: 400px;
    min-width: 100px;
    margin: auto;
    margin-top: 5px;
    padding: 30px;
  }

  p {
    font-size: 13pt;
    line-height: 2;
  }

  h1 {
    font-size: 15pt;
    line-height: 2;
  }

  h2 {
    font-size: 15pt;
    line-height: 2;
  }
}
</style>