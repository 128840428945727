<template>
  <MainLayout>
    <body>
      <main>
        <div id="blocks">
          <div class="block list">
            <p style="font-size: 10pt">volvox vault issue 02 | fall 2020</p>
            <hr>
            <h1>Proximity</h1>
            <hr>
            <p>prox·im·i·ty — nearness in space, time, or relationship.</p>
<hr>
            <p>
              Welcome to the Fall 2020 issue of Volvox Vault: Proximity. This
              issue is all about nearness and farness from people, places,
              creativity, and more. We’re wondering, how does proximity impact
              your creative drive, social energy, interests, habits, and so on?
              I’ve been pondering a study which was done involving college
              students and a bowl of apple slices. It showed that when
              participants were closer to the apple slices, they ate
              significantly more of them [Gregory J. Privitera &#38; Heather E.
              Creary]. So, similar to how we’ll eat the apple when it’s within
              reach, when we’re in close proximity to other creatives: we create
              more. We need each other to learn from and be inspired by.
              Especially during an international pandemic, we need to get
              creative about how we come together. So here we are! There’s
              something incredibly uplifting about immersing yourself into a
              collection of diverse perspectives like this, yet somehow they all
              seem to come from the same universal consciousness. I hope you
              will find some comfort and inspiration in this issue, the times
              have been stressful. Imagine each piece as an apple slice from the
              same nutritious apple, and enjoy!
            </p>
<hr>
            <router-link to="/archive/elliottLaurel"
              >Exploring the independent web with Elliott Cost and Laurel
              Schwulst. [Full Article]</router-link
            ><hr>
            <a href="https://tiana.computer" target="_blank"
              >Tiana Dueck - Editor-in-chief</a
            >
          </div>

          <div class="block list">
            <h2>Shop Proximity</h2><hr>
            <a
              href="https://www.magcloud.com/browse/issue/1873311"
              target="_blank"
              >Proximity [print &#38; digital]</a
            ><hr>
            <a href="" target="_blank">Proximity Tote - SOLD OUT</a><hr>
            <a
              style="line-height: 2; font-size: 18px"
              href="https://volvoxvault.square.site/product/donate/3?cs=true"
              target="_blank"
              >Donate</a
            ><hr>
            <a href=""><img src="./proxmag.jpg" width="100%" /></a>
            <a href=""><img src="./9shop.jpg" width="100%" /></a>
          </div>

          <!--
     <div class="block list">
    <p  style="font-weight: bold;">launch party</p>
    <a style="line-height: 2; font-size: 18px;" href="https://gather.town/app/RiJGCLS7iuu7RPml/volvoxvault" target="_blank">Join the launch party Oct 31st @ 7pm EDT | pw: proximity</a>
<a href="https://gather.town/app/RiJGCLS7iuu7RPml/volvoxvault"><img src="/proximity/proxLaunch1.jpg" width="100%"/></a>
    </div>
  -->

          <div class="block list">
            <h2>Pools</h2><hr>
            <p>Charlie Weare</p><hr>
            <iframe
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/qmdkWxWCXfE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
<hr>
            <p style="line-height: 2; font-size: 18px">
              “Pools.” is a personal effort to encapsulate the emotional drought
              of self isolation during the COVID-19 pandemic, hoping to provide
              a sensual experience showcasing the spectrum of proximity through
              photography &#38; music. The photograph, taken by my partner,
              Hunter McNeil, showcases my interpretation of the spectrum of
              proximity. Together, touching, genuinely nearby, expressed by the
              couple skipping rocks, and apart, isolated, or alone, the
              emptiness which surrounds them. All of which I crave
              simultaneously. Paired with the composition, an eleven-minute
              personal response to the contrasting spectrum of proximity
              expressed through instrumentation. Together the visual, &#38;
              auditory aspects of this project provide an underlying sense of
              affection, &#38; intimacy within the pieces through the lens of
              proximity.
            </p>
          </div>

          <div class="block list">
            <h2>Fit Pics</h2><hr>
            <img style="width: 100%" src="./Proximity_Fit.jpg" />
          </div>

          <div class="block list">
            <h2>2259 mi</h2><hr>
            <p>Caesaria Kim</p><hr>
            <p style="line-height: 2; font-size: 18px">
              Lately I’ve become more aware of my habit of looking for home
              elsewhere. I am always thinking “Okay, in 2 years I’ll be in
              [location] doing [activity], and I’ll finally be happy.” That self
              promise of future happiness is sometimes all that I cling on to.
              Of course, this isn’t sustainable, because the closer I get to my
              perfectly envisioned future, the clearer it is to see that nothing
              changes just based on location. <br />
              When I was in San Francisco, I spent a lot of time thinking about
              Toronto. Now that I’m in Toronto, I spend a lot of time thinking
              about New York. I am constantly trying to find a place where I
              will miraculously be happy, instead of just building one within
              myself. I’m working on that, but in the mean time, I’ll keep
              daydreaming about my fantasy cottage in New Zealand...
              <br />
              Photos taken in San Francisco and Toronto. Collaged in Keynote.
            </p><hr>
            <img src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Ari_Kim.png?v=1657683621660" style="width: 100%" />
          </div>

          <div class="block list">
            <h2>Alex — Version 3</h2><hr>
            <p>Read about this painting by Zoé de Grand'Maison in Proximity.</p><hr>
            <a
              href="https://www.etsy.com/shop/BigHouseArtHouse?ref=seller-platform-mcnav"
              target="_blank"
              >Shop her prints here.</a
            ><hr>
            <p>
              <img
                src="./Zoe_AlexVersion3.jpg"
                style="
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  width: 85%;
                "
              />
            </p>
          </div>

          <div class="block list">
            <h2>Purchase Proximity for more.</h2><hr>
            <a
              href="https://www.magcloud.com/browse/issue/1873311"
              target="_blank"
              ><img src="./proxtiana-17.jpg" style="width: 100%" />
              <img src="./apl-10.jpg" style="width: 100%" />
              <img src="./bandday-1.jpg" style="width: 100%"
            /></a>
          </div>

          <div class="block list">
            <h2>Artists featured in Proximity</h2><hr>
            <p style="line-height: 2; font-size: 18px">
              tiana dueck, charlie weare, will agnew, tom valkanas, madison
              cleary, cameron brown, shaefer genco, jordan sosensky, chris
              borges, joel rodriguez, zoe mills, nicole cipriani, jes young,
              andrei pora, oliver thompson, caesaria kim, rebecca clarke, rae
              bundy, zoe de grand'maison, elliott cost, laurel schwulzt, em
              biggs, susan le, etienne bolduc, jeremy franczyk, ryan luke, liam
              beauchamp, vincent ha, vincent shore.
            </p><hr>
            <img src="./apl-2SM.jpg" style="width: 100%" />
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "Proximity",
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
body {
    font-family: hersheyFut, arial, sans-serif;
  line-height: 2;
}

h1 {
  padding: 0;
  margin: 0;
  font-size: 20pt;
}

h2 {
  padding: 0;
  margin: 0;
  font-size: 15pt;
}

p {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 12pt;
}

a {
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
}

main {
  width: 50%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

#blocks {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding-bottom: 90px;
  align-items: flex-end;
  justify-content: flex-end;
  line-height: 2;
}

#blocks .purchase {
  min-height: 100px;
  max-height: 100px;
}

.block {
  background-color: #fbfdf7;
  border: 1px dotted #212121;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  line-height: 2;
  padding: 10px;
}

#blocks .list a {
  display: block;
  color: #9879a6;
  transition: 1s;
}

#blocks .list a:hover {
  color: grey;
}

@media (max-width: 800px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (max-device-width: 566px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>
