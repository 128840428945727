<template>
  <MainLayout>
    <body>
      <main>
        <div id="blocks">
          <a
            style="border-bottom: none"
            href="https://www.magcloud.com/browse/issue/1915590"
            target="_blank"
            ><img
              style="border: 1px dotted #212121"
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/dither_shop.png?v=1657681527528"
              width="100%"
          /></a>

          <div class="block list">
            <p style="line-height: 2; font-size: 10pt">
              volvox vault issue 03 | winter 2020
            </p>
            <hr />
            <p style="font-size: 20pt; font-weight: bold; line-height: 40px">
              Aggregate
            </p>
            <hr />
            <p style="line-height: 2; font-size: 12pt; font-style: italic">
              A whole formed by combining several elements.
            </p>
            <hr />
            <p style="line-height: 2; font-size: 12pt">
              This issue is for collaboration, and seeing what happens when we
              put our minds together. It's for connecting with each other: a
              connection is an aggregate. I believe that our energy has a
              vibration. When we gather with people who inspire us that
              vibration is impacted. I notice that when I am in a space with
              people who inspire me, or even if I'm simply observing things
              they've created, my vibration (or my frequency) feels radiant.
              When you find the right people, your energy can radiate so
              vibrantly that those around you can feel it too. I believe we
              should follow the light that we see in people, and that's the
              magic of this issue. Every piece is glowing with a frequency that
              comes from the core of each person's inspired collaborative
              energy. Channel into your vibration and enjoy Aggregate. :)
            </p>
            <a href="http://tiana.computer" target="_blank"
              >Tiana Dueck - Editor-in-chief</a
            >
          </div>

          <div class="block list">
            <p style="font-size: 15pt; font-weight: bold; line-height: 40px">
              Jeff Bezos Actually Works For Me
            </p>
            <hr />
            <p style="font-size: 12pt; line-height: 200%">
              By The John Denver Airport Conspiracy
            </p>
            <hr />
            <p>
              <audio title="Jeff Bezos Actually Works For Me" controls>
                <source
                  title=""
                  src="https://cdn.glitch.me/31c7994a-ff45-4592-b835-14262460c504/Jeff_Bezos_Actually_Works_For_Me.wav?v=1657681544376"
                  type="audio/wav"
                />
                Your browser does not support the audio element.
              </audio>
            </p>
            <hr />
            <a href="https://www.magcloud.com/user/volvox_vault" target="_blank"
              ><img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/JB.jpg?v=1657681529429"
                width="100%"
            /></a>
          </div>

          <div class="block list">
            <p style="font-size: 15pt; font-weight: bold; line-height: 40px">
              HONEYPOT
            </p>
            <hr />
            <a
              href="https://kalilhaddad.com/"
              style="font-size: 12pt; line-height: 200%"
              target="_blank"
              >An art film by Kalil Haddad</a
            >
            <hr />
            <p style="font-size: 12pt; line-height: 200%">
              A story of the eye - one read through the lens of desire.<br />
              Remixing various fragments of archival material, HONEYPOT is best
              contextualized within the tradition of video art. Utilizing
              hybrid, maximalist forms of narrative fiction, digital art, and
              documentary, the confluence of these elements is understood
              through their abstraction, through repeat viewings. Not void of
              narrative, yet not contingent upon it. Rather than a traditional
              film, which carries itself narratively from moment to moment, the
              meaning of HONEYPOT can be found within the implicit, connective
              relationships between scenes, analyzed within its details, its
              subtext - a combination of both the literal, symbolic, and the
              space between.<br />
              What does it mean for two people to feel close?
            </p>
            <hr />
            <p style="font-size: 10pt; line-height: 200%">
              Read more in Aggregate
            </p>
          </div>

          <iframe
            src="https://player.vimeo.com/video/499434644"
            width="100%"
            height="480px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <div class="block list">
            <p style="font-size: 15pt; font-weight: bold; line-height: 40px">
              Natan Lawson's Little Moments
            </p>
            <hr />
            <p style="font-size: 12pt; line-height: 200%">
              <strong
                >Tiana: How would you describe yourself as an artist?</strong
              >
            </p>
            <p style="font-size: 12pt; line-height: 200%">
              I'm naturally drawn to observe and collect ephemera. These are the
              transient objects, textures, and printed matter we encounter in
              our daily lives that one might throw out, or simply overlook. The
              principles that make an image pleasing to the eye - composition,
              movement, contrast, scale, connection - are at work everywhere. I
              find that anything, from the template of a child's cursive
              worksheet to the printed pattern on a paper plate, can often be
              appreciated for its formal qualities. While some might consider
              these things as insignificant or dismiss as outright trash, I find
              joy in letting my eye sort through the scraps to find those
              moments that speak to me as art. By collecting, documenting, and
              collaging these remnants into the primary source material of my
              paintings, I aim to monumentalize and celebrate the iconography in
              our everyday life. Since 2018 I've been using needle point as the
              primary source material in my paintings. There's so much that I
              like about needlepoint: First of all, it has endless source
              material. (I've accumulated thousands of scans in an archive and
              keep collecting more). Second, it is relatable on an emotional
              level (reminding us of home/domesticity). I also find the
              iconography is a valuable carrier of ideas. Needlepoint straddles
              this weird space where the imagery can either relate to a personal
              connection (say, your cat) or the archetype, the idea of the thing
              itself. I think needlepoint iconography exists in our collective
              unconscious as a visual, common denominator. Finally, needlepoint
              has formal qualities of the grid and stitch that lends itself to
              working with a computer: thread count corresponds to pixel size
              and a set of coordinates from gcode naturally replicates a stitch
              of thread. In short, I like to look for inspiration in the scraps.
              I love noticing compositions and identifying little moments: the
              discarded bits, clippings, doodles, and still lifes that when
              cropped, scaled, and replicated, give it new attention and life
              through my perspective of noticing and honoring the richness in
              their original design.
            </p>
            <hr />
            <p style="font-size: 10pt; line-height: 200%">
              Read more in Aggregate
            </p>
            <hr />
            <a
              href="https://natanlawson.com/work/?v=ba43077c0ac9"
              target="_blank"
              ><img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/2345_18.jpeg?v=1657681527736"
                width="100%"
            /></a>

            <a
              href="https://natanlawson.com/work/?v=ba43077c0ac9"
              target="_blank"
              ><img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/2345_24.jpeg?v=1657681529486"
                width="100%"
            /></a>
          </div>

          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/IuQpww-PWYk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/W48nPf0vfRM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <div class="block list">
            <p style="font-size: 15pt; font-weight: bold; line-height: 40px">
              AHH 20
            </p>
            <hr />
            <p style="font-size: 12pt; line-height: 200%">
              A fashion collection by Lynne
            </p>
            <hr />
            <p style="font-size: 10pt; line-height: 200%">
              <strong
                >What were your inspirations for the AHH 20 collection?</strong
              >
            </p>
            <p style="font-size: 12pt; line-height: 200%">
              A really bad summer in 2019 and the Evangelion series - especially
              The End of Evangelion. I obsessively screen shotted many frames
              and had them printed and displayed on early design boards in my
              apartment for many months.
            </p>
            <hr />
            <p style="font-size: 15px; line-height: 200%">
              Lynne | Rue | Joseph | Tiana | Nayake
            </p>
            <hr />
            <p style="font-size: 10pt; line-height: 200%">
              Read more in Aggregate
            </p>
            <hr />

            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/SML_C-8.jpg?v=1657681526938"
              width="100%"
            />
            <hr />
            <p style="font-size: 15px; line-height: 200%">
              music: what's paradise by dwig
            </p>
          </div>

          <div class="videoblock list">
            <video
              style="width: 100%; border: 1px dotted #212121"
              class="tab"
              poster="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Lynne.jpg?v=1657681529368"
              controls
            >
              Your browser does not support the &lt;video&gt; tag.
              <source
                src="https://cdn.glitch.me/31c7994a-ff45-4592-b835-14262460c504/Lynne.mp4?v=1657681549249"
              />
            </video>
          </div>

          <div class="schaeBlock list">
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s3.png?v=1657681529018"
              width="100%"
            />
            <hr>
            <p style="font-size: 12pt; line-height: 200%; text-align: center; padding: 10px">
              Schaefer Genco, lLara O'Lanick, Noah Bradbury, Dylan Obront, Dame
              Anthony, &#38; Kate Shatalow
            </p>
            <hr>
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s1.png?v=1657681528612"
              width="70%"
              style="display: block; margin-right: auto; margin-left: auto"
            /><br />
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s2.png?v=1657681528954"
              width="70%"
              style="display: block; margin-right: auto; margin-left: auto"
            /><br />
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s4.png?v=1657681528819"
              width="70%"
              style="display: block; margin-right: auto; margin-left: auto"
            /><br />
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s5.png?v=1657681529262"
              width="70%"
              style="display: block; margin-right: auto; margin-left: auto"
            /><br />
            <img
              src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/s6.png?v=1657681529670"
              width="70%"
              style="display: block; margin-right: auto; margin-left: auto"
            /><br />
          </div>

          <div class="block">
            <p style="font-size: 15pt; font-weight: bold; line-height: 40px">
              Featuring
            </p>
            <p style="font-size: 12pt; line-height: 200%">
              <a href="http://tiana.computer/">tiana dueck,</a>
              <a href="https://natanlawson.com/process">natan lawson,</a>
              <a href="http://kiyel.care/">kiyel (yerim ki),</a>
              <a href="https://76666.world/releases/still-life-with/"
                >inwoo jung,</a
              >
              <a href="mailto:maxwellmatchim@gmail.com">maxwell matchim,</a> day
              carter,
              <a href="mailto:shelbykbourbonnais@gmail.com"
                >shelby bourbonnais,</a
              >
              <a href="https://kalilhaddad.com/">kalil haddad,</a>
              <a>lynne,</a>
              <a href="mailto:josephgleasure@gmail.com">joseph gleasure,</a>
              <a href="https://rue8yi.myportfolio.com/">rue yi,</a> nayake,
              <a href="https://www.beatricedouaihy.com/">bea douaihy,</a>
              <a href="https://jjeroboam.bandcamp.com/">aj wright,</a>
              <a href="https://johnbengtsson.com/">john bengtsson,</a>
              <a href="https://sundaysites.cafe/">sunday sites,</a>
              <a href="http://www.schaefern.com/">schaefer genco,</a>
              <a href="mailto:larakolanick@gmail.com">lara o'lanick,</a> noah
              bradbury, dylan obront, dame anthony, kate shatalow, the john
              denver airport conspiracy,
              <a href="mailto:ceciliadelosrios@rogers.com"
                >cecilia de los rios,</a
              >
              &#38;
              <a href="mailto:anamaria257257@gmail.com"
                >ana mar&Iacute;a romero</a
              >.
            </p>

            <p
              style="
                font-size: 14px;
                line-height: 200%;
                text-decoration: underline;
              "
            >
              <a href="http://kiyel.care/svod" target="_blank">svod.stream</a>
            </p>

            <a href="https://www.magcloud.com/user/volvox_vault" target="_blank"
              ><img
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/thankyou.png?v=1657681527675"
                width="100%"
            /></a>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "Aggregate",
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
body {
  line-height: 2;
  font-family: hersheyFut, arial, sans-serif;
}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 14pt;
}

a {
  color: #9879a6;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  transition: 1s;
}

a:hover {
  text-decoration: underline;
}

main {
  width: 50%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

#blocks {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding-bottom: 90px;
  align-items: flex-end;
  justify-content: flex-end;
}

#blocks .purchase {
  min-height: 100px;
  max-height: 100px;
}

.block {
  background-color: #fbfdf7;
  border: 1px dotted #212121;
  min-height: 445px;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  line-height: 2;
  padding: 10px;
}

.schaeBlock {
  min-height: 600px;
  max-height: 600px;
  overflow-y: scroll;
  background-color: #fbfdf7;
  border: 1px dotted #212121;
}

.videoblock {
  min-height: 500px;
}

#blocks .list a {
  display: block;
}

.btn-group button {
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  width: 100%; /* Set a width if needed */
  display: block; /* Make the buttons appear below each other */
  text-align: left;
  padding-left: 10px;
  -webkit-appearance: none;
}

.rand_img {
  background-size: cover;
  background-position: center center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: auto;
  height: auto;
  grid-gap: 0px;
}

.gallery_display {
  position: relative;
  overflow: hidden;
}

.gallery div:hover {
  opacity: 0.8;
}

.thumb {
  cursor: pointer;
}

.thumb img {
  width: 100%;
  height: auto;
}

img {
  display: block;
}

#footer {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 10px;
  border-top: 1px solid black;
}

@media (max-width: 800px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (max-device-width: 566px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>