<template>
  <MainLayout>
    <body>
      <main>
        <div>
          <div>
            <h1 class="head">Seas Of Noise</h1>

            <p class="headDes">
              With
              <a
                href="https://www.instagram.com/yourfriendbrennan/"
                target="_blank"
                >Your Friend Brennan</a
              >.
              <br />
              <em
                >Originally in
                <a
                  href="https://checkout.square.site/buy/TWWRLYXJEZVU5BCM3W56HKSX"
                  >Volvox Times</a
                >.
                <a href="https://www.patreon.com/volvoxvault"
                  >Video on Patreon</a
                >.</em
              >
            </p>

            <div style="padding-top: 25px; padding-bottom: 25px">
              <iframe
                style="border: 0; width: 100%; height: 373px"
                src="https://bandcamp.com/EmbeddedPlayer/album=4273364914/size=large/bgcol=ffffff/linkcol=333333/artwork=small/transparent=true/"
                seamless
                ><a href="https://yourfriendbrennan.fanlink.to/seasofnoise"
                  >Seas Of Noise by Your Friend Brennan</a
                ></iframe
              >
            </div>

            <p
              style="
                border: 1px solid yellowgreen;
                border-radius: 30px;
                padding: 20px;
              "
            >
              <em
                >In early October 2021, I met with multi-instrumentalist
                <a href="https://www.instagram.com/yourfriendbrennan/"
                  >Brennan Doyle</a
                >
                of Your Friend Brennan over video call (<a
                  href="https://www.patreon.com/volvoxvault"
                  >watch</a
                >). He was cocooned in a blanket at Risque Disque studio over in
                BC, finishing his album. Brennan has been in a number of bands,
                like Cartoon Lizard, and often performs with Haley Blais, and
                Diamond Cafe. 
                <br>
                Now, we're sharing this interview online to celebrate his first 
                solo album, Seas Of Noise, which was just released on April 10th.</em
              >
            </p>
            <br>
            <p>
              <span class="HotelQ"
                >Tiana: When did you start making music?</span
              >
              <br /><br />
              <strong>Brennan:</strong> Since grade 6, kinda.—I was like ‘Yeah I
              wanna be a rockstar’ or something. I was going through a White
              Stripes phase or a Led Zeppelin phase... so my songs were
              terrible, awful rip-offs of those bands. <br /><br />

              <span class="HotelQ">What inspired this solo album?</span>
              <br /><br />
              Over quarantine I started listening to a lot more ambient music.
              Brian Eno, Oneohtrix Point Never. I’ve always loved the Spirited
              Away soundtrack, which is very piano based.—I was like, ‘I just
              want nice background music to make breakfast to in the morning and
              sit around to in the living room... I should just make some of
              that.’
              <strong
                >I made these songs with the intention of being able to put them
                on in the background.</strong
              >
              Not that you can’t appreciate them while focusing on them, but
              leaving room for your own thoughts. <br /><br />

              <span class="HotelQ">Why did you keep the album lyricless?</span>
              <br /><br />
              I find as I get older, with lyrics and stuff, it’s really hard to
              have something to say, because the big topics are so complex and
              nuanced. So I’d rather just make an emotion through a song that’s
              interpretive.
              <br /><br />

              <span class="HotelQ"
                >What’s the contrast between the experience of writing a song
                like Seas of Noise, and the experience of listening to it?</span
              >
              <br /><br />
              Seas of Noise was actually the only song on this upcoming album
              that I had written prior to the idea of this album. It initially
              had lyrics, and I had a demo of it that was quite different in
              arrangement, and me singing on it. I was always really nervous to
              record it, because I’m like, ‘I really love the song, and the
              lyrics are some of the best I’ve written; but, I just don’t have a
              lot of confidence with my voice, so I don’t want this song to be
              brought down to the level of my vocal performance.’ When I had the
              idea of making a piano album, I kind of repurposed it. I’m like,
              ‘I’m just not going to sing the vocals.’ But the recording sounds
              kind of, I guess you could say random, in terms of where spaces
              are and things, but that was so intentional. I would go through
              and edit the length of the spaces, like when I initially recorded
              the song, because it’s not to a BPM or anything. <strong>I had to do it
              all by feel, and I would intentionally leave long spaces. I had
              the option to leave the perfect pause… the pauses are so thought
              out.</strong>
              <br /><br />

              <span class="HotelQ">Does this album have a narrative?</span>
              <br /><br />
              With this music I was purposefully not thinking of a narrative.
              Not like ‘this the emotion of love or something, or this is the
              emotion of this.’ I just went by like, ‘this just feels emotive,
              whatever it is.’ I never really thought about it or contrived it
              too much. <strong>With Seas of Noise, initially one of the lyrics was
              like, ‘Why should I spill my voice into an endless sea of noise?’</strong>
              So it’s kind of fitting that I took the lyrics out.—I just really
              liked the title. I thought it had cool juxtaposition with the
              album, being quite calm, not noisy, and soft.
              <br /><br />

              <span class="HotelQ"
                >What instruments are in Seas of Noise?
              </span>
              <br /><br />
              There’s a piano. Cello, like when it comes in, you hear these kind
              of like plunking it almost sounds like a stand up bass, it’s
              actually a cello. And kind of like low open notes—any strings you
              hear is a cello that I borrowed from my friend, Ryan. Then there’s
              a trumpet doing all the horn stuff. There’s a harp. I rented a
              harp, just went ham. There’s a synth, kind of like a synth sub
              bass. It’s not too sub-y, you can’t really distinguish it from the
              piano too much. I think that’s it. So there’s only like five
              instruments on it. It gets a lot more dense on other songs in the
              album.
              <br /><br />

              <span class="HotelQ">Had you ever played the harp before?</span>
              <br /><br />
              No, I hadn’t. But it’s funny because people seem pretty impressed
              by the harp stuff. I was just like ripping that harp. <strong>I tuned it
              so I literally couldn’t hit a bad note. Just going like *boo boo
              boo* with my fingers.</strong> I have no ability to form chords. I’m just
              strumming it.
              <br /><br />

              <span class="HotelQ">What does this album mean to you?</span>
              <br /><br />
              It means a lot to me, I really love it. Since I have an insecurity
              with my singing voice, I just bypassed that completely. And yeah,
              I would actually listen to this all the time, which is a new thing
              to me when it comes to my personal songwriting. The songs that
              I’ve put up with bands and things are collaborative efforts, and
              I’ve loved them too. <strong>But, stuff that’s just solely dependent on my
              efforts, this is the first time I’ve come to a place where I feel
              really confident with it.</strong>
              <br /><br />

              <span class="HotelQ"
                >Do you have any hopes for how your music makes another person
                feel?</span
              >
              <br /><br />
              I want people to feel relaxed and introspective. That’s a word
              that comes up a lot. <strong>To me, I think the ideal way to listen to any
              song on the album is in a comfy chair, pitch black room at night,
              with some nice speakers, maybe a candle... maybe.</strong> But I really like
              the pitch black thing, it really makes you listen to all the
              intricacies…
              <br />
            </p>

            <div class="imgBox">
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/bdithergreen.jpg?v=1649699269370"
              />
              <p style="line-height: 0; margin-top: 20px; text-align: center">
                <em
                  >Photo:
                  <a href="https://www.blairhansen.ca/">Blair Hansen</a></em
                >
              </p>
            </div>

            <p>
              <span class="HotelQ"
                >Have you ever been in a float tank? That might be cool to
                listen in...</span
              >
              <br /><br />
              I guess that kind of takes away from the purpose of a float tank,
              because it’s supposed to be sensory deprivation.
              <br /><br />

              <span class="HotelQ"
                >I guess you have one sense as a treat.
              </span>
              <br /><br />
              Yeah. That would be the most amazing way to listen to this music.
              It would be sick.
              <br /><br />

              <span class="HotelQ"
                >It’s too bad it wouldn’t really work to have, like, a float
                concert. It’d be kinda gross.
              </span>
              <br /><br />
              Yeah, I try to think of things like that. <strong>Because I think maybe a
              future form of music would be more all encompassing, in terms of
              senses.</strong> Like, I’d love to build a machine where it’s programmed
              like, you get cold during some parts of the song or
              <em>some shit</em>. <br /><br />

              <span class="HotelQ"
                >Woah, that’s good. Why just one sense? There’s so many
                senses...
              </span>
              <br /><br />
              Just start shaking violently during the chorus.
              <br /><br />

              <span class="HotelQ"
                >Haha, yes!—I like the footsteps in Seas of Noise. It feels like
                I’m sort of there where the music is being played. Is that
                something you tried to do?
              </span>
              <br /><br />
              Not intentional at all. That was funny actually. When I was
              recording in this room actually. We brought the piano out [from
              the wall] a bit because it just sounds better. And it was just
              like a night time kind of by myself and my friend Shiloh who runs
              this studio was just kind of walking around the stairs upstairs,
              doing his thing. He’s always on a call. So he likes to walk and
              talk. He just happened to kind of stomp around during a pause.
              Which is so great though, because I’m so familiar with the space,
              I’ve been here a lot. It just sounds so much like there’s somebody
              above me walking, which is cool. And I think back to that exact
              time. For me, it’s kind of like a personal thing. I have a direct
              visual association with that. I really like it. <strong>I mean, if the
              whole song you could hear nonstop people talking and being noisy
              in the background it would be kind of annoying, but it’s just like
              this nice little detail that crept in there.</strong>
              <br /><br />

              <span class="HotelQ"
                >In my head it was someone walking up to the piano and then
                leaving. I think it’s another thing that adds to those senses,
                like the whole floating thing. Just, you know, when there’s
                different stuff other than the song going on?
              </span>
              <br /><br />
              <strong>Yeah, one of the things I really want to do with this music is
              make it as intimate as possible,</strong> and like, play things as quietly
              as I could. And then what happens, you can hear creeks, you can
              hear people walking, and it creates more of a sense, it just feels
              more real. Like you’re in a room listening to somebody.
              <br /><br />

              <span class="HotelQ">Very cool. Any final thoughts? </span>
              <br /><br />
              The album is being completed this weekend, which is very surreal
              and hasn’t really solidified in my head yet. But I want to kind of
              get it out as soon as possible.
              <br /><br />
            </p>

            <p
              style="
                border: 1px solid yellowgreen;
                border-radius: 30px;
                padding: 20px;
                text-align: center;
              "
            >
              <em
                ><a href="https://yourfriendbrennan.fanlink.to/seasofnoise"
                  >Your Friend Brennan's album Seas Of Noise is out now!</a
                ></em
              >
            </p>

            <br />

            <a href="https://paypal.me/ti4na" target="_blank"> Tip Jar ☕ </a>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "seasofnoise",
  components: {
    MainLayout,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Seas of Noise",
  },
};
</script>

<style scoped>
body {
  line-height: 2;
}

.HotelQ {
  color: yellowgreen;
  font-weight: bold;
}

.head {
  text-align: center;
  font-size: 20pt;
  line-height: 1.5;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}

a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  color: grey;
  text-decoration: none;
}

main {
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(255, 255, 255, 0.6);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 40px;
  border-radius: 45px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid yellowgreen;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 30px;

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

mark.mollyA {
  background-color: Moccasin;
  color: black;
}

mark.molly {
  background-color: OliveDrab;
  color: black;
}

@media (max-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-device-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}
</style>