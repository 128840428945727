<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>
    <h1>Patient Object</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://www.instagram.com/yslkouros/" target="_blank">Terry</a>
      <a href="https://www.terrycole.space/" target="_blank">Cole</a> (He/Him).
    </p>
    <br />
    <div class="youtube">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZByBKFhH5j8?si=SGU0DPQemY5_BYTH"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          This piece was conceived entirely with a flip phone camera, and I
          composed the music for piano in real time with the video. It is about
          living and moving through the city. It is a contemplation on the
          spatial, volumetric relationships between the camera and reality
          around it; an inquiry into the nature of perspective itself. As much
          as it is a contemplation on the objects and spaces the camera
          inhabits, the piece treats the image itself as a tangible object, as
          something to be kept and held onto, as something that gets abused too
          often in the age of Instagram, TikTok, etc... This piece is a further
          expression of an established through line in my work, which I refer to
          as 'compressionism': the idea that digital imaging technology, in its
          inadequacies and errors, can sometimes offer us impressions of reality
          that bring us closer to beauty and truth than images of high fidelity.
        </p>
        <br />
        <h3>TERRY COLE</h3>
        <p>
          tcoopercole{@}gmail.com
          <br />
          An American artist who works in painting, drawing, and photography. He
          was a child who came of age in a gap where digital technology was
          fully ubiquitous, yet prior to the dawn of social media. His work both
          leverages and inquires into the implicit languages that images take on
          in the digital era, and which continue to evolve at exceeding rates. A
          through line in his work is a continued interest in the way cameras
          impose the perspective grid onto reality; the way that the difference
          in sizes of image sensors relate to spaces and objects. Some of his
          works are made with flip phones, iPhones, and others made with large
          format digital cameras and scanners. There is a sense of both camera
          and subject intertwining and harmonizing through his photographs. His
          work struggles to express substance and light to a world where the
          image is continually degraded and used by algorithms to make us more
          wanting and complacent. Cole's images leave behind an artifact of
          youth in the age of the internet, full of yearning for wholeness and
          lament for an overstimulated, instagram-addicted generation.
        </p>
      </div>
      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>Camera / Editor / Pianist: Terry Cole</p>
      </div>
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH TERRY</span>
        </h2>
        <br />
          <h3>Why were you inspired to make this specific film?</h3>
          <br />
        <p>
          I wanted to center things in the small approachable viewfinder of my
          Alcatel AT&T flip phone. I got it off of facebook marketplace to quit
          instagram and quit my addiction to my iPhone. This film is very tied
          to the object of this phone and the way I felt like I induced forms of
          asceticism that led me away from digital technology. This specific
          film has a love letter to Boston inside it. It has unfamiliarity but
          soft light tenderness and kindness and breathing. It has rich yellow
          light from the sun during cold months.
        </p>
        <br />
        <h3>What do you like most about your film?</h3>
        <br />
        <p>
          I like the architecture of this film. The way transitions happen, the
          way camera movements suggest an awkward and unsolved orientation, a
          seeking, walking around a cube like you'd walk around a vehicle in a
          used car lot to assess it. This film does a lot of ambiguous assessing
          and it does some keeping and some holding.
        </p>
        <br />
        <h3
          >Did anyone specific become critical to the creation of your film? Why
          do they stand out as significant to your film?</h3
        >
        <br />
        <p>
          My girlfriend Ivy was really central to the editing process. I
          probably showed her 2 or 3 cuts and the final thing changed a lot from
          what I was originally going after. I think she helped push me to
          sculpt this into something more specified and sanded down like a piece
          of wood.
        </p>
        <br />
        <h3
          >What is one of your fondest memories from the production/process of
          making this film?</h3
        >
        <br />
        <p>
          My fondest memories are when the camera kept calling me back; the
          memory of making images that did not end up in the film but held up
          the thick concept and looming cloud of making this project over
          months. There are fond memories of the way I continued to work out and
          define what I was trying to do with how I treated the little webcam in
          my pocket. There are bits of life and moving through the city and
          there are bits of confusion and anonymity.
        </p>
        <br />
        <h3>How does your true self shine through in your film?</h3>
        <br />
        <p>
          This film shows me trying to hold onto things that I lived through but
          which do not identify my life all that well. This film has a lot of
          what I see in it. This film has a lot of things I might feel a little
          ashamed to have taken so much joy in seeing.
        </p>
      </div>
    </div>
    <div
      class="chat"
      style="border: 1px dotted #212121; padding: 30px; height: 400px"
    >
      <script
        type="application/javascript"
        id="cid0020000363317081813"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "patientterry",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>
    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>

<script>
export default {
  name: "cole",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
  line-height: 1.6;
  background: #664c71;
  font-family: hersheyOld;
  height: 100%;
  color: #f3f9e6;
  padding: 30px;
  overscroll-behavior: none;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  line-height: 1;
  font-size: 45pt;
}

h2 {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

ul {
  font-size: 15pt;
}

.logo {
  width: 50px;
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;
}

a {
  color: #f3f9e6;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  background: #f3f9e6;
  color: #664c71;
}

.ts-contain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.ts-box {
  border: 1px dotted #212121;
  padding: 30px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.youtube {
  border: 1px dotted #212121;
  padding: 30px;
  margin-bottom: 10px;
  height: 55vh;
  min-height: 350px;
}

.chat {
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .ts-contain {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .ts-box {
    height: 300px;
  }

  .youtube {
    height: 200px;
    margin-bottom: 20px;
  }

  .chat {
    display: none;
  }

  .credits {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
</style>
