<template>
    <body>
        <img src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276" class="logo" />
    
        <h1>true self film fest</h1>
        <br>
        <p style="font-family: hersheyFut;">presented by
            <router-link to="/">volvox observer</router-link> | <a href="https://ko-fi.com/volvoxobserver">support us on ko-fi</a>
            <br> hosted by <a href="https://tiana.computer">tiana dueck</a> & <a href="http://kalilhaddad.com">kalil haddad</a>
        </p>
        <br>
    
    
    
    
        <div class="ts-contain">
    
            <div class="ts-box">
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">ABOUT</span></h2>
                <br>
                <p>
                    A showcase of short films that are made from the heart. We're thrilled to share films that artists were drawn to create from their core, with less focus on the technical details, and more on communicating a feeling that naturally pours from them. Filmmaking
                    can be daunting and overwhelming, so we intend to practice a calmer artist-driven approach through this quaint fest.
                </p>
                <br>
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">PROGRAMME</span></h2>
                <br>
                <p>
                    <em>Each section is around 30 min.</em>
                    <br> A Wet Cashew by Charles VanderKlipp
                    <br> Vlog #1460 by Connie Liu
                    <br> I Am by Kailum Graves
                    <br> I Am Here by Alexandra Stenberg
                    <br> Across The Water by Ellen Bjerborn
                    <br> A Film About My Grandparents by Joel Haver
                    <br> Estranged by Lisa Saban
                    <br> ~ + ~ + ~ + ~ 10 min intermission ~ + ~ + ~ + ~
                    <br> Mega Memories by Lara Olanick
                    <br> Joel Meyerowitz and My YouTube Addiction by Stanley Tucker
                    <br> Goddess (She/Her/Hers) by Charli Christine Marker
                    <br> Why Do Ants Go Back To Their Nest? by Alex Lo
                    <br> Tracing the Materiality of a Cellular Connection by Alan Perry
                    <br> ~ + ~ + ~ + ~ 10 min intermission ~ + ~ + ~ + ~
                    <br> Matador by Matias Vaisman
                    <br> Patient Object by Terry Cole
                    <br> Okay, See You Then by Maia Liebeskind
                    <br> Salad by Tiana Dueck
                    <br> Ellie’s Lipstick by Anna & Jacob Brotman-Krass
                    <br> Echappe by Xinli She
                </p>
    
            </div>
    
            <div class="ts-box">
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">SCREENINGS</span></h2>
                <br>
                <p><strong>TORONTO SCREENING: NOVEMBER 1 @ 7:30PM</strong>
                <br> Hosted by <a href="https://kalilhaddad.com">Kalil Haddad</a>.
                <br> Where: Eyesore Cinema (1176 Bloor Street West Toronto ON M6H 1N1)<br>
                    <a href="https://checkout.square.site/buy/3X3BSW3RH7SFXU5WTKO26VDD">Purchase Tickets</a>
                    <br>
                    <strong>$5 EARLY BIRD UNTIL OCT 25</strong> or $10 (Oct 25 - Nov 1)<br>
                    Doors: 7:30pm — Screening begins promptly at 8pm, please be on time. :)</p>
                <br>
                <p><strong><a href="https://workingroom.online/trueself.html" target="_blank">RICHMOND SCREENING: NOVEMBER 5 @ 7:00PM</a></strong>
                <br>Where: Working Room — <a href="https://docs.google.com/forms/d/e/1FAIpQLSdAWHW7Y6ipWDC_RALRSCM0HXfFGJIso7NEGs3g34-oH-UCeg/viewform" target="_blank">RSVP to receive address.</a><br>
                    Attendance is free. Drinks and refreshments will be provided.
                   <br>
                    Doors: 7pm — Screening begins promptly at 8pm, please be on time. :)</p>
                <br>
                <hr>
                <br>
                <p>PAST SCREENINGS</p>
                <br>
                <p><strong>ROTTERDAM SCREENING: AUGUST 27 @ 17:00 CEST</strong>
                <br> Where: Extra Practice (Zwaanshals 209, 3036 KN Rotterdam)<br> 
                Attendance is free. <a href="https://forms.gle/dJRov6Hv46oTUzpLA">Please RSVP</a>.</p>
                <br>
                <p>
                    <em>Interested in hosting a screening? Send us an email: hello@volvox.observer</em>
                </p>
            </div>
    
            <div class="ts-box">
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">SUBMIT YOUR FILM</span></h2>
                <br>
                <p>
                    Send your submission to hello@volvox.observer with the subject "true self", include a brief artist's statement and the category of your film (see guidelines) for a chance to be featured in the fest.
                </p>
                <p><em>Submissions are closed but feel free to send us a film anyways: hello@volvox.observer</em></p>
                <br>
    
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">GUIDELINES</span></h2>
                <ul>
                    <li>run time must be between 1-15 min</li>
                    <li>the production must be independent</li>
                    <li>all content must be original; with the exception of unoriginal content used for quotation, criticism, or review</li>
                    <li>your film should be in the scope of one of our categories...
                        <ul>
                            <li>experimental (alternative films)</li>
                            <li>based on something true (fiction films)</li>
                            <li>point of view (documentary films)</li>
                        </ul>
                    </li>
                </ul>

                                <hr>
                <br>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/zAUwJ4zgwF0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    
            </div>
    
            <div class="ts-box">
                <h2 style="color:#664c71;"><span style="background:#f3f9e6; padding: 5px;">THE FEST</span></h2>
                <br>
                <p>
                    ONLINE: The festival will be published online at this url in December.
                    <router-link to="trueselftemplate">Each selected film will have its own webpage (example)</router-link> that showcases the film as well as special features (so document your production process!) like photos, Q&As, a director's statement, and more. We will host an online
                    live premiere of the fest.
                    <br><br> IRL: We are hosting in person festivals in Toronto, Rotterdam, and other cities worldwide.<br> Interested in hosting a screening? Let us know: hello@volvox.observer
                </p>
            </div>
    
        </div>
    
        <br>
        <p>
            Follow <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on instagram and
            <router-link to="/support">subscribe to our newsletter</router-link> for updates.<br> Share your ideas in the tsff chat...
        </p>
    
        <br>
    
        <div style="border: 1px dotted #212121; padding: 30px; height: 400px;">
<script type="application/javascript" id="cid0020000341434605351" data-cfasync="false" async src="//st.chatango.com/js/gz/emb.js" style="width: 100%;height: 100%;">
{
    "handle": "trueselfff",
    "arch": "js",
    "styles": {
        "a": "f3f9e6",
        "b": 100,
        "c": "000000",
        "d": "000000",
        "k": "f3f9e6",
        "l": "f3f9e6",
        "m": "f3f9e6",
        "p": "10",
        "q": "f3f9e6",
        "r": 100,
        "ab": false,
        "usricon": 0,
        "surl": 0,
        "allowpm": 0,
        "cnrs": "1",
        "fwtickm": 1
    }
}
</script>
        </div>
    
    
    </body>
</template>


<script>
export default {
    name: "tsff",
    components: {},
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: "True Self Film Fest",
    },
};
</script>

<style scoped>
@font-face {
    font-family: hersheyOld;
    src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
    line-height: 1.6;
    background: #664c71;
    font-family: hersheyOld;
    height: 100%;
    color: #f3f9e6;
    padding: 30px;
    overscroll-behavior: none;
}

h1,
p {
    padding: 0px;
    margin: 0px;
}

h1 {
    line-height: 1;
    font-size: 45pt;
}

h2 {
    padding: 0px;
    margin: 0px;
    line-height: 1;
}

ul {
    font-size: 15pt;
}

.logo {
    width: 150px;
    float: right;
    padding-left: 10px;
    padding-bottom: 10px;
}

a {
    color: #f3f9e6;
    transition: 1s;
    text-decoration: underline;
}

a:hover,
.ok {
    text-decoration: none;
    background: #f3f9e6;
    color: #664c71;
}

.ts-contain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.ts-box {
    border: 1px dotted #212121;
    padding: 30px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .ts-contain {
        grid-template-columns: 1fr;
    }
    .logo {
        width: 200px;
    }
}
</style>