<template>
  <MainLayout>
    <body>
      <main>
        <div>
          <div>
            <h1 class="head">Spotlight</h1>

            <p class="headDes">
              With
              <a
                href="https://www.instagram.com/trevorjameslang/"
                target="_blank"
                >Trevor Lang</a
              >.
              <br />
              <em
                >Originally in
                <a
                  href="https://checkout.square.site/buy/TWWRLYXJEZVU5BCM3W56HKSX"
                  >Volvox Times</a
                >.
                <a href="https://www.patreon.com/volvoxvault"
                  >Video on Patreon</a
                >.</em
              >
            </p>

            <div style="padding-top: 25px; padding-bottom: 25px">
 

              <iframe style="border: 0; width: 100%; height: 120px;" src="https://bandcamp.com/EmbeddedPlayer/album=2939687156/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://trevorlang.bandcamp.com/album/the-instruments-ep">The Instruments EP by Trevor Lang</a></iframe>

            </div>

            <p
              style="
                border: 1px solid yellowgreen;
                border-radius: 30px;
                padding: 20px;
                margin-bottom: 20px;
              "
            >
              <em
                >Trevor James Lang is a musician and
              artist based in Vancouver, British Columbia. Recently, he released
              a new solo single, Spotlight. Taking a moment away from
              collaborative music projects with his usual bands (Cartoon Lizard
              and Haley Blais), he’s focusing on solo music again for the first time in years. I met with Trevor (and his cat Star) over a
              video call to chat about his blossoming solo endeavours this past Winter. </em
              >
            </p>

            <p>
              <span class="trevor">“I finished these four songs that have different feelings and vibes.
              And <em>Spotlight</em> is the last of that
              batch. Though there is a lot more to come. In a way, it’s the true
              sound that I want my music to have. Whereas the other songs were
              sort of parts of my musical aspirations, Spotlight feels like a
              culmination of all of my ideas in one cohesive single. So I’m
              really excited to get it out there. I mean, parents can’t pick
              favourites, but... <strong>it’s kind of my favourite of the bunch</strong>,”</span> 
              Trevor proclaimed. Star meowed in agreement.
              </p>

              <div class="imgBox">
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Oscillations%20%234%20Trevor%20James%20Lang.00_19_16_05.Still001.jpg?v=1651514948737"
              />

              </div>

              <iframe style="border-radius:12px; margin: 0px; margin-bottom: 20px; padding: 0px;" src="https://open.spotify.com/embed/episode/23qI9nnlXjiOuFrFTNjbn6?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>

            

            <p>

              <span class="trevor">“When I started writing
              Spotlight, I thought it was going to be a sort of Fiona Apple-y
              kind of freak/alt folk song, with a lo-fi vibe. But then it got a
              little bit funkier and groovier. I started adding synthesizers to
              it, and it sort of felt like everything that I was listening to...
              My main inspirations these days are Bob Dylan and Stevie Wonder. I
              like the sort of bulkier sound of Dylan’s stuff , and then the
              hyper-groovy, funky, spacey Stevie Wonder stuff from like the
              seventies, you know? With that contrast, it’s a difficult place
              to find a balance, because they’re both obviously such
              legends—and I’m not saying that I’ve gotten there at all—but I
              mean, you can even see like up around the top here, I have like
              all of my most inspirational stuff,”</span> he points to vinyl records
              on a shelf around the upper perimeter of his space, 
              <span class="trevor">“There’s
              Joanna Newsom, Stevie Wonder, Bob Dylan... Brian Wilson is up
              there. They’re all sitting up there, I love having them around.
              They’re either helping, or looking down on me shaking their
              heads.”</span> I admired his shrine, and asked how he feels about working
              solo vs collaboratively. <span class="trevor">“They’re both very difficult and freeing
              in their own ways. The thing about working by yourself is that if
              you spend a lot of time on something and it doesn’t really turn
              out the way you wanted it to, you have no one to blame except
              yourself. It can be a hard realization to make at times, but then
              at the same time, some of those songs that you make by yourself
              are the most satisfying, because they’re custom made for you, you
              know? I gotta say, I’m a bit of a control freak at times. So
              it can be really difficult working in a collaborative setting,
              because it’s essential to relieve some of that control to the
              people you’re collaborating with. <strong>That control is sort of attached
              to your ego and you have to step back.</strong> I mean, with Cartoon Lizard
              specifically, our whole MO was to have a completely democratic
              process. We all have opinions and we all try to work together, but
              it can get so weedy because people’s feelings get involved. But in
              the end, your collaborators are coming up with things that you
              wouldn’t have come up with and it can be so magical. </span>
              
              <p class="quote">
              I’m a bit of a control freak at times — it’s essential to relieve some of that control to the
              people you’re collaborating with.
              </p>

              <p>
              <span class="trevor">
              There’s this
              feeling that we’ve sort of talked about, where you have a song
              that you present and then someone goes off with it for a while,
              and then maybe someone else adds to it, and then you get it back
              without hearing anything. All of a sudden, it’s your initial
              songs, the thing you really liked, but now it’s new again. So
              again, it’s like a custom made song for you, but that you had
              nothing to do with other than the initial idea. It’s such a great,
              weird feeling.—Everyone in that band has had a huge impact on my
              life. <strong>You uncover new parts of yourself when working with someone
              who has new ideas for you to react to.</strong> I’ve been really lucky that
              a lot of people have reached out and asked for me to make them a
              music video. And in doing that, it’s like a new form of
              collaboration that I had never done before. Just being around
              other people and seeing how they go about things, it’s like
              traveling. Going to a diff erent country and seeing how another
              culture operates while comparing it to your own like, ‘Oh, that’s
              so weird. I never would have thought how weird my own culture
              is.’”</span></p>
              
              <p class="quote">
              You uncover new parts of yourself when working with someone
              who has new ideas for you to react to.
              </p>

              <p>
              I asked him what the perfect listening situation would be for
              Spotlight from home. <span class="trevor">“<strong>The most formulative music experiences in my
              life have been ones where I’d go into an album or song and fully
              surrender to it, and crazy shit can happen.</strong> You know, you don’t
              have to be high or more inebriated at all for your mind to go off
              and experience something unique. So, definitely closed eyes with
              some nice headphones in a dark or mood-lit room. Maybe a salt lamp
              or Christmas lights. But if you must watch something, I’m hoping to have a video for it. Although, who
              knows if that’s going to happen. There’s a lot of things happening
              between now and then. Otherwise, any generic YouTube
              visualizing... trippy fractals.”</span>
            </p>

            <div class="imgBox">
              <img
                style="border-radius: 30px"
                src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/273472919_534043064411892_4441666417675951232_n.jpg?v=1651515044164"
              />
              <p style="line-height: 0; margin-top: 20px; text-align: center">
                <em
                  >Photo:
                  <a href="https://www.instagram.com/p/CZpQfoOJFgy/">Rocktographers</a></em
                >
              </p>
            </div>

            <p>
              In closing, I asked him if he had
              any advice for fellow musicians and artists. 
              <span class="trevor">“Eventually someone
              who likes your music (or art) is going to hear it. It might even
              be long after you die... Like today, with all these vinyl reissues
              of forgotten records of the sixties and stuff like that. <strong>Anyone
              can get their day in the sun...</strong> You’ll never be heard if you’re
              not actively putting stuff out. So just keep doing it. I mean,
              that’s what I have to remind myself every time I release a song
              and it doesn’t go viral. I have to be like, ‘Yeah well, I can’t
              expect it to, I need to just keep doing things.’Do whatever feels
              natural to you. It can be dangerous, doing something for an
              aesthetic over actual inner expression. If you’re not expressing
              anything, then it’s going to be very diffi cult to make something
              meaningful, or whatever. <strong>Just always try to find yourself in it.</strong>”</span>
              Too true, Trevor. So reader, what will you find yourself in next?
            </p>

            <p
              style="
                border: 1px solid yellowgreen;
                border-radius: 30px;
                padding: 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <em
                ><a href="https://linktr.ee/trevorlang"
                  >Trevor Lang's single Spotlight is out now!</a
                ></em
              >
            </p>

            <br />

            <a href="https://paypal.me/ti4na" target="_blank"> Tip Jar ☕ </a>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "trevorspotlight",
  components: {
    MainLayout,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Spotlight",
  },
};
</script>

<style scoped>
body {
  line-height: 2;
}

.HotelQ {
  color: yellowgreen;
  font-weight: bold;
}

.quote {
  font-size: 20pt;
  text-transform: uppercase;
  font-weight: bold;
  color: yellowgreen;
  border-top: 1px solid yellowgreen;
  border-bottom: 1px solid yellowgreen;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  line-height: 1.6;
  text-align: right;
}

.head {
  text-align: center;
  font-size: 20pt;
  line-height: 1.5;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}

.trevor {
  color: #593879;
}

a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  color: grey;
  text-decoration: none;
}

main {
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(255, 255, 255, 0.6);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 40px;
  border-radius: 45px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid yellowgreen;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 30px;

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

mark.mollyA {
  background-color: Moccasin;
  color: black;
}

mark.molly {
  background-color: OliveDrab;
  color: black;
}

@media (max-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }

  .quote {
  font-size: 18pt;
  }
}

@media only screen and (max-device-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }

  .quote {
  font-size: 18pt;
  }
}
</style>