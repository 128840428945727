<template>
  <body>
    <a href="/trueself"
      ><img
        src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/ts.png?v=1673389523276"
        class="logo"
    /></a>

    <h1>Perfect Flowers Of The Scum Generation</h1>
    <br />
    <p style="font-family: hersheyFut">
      A film by
      <a href="https://instagram.com/wildedavis" target="_blank">Wilde Davis</a>
      (They/He).
    </p>
    <br />

    <div class="youtube">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FCSSpN2IqE4?si=pVYhehz1FdMwjtXW"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="ts-contain">
      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">ABOUT</span>
        </h2>
        <br />
        <p>
          The film is a queer triptych following a clan of revolutionary femmes
          inspired to cause chaos by their hatred for this butch hustler named
          Hollywood. It's an experiment in degradation, a ritual of magick.
        </p>
        <br />
        <h3>WILDE DAVIS</h3>
        <p>
          wildedavis2{@}gmail.com
          <br />
          Wilde Davis is a filmmaker, writer, and curator based in Paris,
          France. Their filmmaking practice employs analog to invoke pagan
          ritual. They interrogate realities of gender, sexuality, and class
          politics through a punk, communist lens. They hold a BSFS in Culture
          and Politics from Georgetown University.
        </p>
      </div>

      <div class="ts-box credits">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">CREDITS</span>
        </h2>
        <br />
        <p>
          Director / Producer / Writer / Editor: Wilde Davis<br />
          D Little: The Queen<br />
          Wilde Davis: The Witch<br />
          T.C. Williams: Hollywood<br />
          Day Vidya: The Fool<br />
          Sound Design: Christos Tejada<br />
          Sound Mixing: Louise BSX<br />
          Music: M-0ther
        </p>
      </div>

      <div class="ts-box">
        <h2 style="color: #664c71">
          <span style="background: #f3f9e6; padding: 5px">Q&A WITH WILDE</span>
        </h2>
        <br />

        <h3>
          Who are some of your inspirations as a filmmaker and how might their
          impact show up in your film?
        </h3>
        <br />
        <p>
          At this very present moment in time I'm super attracted to the
          archive. I really want to start working with found footage and
          archival material, kind of like Kalil has done and many other fab
          filmmakers. The queer archive is a rich dirty place and I think there
          is a lot of it hidden in people's basements that I want to find and
          uncover. Because queer art has always been edgey and fleeting and
          transgressive, it hasn't gotten any sort of preservation or
          restoration from elite institutions. Some films of course have but the
          smut has only survived because some queer was too sentimental to let
          it go. God bless the hoarding queers.
        </p>
        <br />
        <h3>
          What emotion or human experience were you most drawn to in the making
          of this film and why?
        </h3>
        <br />
        <p>
          Magic! I'm very entranced by Kenneth Anger's insistence that cinema
          making is a form of magic. He always talked about how he wanted to
          convince his audience to perform dark magic and hello that's why I am
          here. He made me do it. Every piece of celluloid a little spell. I
          wanted to cast a spell to bind whiteness and capitalism. I created the
          film while the Black Lives Matter protests were unfolding and shifting
          the world's consciousness in the summer of 2020. Simultaneously, I was
          reading tarot for the first time and I sought to create a film that
          used archetypes to express the complex array of influences, emotions,
          and experiences I was having during this period.
        </p>
        <br />
        <h3>
          Who are some of your inspirations as a filmmaker and how might their
          impact show up in your film?
        </h3>
        <br />
        <p>
          Alongside some of the inspirations I mentioned, I see my filmmaking as
          this sort of hyperpop-like amalgamation of influences that thrusts a
          gen z brain onto a magical thing like celluloid and unleashes
          monstrosity. By this I mean that I am addicted to cinema and I never
          stop watching and reading about it. I love genre cinema particularly
          horror and anything goth and punky. When I was creating this film I
          essentially imagined each scene as if it was being stolen from another
          film and reworked for my movie. The tarot reading from Cleo from 5 to
          7, the hitchhiking scene from Super 8 1/2, so on and so forth. And the
          whole thing was basically like what if a bunch of femme fags took on
          Valerie Solanas' Scum Manifesto and brought it to life by killing a
          hustler named Hollywood (cuz I fucking hate Hollywood).
        </p>
      </div>
    </div>

    <div
      class="chat"
      style="border: 1px dotted #212121; padding: 30px; height: 400px"
    >
      <script
        type="application/javascript"
        id="cid0020000363486277724"
        data-cfasync="false"
        async
        src="//st.chatango.com/js/gz/emb.js"
        style="width: 100%; height: 100%"
      >
        {
            "handle": "perfectwilde",
            "arch": "js",
            "styles": {
                "a": "f3f9e6",
                "b": 100,
                "c": "000000",
                "d": "000000",
                "k": "f3f9e6",
                "l": "f3f9e6",
                "m": "f3f9e6",
                "p": "10",
                "q": "f3f9e6",
                "r": 100,
                "ab": false,
                "usricon": 0,
                "surl": 0,
                "allowpm": 0,
                "cnrs": "1",
                "fwtickm": 1
            }
        }
      </script>
    </div>

    <br />
    <p>
      Follow
      <a href="https://instagram.com/volvox.observer">@volvox.observer</a> on
      instagram and
      <router-link to="/support">subscribe to our newsletter</router-link> for
      updates.
    </p>
  </body>
</template>

<script>
export default {
  name: "davis",
  components: {},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "True Self Film Fest",
  },
};
</script>

<style scoped>
@font-face {
  font-family: hersheyOld;
  src: url(https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/Hershey-Noailles-OldFrench-Bold.ttf?v=1673385487640);
}

body {
  line-height: 1.6;
  background: #664c71;
  font-family: hersheyOld;
  height: 100%;
  color: #f3f9e6;
  padding: 30px;
  overscroll-behavior: none;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

h1 {
  line-height: 1;
  font-size: 45pt;
}

h2 {
  padding: 0px;
  margin: 0px;
  line-height: 1;
}

ul {
  font-size: 15pt;
}

.logo {
  width: 50px;
  float: right;
  padding-left: 10px;
  padding-bottom: 10px;
}

a {
  color: #f3f9e6;
  transition: 1s;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  background: #f3f9e6;
  color: #664c71;
}

.ts-contain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.ts-box {
  border: 1px dotted #212121;
  padding: 30px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.youtube {
  border: 1px dotted #212121;
  padding: 30px;
  margin-bottom: 10px;
  height: 55vh;
  min-height: 350px;
}

.chat {
  margin-top: 10px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .ts-contain {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .ts-box {
    height: 300px;
  }

  .youtube {
    height: 200px;
    margin-bottom: 20px;
  }

  .chat {
    display: none;
  }

  .credits {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
</style>
