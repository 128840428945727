<template>
  <MainLayout>
    <body>
      <main>
        <div id="blocks">
          <div class="block list purchase">
              <p style="line-height: 2; font-size: 10pt">
              volvox vault issue 01 | summer 2020
            </p>
            <hr>
            <p><b>Identity</b></p><hr>
            <a
              href="https://www.magcloud.com/browse/issue/1812415?__r=2733067"
              target="_blank"
              >Identity [print &#38; digital] - $9 USD</a
            ><hr>
            <a
              href="https://www.instagram.com/ar/587004575282538/"
              target="_blank"
              >The Digital Cowboy AR Filter</a
            >
          </div>

          <div class="block list">
            <p><b>A chat with Editor-in-Chief: Tiana Dueck</b></p><hr>
            <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/JRW8bmGdVRU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="block list">
            <p><b>Letter From The Editor</b></p><hr>
            <p style="line-height: 2; font-size: 18px">
              Hello world,<br />
              I’m overjoyed to introduce you to the first issue of Volvox Vault.
              The Identity issue is vibrating with harmonic creative energies. I
              was lucky when a rush of inspiration hit me to start this project
              when COVID-19 sadly made its way to Canada. Like many of us, I’m
              spending a lot of time alone. Reflecting on who I am now at the
              unripe age of 22 and who I want to become. I’ve been doing a lot
              of quiet manifesting, following my passions, and finding ways to
              stay balanced. I found that while being alone is just dandy,
              lovely people are truly what make life worth living. Having a
              sense of community nourishes our lives. So I present Volvox Vault,
              a sanctuary for creatives to come and reflect on their practices,
              befriend a talented community, and share guidance. Through our
              website, quarterly magazines, and other endeavours, we will bring
              creatives together. The principles of Volvox Vault are heavily
              inspired by the law of attraction. We ask, who are you truly in
              relation to who you are allowing yourself to be? In what ways are
              you distracted from the vibrational core of your being? And might
              you find the space and time to rediscover your self/source, create
              something based on your pure desire to make, and share it with the
              Volvox Vault community? If you’re into the law of attraction,
              you’ll understand when I say that we are all about being “in the
              vortex”. Further, while I was learning to code in university, I
              found that computational art was somehow exceedingly natural. When
              you think computationally, especially while coding behaviours, you
              realize that these fundamental instructions are at the base of
              everything. You don’t have to be an expert to code a colony of
              ants, or even a small ecosystem of fish. It’s oddly meditative.
              Great generative artists understand the natural instructions that
              are present in real ecosystems, and are fluent in languages that
              allow them to virtually replicate and alter them. The results are
              magical; they are wizards. With that understanding, I realized
              that this is true for many types of artists. To me, great artists
              are genuine and look to the natural for inspiration. It is not
              fulfilling to create based on what is trending, what’s truly
              rewarding is pursuing art which is honest to ourselves. Truthful,
              like the beauty of forests, oceans, animals, and the sky.
              Something that Mother Nature herself would be proud of. Projects
              motivated by the self, not by outside pressures. Those are the
              kinds of projects we host at Volvox Vault. You might be wondering
              “What the hell is a Volvox?” Volvox is a type of algae which forms
              curious spherical colonies of tens of thousands of cells. Born
              into colonies, and mothering colonies, the Volvox in a sense is a
              colony. Volvox represents that we as individuals would be
              practically nothing without our united communities. We’re all
              products of them! I am inspired by creative communities like
              Special Fish, The Creative Independent, Daisie, and Are.na. They
              all serve great purposes as creative platforms. Yet, I saw a need
              for a new community while looking around at my friends, and the
              creatives near and far from me. There is always room for more
              collaboration. We need to unite more fluidly somehow. So here we
              are, coming together for the first time. Thank you for supporting
              this little baby project & enjoy Identity...
            </p><hr>
            <a href="https://tiana.computer" target="_blank"
              >Tiana Dueck - Editor-in-chief</a
            >
          </div>

          <div class="block list">
            <p><b>A Minute With Max</b></p><hr>
            <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/YECJ-HcMpkM"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="block list">
            <p><b>How I Found Creativity in Self Isolation</b></p><hr>
            <p style="line-height: 2; font-size: 18px">
              “Have you been more creative now since quarantine started?”<br />
              Many artistic and creative types have heard this question over the
              last few months. My answer has always been affirmative, citing the
              extra time gave my mind access to new creative realms. My thought
              process was if I could verbalize creative success, it would
              manifest itself into existence. The truth of the matter was I had
              hit my first ever creative block. Whenever I would sit down to
              draw or write nothing came to mind. Even if I could scrounge up
              something it felt hallow and not representative of my ability. It
              was as though the river of my creativity had gone totally dry.
              This was quite difficult for me to stomach since creative
              production had been a large part of my artistic identity. A few
              years prior, I had bought into the idea that I had to always be
              producing. I would often tell myself “you’re only as good as your
              last drawing” in the name of motivation. For a long time, this
              worked, but during this period of self-isolation it was hard to
              motivate myself in that manner. In the past when I wanted to tap
              into creative realms, I would intentionally self-isolate and then
              see what I had come up with once I emerged. I figured that now
              since self-isolation was being imposed on me it would be the same
              thing, but it was not. Being forced to shelter in place made me
              interpret that I was forced to do artwork as well.
              <br /><br />
              I would see a lot of things online that would also reinforce this
              idea. I had encountered countless posts and articles that were
              calls to action for artists. I would see lines like “We need art
              now more than ever” and “How art can aid us in self-isolation.” I
              was subconsciously interpreting all this external pressure and
              internalizing it. It added into the pressure I put on myself by
              associating creative production with a part of my identity. The
              result was a feeling that my creativity was totally stifled.<br /><br />
              It was only when I realized that this pandemic had put us all
              under extreme circumstances, thus making all previous modes of
              operating null and void. I had come to understand that it was fine
              to let go of old standards. The second realization that helped my
              creativity was altering the internal monologue towards my work.
              Once I relaxed about my rate of production, I also realized that I
              did not need to go over the top to motivate myself. I have had
              creative lulls before, and yet I always managed to come back and
              produce art. I realized this was because no matter what I was
              doing art was also happening within me. I could go for a walk,
              watch standup all day, or read a book; all were building my
              creativity. All the things I was doing outside of art were
              operating within me on a subconscious level and adding to my
              creative reserves. This reshaping of my artistic identity meant
              that there was no such thing as “a waste of time.”
              <br /><br />
              The third and final realization related to this external pressure.
              I had fallen into the trap of articles that put the onus on
              artists to “save” the public from their quarantine blues or
              boredom. The only responsibility artists have is to themselves and
              their wellbeing, especially in times like these. It made no sense
              to measure myself up against the arbitrary standard of the
              internet hivemind.
              <br /><br />
              Once I had worked through all these things and internalized them,
              I was able to harness my creativity once more.
            </p><hr>
            <p>Daniel Maluka</p>
          </div>

          <div class="block list">
            <p><b>Quarantine Dreams Part 1-9</b></p><hr>
            <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/yooeH4YYkUo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="block list">
            <p><b>Piece. (Peace) - - - Website Exclusive</b></p><hr>
            <p style="line-height: 2; font-size: 18px">
              The storm marries nicely with our familiar pools.<br />
              An overflow – <br />
              Occurs Though –<br />
              I misplaced my lifejacket for this and wore weights as my
              shoes.<br />
              <br />
              To go with the tide does not mean I know the depths.<br />
              And, most often what is on the surface is not securely kept.<br />
              <br />
              Maybe the question more so is,<br />
              What will it take for me to jump in? <br />
              A lesson of drowning in my own waters<br />
              Before I even know how to swim.<br />
              <br />
              My Spirit is wandering – <br />
              To mapped unfamiliarity.<br />
              An uncertainty –<br />
              Poses an illusion of no choice in the matter making me feel
              coy.<br />
              Though, these are prison bars of my thinking I must resist to
              employ.<br />
              <br />
              And break out –<br />
              Synergize what is polarized.<br />
              And make a real effort to see –<br />
              Myself bare, gritty, and honestly.<br />
              <br />
              If I could travel back in time I would say to myself:<br />
              !!!!Go!!!! –<br />
              Where your art and your heart are appreciated.<br />
              Not necessarily by others… But by YOU.<br />
              Because sometimes it is yourself that is <br />
              Hardest to stay with in the same room.<br />
              <br />
              In this motion, you will become the tree,<br />
              Fully expressing your soliloquy,<br />
              Standing your ground on nobody else’s lease…<br />
              <br />
              Then I would remind myself of that line before,<br />
              Where the familiar pool married the storm. <br />
              Speak now or forever hold your peace.<br />
              Sometimes I think we bind ourselves to things,<br />
              Without ever thinking about our piece. (peace).
            </p><hr>
            <a href="">Em Biggs</a>
          </div>

          <div class="block list">
            <p><b>Winged Aphid Morphs</b></p><hr>
            <iframe
              width="100%"
              height="380px"
              src="https://www.youtube.com/embed/rj4iU83mTCI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="block list">
            <p><b>In Closing</b></p><hr>
            <p style="line-height: 2; font-size: 18px">
              Thank you for supporting the first issue of Volvox Vault. We have
              poured our hearts and souls into this project, and we hope you
              have felt it. There is much more to come from us, so we hope to
              see you again in the next issue and on our website.
              <br /><br />
              We acknowledge that the summer of 2020 has been a confrontational
              one. A much needed passion for change and justice has been
              ignited, and we stand with those fighting for their rights since
              colonization began and into the future. There is a lot of work to
              be done, we must continue to raise our voices, support each other,
              and demand justice. Black lives matter. Indigenous lives matter.
              Trans lives matter.
              <br /><br />
              Velvetyne Type Foundry Fonts: Happy Times at the IKOB New Game
              Plus Edition by Lucas Le Bihan &#38; Trickster by Jean-Baptiste
              Morizot
            </p>
          </div>

          <div class="block list purchase">
            <p><b>Featured Artists</b></p><hr>
            <p>
              Tiana Dueck, Andrei Pora, Beatrice Douaihy, Daniel Maluka, Emily
              Biggs, Rae Bundy, Hannah Polinski, The Digital Cowboy, Arianna
              Cancian, Brooke Hess, Daniel Barrios, Deanna Armenti, Frijke
              Coumans, Maxwell Matchim, Paige Walker, Shane Brennan
            </p>
          </div>
        </div>
      </main>
    </body>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "Identity",
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
body {
line-height: 2;
  font-family: hersheyFut, arial, sans-serif;

}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 15pt;
}

a {
  color: #9879a6;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  transition: 1s;
}

a:hover {
  color: grey;
}

main {
  width: 50%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

#blocks {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding-bottom: 90px;
  align-items: flex-end;
  justify-content: flex-end;
}

#blocks .purchase {
  max-height: 100%;
}

.block {
  background-color: #fbfdf7;
  border: 1px dotted #212121;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  line-height: 2;
  padding: 10px;
}

#blocks .list a {
  display: block;
}

main {
  margin-bottom: 60px;
}

@media (max-width: 800px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (max-device-width: 566px) {
  main {
    width: 90%;
  }

  .blocks {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>