<template>

    <DiaryEntries></DiaryEntries>

</template>


<script>
import DiaryEntries from '../../components/diaryEntries'

export default {
  name: 'Diary',
  components: {
    DiaryEntries
  }
  
};
</script>
