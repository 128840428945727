<template>
  <MainLayout>
    <body>
      <main>
        <h1 class="head">I Thought Nothing Of It: Horse Champion</h1>
        <p class="headDes">A conversation with Will Agnew aka Horse Champion</p>

        <p>
          <audio
            class="firstAudio"
            title="I Thought Nothing Of It - Horse Champion"
            controls
          >
            <source title="" src="https://cdn.glitch.global/31c7994a-ff45-4592-b835-14262460c504/will_volvox.mp3?v=1661908159059" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </p>

        <div class="imgBox"><img src="./HorseRelease5.jpg" /></div>

        <p>
          <strong
            >Tiana: Your new album “I Thought Nothing Of It” feels as if it is
            reflecting on those little sweet moments that actually mean the
            most, and sometimes you don’t realize how special it is until it's
            over. How would you describe this album?</strong
          ><br /><br />

          <strong>Will:</strong> I really do like that interpretation. I kind of
          wanted to release it as a bit of a bookend on a certain chapter of my
          life. and to put it out on my birthday, and have this piece to look
          back on and be like “Oh! This is what was goin’ on when I was 19, 18,
          whatever, and this is what I was thinking.”
        </p>

        <div class="bandList">
          <iframe
            style="border: 0; width: 100%; height: 400px"
            src="https://bandcamp.com/EmbeddedPlayer/album=2110911469/size=large/bgcol=ffffff/linkcol=63b2cc/artwork=small/transparent=true/"
            seamless
            ><a
              href="https://horsechampion.bandcamp.com/album/i-thought-nothing-of-it-2"
              >I Thought Nothing of It by Horse Champion</a
            ></iframe
          >
        </div>

        <p>
          <strong
            >What were your main influences while producing this album?</strong
          >
          <br /><br />

          There's this song by <em>Whatever Dad</em> and the song is - it kind
          of changes titles - right now it's titled
          <em>Warsh_Tippy and Zelda</em> and it's just this really gorgeous
          lo-fi indie rock song that's just got such a warm enveloping feeling.
          The production's really interesting and super minimal, and I love that
          and I wanted to basically just steel that and take it for myself! But
          I also really love <em>Little Kid</em> and the sad sad emotions that
          they make me feel. They're from Toronto, they just put out a record
          recently called <em>Transfiguration Highway</em> and it's very good.
        </p>
        <br />

        <iframe
          style="border: 0; width: 100%; height: 42px"
          src="https://bandcamp.com/EmbeddedPlayer/album=1887951541/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
          seamless
          ><a
            href="https://littlekid.bandcamp.com/album/transfiguration-highway-2"
            >Transfiguration Highway by Little Kid</a
          ></iframe
        >

        <iframe
          style="border: 0; width: 100%; height: 42px"
          src="https://bandcamp.com/EmbeddedPlayer/album=1567414573/size=small/bgcol=ffffff/linkcol=0687f5/track=746958955/transparent=true/"
          seamless
          ><a
            href="https://mthomearts.bandcamp.com/album/100-take-home-grade-pending"
            >100% Take Home! + Grade Pending by Whatever, Dad</a
          ></iframe
        >

        <br /><br />
        <p>
          <strong
            >The album is uniquely present and nostalgic at the same time.
            What’s your relationship with that feeling in writing music and
            life?</strong
          >
          <br /><br />

          I'm definitely a huge overthinker, specifically whenever I get really
          sad I start thinking about how I'm thinking about how sad I am, and
          it's like “Oh! I'm thinking about being sad, am I really sad?” And
          it's this weird feedback loop. But in how awful that can be sometimes,
          I do start thinking of song lyrics in that weird feedback loop. So it
          sucks but it has its upsides I guess.
          <br /><br />
        </p>

        <p>
          <strong
            >What are some of your favorite sounds in the album and why?</strong
          >
          <br /><br />

          I was really happy with how Bird turned out. That was the first song
          that I recorded after I bought a little 2 channel digital interface
          for my laptop, and having only recorded on 4 track set recorders I
          just wanted to have as many layers of the song as possible. so i
          started just tossing on all the synths I could find in my room.
          <br /><br />
          It just really opens up the creativity of it. on my 4 track I only
          have 2 mic inputs and 2 line inputs whereas I have theoretically
          infinite mic inputs on this little 2 channel interface for my
          computer. On Bird there's just one track that's purely just an air
          organ that plays one note. I wouldn't be able to do that on my 4 track
          recorder. So it was really nice to play around with the new
          possibilities.
          <br /><br />
        </p>

        <p>
          <strong
            >Did you do anything particularly experimental in your
            process?</strong
          >
          <br /><br />

          On Maybe in Toronto, the kick that you hear is actually just me
          thumping in my chair at my desk in my apartment. I was like “Oh! I'll
          just put a kick in eventually...” but I kinf of liked how it sounded
          so I kept it.
          <br /><br />
          And on Text Me When You're Home, the drums on that, I took a little
          cassette recorder that I borrowed from my roommate and put it in front
          of the drums and just played super loudly so it got distorted and all
          crunched out and compressed. I liked how that turned out as well.
        </p>

        <div class="imgBox"><img src="./HorseRelease2.jpg" /></div>

        <p>
          <strong
            >Was anyone else involved in writing, recording or producing the
            songs?</strong
          >
          <br /><br />

          I did all of it. I actually asked 2 different friends to play bass on
          Plant, and they both said they would, and then I sent them both the
          logic file and then neither of them ever replied. haha So I ended up
          just doin it all. But I do like that. The only thing that someone else
          had a hand in was my friend Christina Oyawale, they did the writing
          and drawing on the album cover. And that's a picture of my old
          roommate Nathan Patrick, old roommate, current friend. Hi Nathan!<br /><br />

          <strong>Yeah and current muse.</strong><br /><br />

          Yes, exactly. All the songs are about him. Haha
          <br /><br />
        </p>

        <p>
          <strong
            >What was your studio and recording process like for this
            album?</strong
          >
          <br /><br />

          Honestly it's almost entirely recorded in my bedroom. I rent a
          rehearsal room downtown with some friends, and the loud drums I can't
          record in my bedroom, I recorded down there, but I'm a person who is
          very influenced by the surroundings and I think a lot of the songs on
          the record I tried to have a specific vibe that was kind of like my
          bedroom with my plants and laptop and wooden desk and that's the tone
          of the record. For a while I was recording drums in the laundry room
          below my apartment, but I couldn't play loud enough.
          <br /><br />
          A lot of the songs are kind of, I had an idea for a song, and then I
          recorded the bass track, and then picked up a synthesizer that I had
          laying in my room, and wrote a little part, and just added parts as it
          went. So I think being in my bedroom is very conducive to that.
          <br /><br />
        </p>

        <p>
          <strong
            >This album makes me want to ask, what advice would you give to your
            younger self?</strong
          ><br /><br />

          It's super corny but I'm a big believer in that everything happens for
          a reason. There's a through line to everything good that's ever
          happened in my life and it traces back to everything bad that's ever
          happened in my life. Just keep on truckin’! You know. That's what I'd
          tell lil ol Will living in his parent's basement, playing his Dad's
          acoustic.
          <br /><br />
        </p>

        <p>
          <strong>Which track means the most to you and why?</strong
          ><br /><br />

          Probably Maybe in Toronto, because a lot of the album is about my
          relationship with this specific person and then that song kind of
          feels like we're maybe closing the chapter on it, and it's a nice
          “Hey! Maybe sometime soon.”
          <br /><br />
          <strong>TTYL.</strong><br /><br />

          Yeah, TTYL. We'll see where life takes us.<br /><br />

          <strong>SWLTU. See where life takes us.</strong>
        </p>

        <div class="imgBox"><img src="./HorseRelease3.jpg" /></div>

        <p>
          <strong
            >To me this album feels like waking up early and rested. Like
            morning dew. If it were a physical thing, what would it feel like to
            you?</strong
          ><br /><br />

          I'm a big proponent of attaching audio to physical places and
          experiences. I had to think about this for a bit, but I think if I
          want the record to feel like anything, it's just a really comfy lived
          in bedroom in an old house, and it's full of plants, and it's got the
          original hardwood floors, and maybe like the nice old molding as well,
          and a big metal radiator, and you feel kind of at home in it. It's a
          little bit messy but not too messy, you know? Lived in.
          <br /><br />

          <strong>Are you just describing where you live?</strong> <br /><br />

          Not entirely, I could have some more plants. I do have some nice
          hardwood here. More plants, and a Victorian house, you know... not
          above a shop like right now.
          <br /><br />
        </p>

        <p>
          <strong
            >Do you have any other plans relating to this release? A video?
            Online Shows?</strong
          >
          <br /><br />

          Yeah! I have a music video for Plant that I filmed a while ago. That
          I'm probably going to put out after the album comes out, and then I
          really want to do a music video for Text me when you're home. And I
          got some ideas for that and I think I'll probably record that soon
          before the snow reaches us. But we'll see what happens!
          <br /><br />
          It was literally just like, “I'm just gonna make a music video!” and I
          just filmed it myself and edited it myself. I mean, I did the record
          myself, might as well see what I'm capable of doing. It's a good
          creative workout to kind of reach out and do a different medium that
          I'm not as used to and see if something can come out of it.
          <br /><br />
        </p>

        <p>
          <strong
            >When you create music, what is your personal purpose or
            goal?</strong
          >
          <br /><br />

          I think with this record in particular, I just went in and I was like,
          I want to make something that I would like. Like if I heard it on
          Bandcamp or in a YouTube video or something, I wanted to be like “Oh!
          That's kinda cool! Hey! That's kinda neat.” <br />
          I'm really happy with the turn out of it. I was pretty proud of the
          production stuff even though it's really lo-fi. It was my first shot
          of properly recording something digitally.
          <br /><br />
        </p>

        <p>
          <strong>What's next for you?</strong><br /><br />

          I don't know! Just seeing where life takes me. I've been working with
          my friend Ginger, she makes really great indie pop music. And I've
          been co writing with her on stuff, which is really cool because it's a
          bit of a step out of what I would normally write. And I'm not
          confident enough to make pop music or sing pop songs, but I love pop
          melodies and a concisely tightly written song. So it's a nice little
          writing exercise to step into someone else's shoes and pretend that I
          can sing pop songs.
          <br /><br />
          And also just been helping other friends record stuff, this week I'm
          going in the studio with my friend Dylan to help him record and ep of
          some songs he's been working on, which I really like doing.
          <br /><br />

          More horse champion stuff eventually! Whenever it comes.
          <br /><br />
        </p>

        <p>
          <strong>Anything else you want to say about this album?</strong
          ><br /><br />

          If someone listens to it and just feels a connection to it, I'm happy.
          That's all, I'm happy.
        </p>

        <div class="imgBox"><img src="./HorseRelease4.jpg" /></div>

        <p>
          <a
            href="https://www.paypal.com/paypalme/horsechampion"
            target="_blank"
          >
            Horse Champion's Tip Jar ☕
          </a>
          |
          <a href="https://www.paypal.com/paypalme/ti4na" target="_blank">
            Tiana Dueck's Tip Jar ☕
          </a>
        </p>
      </main>
    </body>
  </MainLayout>
</template>


<script>
import MainLayout from "../../../../layouts/MainLayout";

export default {
  name: "horseChampion",
  components: {
    MainLayout,
  },
};
</script>

<style scoped>
body {
  line-height: 2;
}

.head {
  text-align: center;
  font-size: 20pt;
}

.headDes {
  text-align: center;
  padding-top: 15px;
  color: yellowgreen;
}

a {
  color: yellowgreen;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  color: grey;
}

main {
  width: 50%;
  max-width: 600px;
  min-width: 340px;
  background-color: rgb(255, 255, 255, 0.6);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 40px;
  border-radius: 45px;
}

img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.imgBox {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;

  border-image-source: linear-gradient(to bottom, yellowgreen, grey);

  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstAudio {
  padding-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
}

.bandList {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-device-width: 800px) {
  main {
    margin-top: 25px;
    min-width: 300px;
    max-width: 300px;
  }
}
</style>