<template>
    <body>
<main>
      <h2>cleaning diary: saturday</h2>
      <h3><a href="https://www.athenascott.com/" target="_blank">athena scott</a></h3>

      <p>
        If I do my dishes while waiting for my tea to steep, maybe E will
        text me back, will tell me why her last message was three hours ago, why
        it ended in a period, cold and hard and distant.
        <br /><br />
        If I wash the pots from last night and our two matcha-stained mugs from
        breakfast, and the whisk and milk steamer and cereal bowls and water
        glasses, then as the dishes drip onto the drying rack I might feel calm
        and light and less and less dizzy.
        <br /><br />
        If I fold my towels, pair the socks, and hang my dresses, then a friend
        might reach out and invite me somewhere fun to wear them this weekend.
        Spring might come sooner, last longer.
        <br /><br />
        If I take the trash out to the curb, then my neighbour might wave me
        over and I’d have only funny things to say, and they’d invite me over
        for dinner to drink their fancy wine from the cellar.
        <br /><br />
        If I replace the wilted flowers on my bookshelf with fresh tulips, would
        I bake more? Paint more? Lay out in the park and read poetry more often?
        <br /><br />
        A person with fresh flowers wouldn’t miss deadlines, wouldn’t be so sad
        when her friends are too busy to hang out. The sun would stretch further
        into my home, and I’d have more room to entertain, more friends to host,
        more time to create, more ideas to excite me.
        <br /><br />
        It wouldn’t take much: I could do the dishes and take out the trash and
        sweep the floors and fold the laundry and make the bed and wipe down the
        counters and scrub the shower tiles—and by then, I might not even mind
        the smaller things: the candle wax on the dining table or the blueberry
        that fell on the kitchen floor, crushed under my toes before breakfast.
        <br /><br />
        Two hours until E gets here and that could be enough to get a handle
        on things, should be.
        <br /><br />
        But first, I’ll close my eyes for a minute or twenty. I’ll kick today’s
        socks onto the floor next to yesterday’s and curl up in the corner of
        the couch. I’ll fold a worn sweater under my head and nap.
        <br /><br />
        The kale chips will burn in the oven;
        <br />
        E will love me anyway.
      </p>
    </main>

    <router-link to="/archive#diary" class="back">back to archive</router-link>

    <img
      @click="myFunction1"
      id="img1"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-16.png?v=1684588249867"
    />
    <img
      @click="myFunction2"
      id="img2"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-15.png?v=1684588247977"
    />
    <img
      @click="myFunction3"
      id="img3"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-07.png?v=1684588251886"
    />
    <img
      @click="myFunction4"
      id="img4"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-08.png?v=1684588253510"
    />
    <img
      @click="myFunction5"
      id="img5"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-09.png?v=1684588255041"
    />
    <img
      @click="myFunction6"
      id="img6"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-10.png?v=1684588256677"
    />
    <img
      @click="myFunction7"
      id="img7"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-14.png?v=1684588245896"
    />
    <img
      @click="myFunction8"
      id="img8"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-17.png?v=1684588258243"
    />
    <img
      @click="myFunction9"
      id="img9"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-11.png?v=1684588259759"
    />
    <img
      @click="myFunction10"
      id="img10"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-12.png?v=1684588262922"
    />
    <img
      @click="myFunction11"
      id="img11"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-13.png?v=1684588268129"
    />
    <img
      @click="myFunction12"
      id="img12"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-01.png?v=1684588271534"
    />
    <img
      @click="myFunction13"
      id="img13"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-18.png?v=1684588261340"
    />
    <img
      @click="myFunction14"
      id="img14"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-02.png?v=1684588273127"
    />
    <img
      @click="myFunction15"
      id="img15"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-03.png?v=1684588274952"
    />
    <img
      @click="myFunction16"
      id="img16"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-04.png?v=1684588276526"
    />
    <img
      @click="myFunction17"
      id="img17"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-05.png?v=1684588278126"
    />
    <img
      @click="myFunction18"
      id="img18"
      src="https://cdn.glitch.global/f78862a1-d039-4c43-af38-52de88c188df/athen-sketches-06.png?v=1684588280762"
    />

    </body>
</template>


<script>

export default {

    name: "cleaningdiary",
    components: {},
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: "cleaning diary",
    },
    methods: {
      myFunction1: function() {
        document.getElementById("img1").style.display = "none";
      },
      myFunction2: function() {
        document.getElementById("img2").style.display = "none";
      },
      myFunction3: function() {
        document.getElementById("img3").style.display = "none";
      },
      myFunction4: function() {
        document.getElementById("img4").style.display = "none";
      },
      myFunction5: function() {
        document.getElementById("img5").style.display = "none";
      },
      myFunction6: function() {
        document.getElementById("img6").style.display = "none";
      },
      myFunction7: function() {
        document.getElementById("img7").style.display = "none";
      },
      myFunction8: function() {
        document.getElementById("img8").style.display = "none";
      },
      myFunction9: function() {
        document.getElementById("img9").style.display = "none";
      },
      myFunction10: function() {
        document.getElementById("img10").style.display = "none";
      },
      myFunction11: function() {
        document.getElementById("img11").style.display = "none";
      },
      myFunction12: function() {
        document.getElementById("img12").style.display = "none";
      },
      myFunction13: function() {
        document.getElementById("img13").style.display = "none";
      },
      myFunction14: function() {
        document.getElementById("img14").style.display = "none";
      },
      myFunction15: function() {
        document.getElementById("img15").style.display = "none";
      },
      myFunction16: function() {
        document.getElementById("img16").style.display = "none";
      },
      myFunction17: function() {
        document.getElementById("img17").style.display = "none";
      },
      myFunction18: function() {
        document.getElementById("img18").style.display = "none";
      },
    },
};
</script>

<style scoped>

html {
  background: rgba(200,200, 0,0.1);
}

body {
  padding: 45px;
  padding-bottom: 60px;
  color: rgb(55,55,55);
  line-height: 1.6;
}

main {
  padding: 20px;
  border: 1.5px solid rgb(55,55,55);
  margin: 0 auto;
  max-width: 310px;
  hyphens: auto;
  word-break: break-word;
  background: rgba(200,200, 0,0.1);
  color: rgb(55,55,55);
}

img {
  display: block;
  width: 150px;
  padding: 10px;
  opacity: 0.6;
}

h2,
h3 {
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  text-align: CENTER;
}

h3 {
  font-size: 16pt;
}

p {
  font-size: 14pt; 
  
}

.back {
  position: absolute;
  top: 8px;
  right: 8px;
}

#img1 {
  position: absolute;
  top: 220%;
  left: 5%;
  padding-bottom: 40px;
}

#img18 {
  position: absolute;
  top: 200%;
  right: 16%;
}

#img16 {
  position: absolute;
  top: 185%;
  left: 5%;
}

#img11 {
  position: absolute;
  top: 175%;
  right: 3%;
  width: 150px;
}

#img6 {
  position: absolute;
  top: 160%;
  left: 15%;
}

#img17 {
  position: absolute;
  top: 150%;
  right: 12%;
  width: 130px;
}

#img10 {
  position: absolute;
  top: 125%;
  right: 4%;
  width: 175px;
}

#img3 {
  position: absolute;
  top: 135%;
  left: 5%;
  width: 120px;
}

#img14 {
  position: absolute;
  top: 100%;
  right: 19%;
  width: 190px;
}

#img12 {
  position: absolute;
  top: 100%;
  left: 10%;
  width: 110px;
}

#img5 {
  position: absolute;
  top: 76%;
  right: 8%;
  width: 65px;
}

#img9 {
  position: absolute;
  top: 80%;
  left: 6%;
  width: 90px;
}

#img8 {
  position: absolute;
  top: 55%;
  right: 13%;
  width: 120px;
}

#img13 {
  position: absolute;
  top: 55%;
  left: 10%;
  width: 200px;
}

#img4 {
  position: absolute;
  top: 40%;
  right: 6%;
  width: 130px;
}

#img2 {
  position: absolute;
  top: 25%;
  left: 7%;
}

#img7 {
  position: absolute;
  top: 6%;
  right: 8%;
  width: 220px;
}

#img15 {
  position: absolute;
  top: 5%;
  left: 3%;
  width: 90px;
}


</style>