<template>
  <CafeDoorLayout>

  </CafeDoorLayout>
</template>


<script>
import CafeDoorLayout from '../../layouts/cafe/CafeDoorLayout.vue'

export default {
  name: 'CafePost',
  components: {
    CafeDoorLayout
  }
};
</script>